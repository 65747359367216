import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Input } from '../Input';
import Grid from '@mui/material/Grid';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PopupConfirmDeleteProps = {
  open: boolean;
  message?: string;
  description?: string;
  label?: string;
  pass?: string;
  handleClick: () => void;
  handleClose: () => void;
};

const PopupConfirmDelete = ({
  open,
  message,
  description,
  label,
  pass = 'DELETE',
  handleClick,
  handleClose,
}: PopupConfirmDeleteProps) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState('');
  const isDisabled = confirm !== pass;
  const m = t('popup-delete-title');
  const d = t('popup-delete-description');
  const l = t('popup-delete-confirm');

  useEffect(() => {
    return () => {
      setConfirm('');
    };
  }, [handleClick]);

  const handleChangeConfirm = (text: string) => {
    setConfirm(text);
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>{message || m}</DialogTitleContainer>
      <DialogContentContainer>
        <p>{description || d}</p>
        <Grid xs={12} sm={12} item>
          <Input
            label={label || l}
            placeholder={pass}
            value={confirm}
            hasBorder
            OnChange={handleChangeConfirm}
          />
        </Grid>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-confirm')}
          color={'primary'}
          OnClick={handleClick}
          disabled={isDisabled}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

export default PopupConfirmDelete;
