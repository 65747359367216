import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from '../utils/redux';
import { initialsSelector } from '../redux/selectors/user';
import { NotificationMenu, ProfileMenu } from '../components/Snapbar';
import { useNavigate } from 'react-router';
import ColorType from '../components/Theme/ColorType';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

type Props = {
  isWithLogo?: boolean;
};

const TopSideMenu = ({ isWithLogo = false }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifAnchorEl, setNotifAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openNotifMenu = Boolean(notifAnchorEl);

  const initials = useAppSelector(initialsSelector);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotifAnchorEl(event.currentTarget);
  };
  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };

  return (
    <Wrapper isWithLogo={isWithLogo}>
      {isWithLogo && (
        <TitleContainer>
          <LogoContainer>
            <img
              src="/logo.png"
              alt="logo"
              height="50px"
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer', marginTop: 20 }}
            />
          </LogoContainer>
          <Title>{t('pp-terms-title')}</Title>
        </TitleContainer>
      )}
      <Container isWithLogo={isWithLogo}>
        <IconButton
          onClick={handleNotifClick}
          aria-controls={openNotifMenu ? 'notif-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openNotifMenu ? 'true' : undefined}
        >
          <NotificationsNoneIcon
            sx={{
              width: 32,
              height: 32,
            }}
          />
        </IconButton>
        <IconButton
          onClick={handleClick}
          aria-controls={openMenu ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
            }}
          >
            {initials}
          </Avatar>
        </IconButton>
      </Container>
      {/* <Divider /> */}
      <NotificationMenu
        anchorEl={notifAnchorEl}
        open={openNotifMenu}
        handleClose={handleNotifClose}
      />
      <ProfileMenu
        anchorEl={anchorEl}
        open={openMenu}
        handleClose={handleClose}
      />
    </Wrapper>
  );
};

type ContainerProp = {
  isWithLogo: boolean;
};

const Wrapper = styled(Box)<ContainerProp>`
  ${({ isWithLogo }) =>
    isWithLogo &&
    `
display: flex;
justify-content: space-between;
`}
`;

const Container = styled(Box)<ContainerProp>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px 32px;
  gap: 8px;
  ${({ isWithLogo }) =>
    isWithLogo &&
    `
    flex: 1
  `}
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 16px;
  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

const LogoContainer = styled('div')`
  margin-left: 3px;
  margin-top: -16px;
  @media (max-width: 535px) {
    margin-top: 0px;
  }
`;

const Title = styled('span')`
  font-size: 24px;
  font-family: Inter;
`;

const LogoText = styled('span')(() => ({
  color: ColorType.primary,
  fontWeight: 'bold',
  fontSize: 32,
  cursor: 'pointer',
}));

export default TopSideMenu;
