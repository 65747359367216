import { useEffect } from 'react';
import Path from '../components/Link/Path';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import {
  isAdminSelector,
  nameSelector,
  roleSelector,
} from '../redux/selectors/user';
import { companySelector } from '../redux/selectors/company';
import CompanyDetails from './CompanyDetails';
import { CompanyTable } from '../components/Tables';
import { getCompanies } from '../redux/actions/companyAction';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

const CompanyManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAdmin = useAppSelector(isAdminSelector);
  const company = useAppSelector(companySelector);
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  useEffect(() => {
    isAdmin && dispatch(getCompanies());
  }, [dispatch, isAdmin]);

  if (isAdmin && Object.keys(company).length === 0)
    return (
      <Container>
        <HeaderContainer>
          <StyledDiv>
            <Path />
            <TitleSpan>
              {userName},{' '}
              <DescriptionSpan style={{ fontSize: 24 }}>
                {handleDisplayRole(role)}
              </DescriptionSpan>
            </TitleSpan>
            <DescriptionSpan>{t('companies-description')}</DescriptionSpan>
          </StyledDiv>
        </HeaderContainer>
        <CompanyTable />
      </Container>
    );
  return <CompanyDetails />;
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

export default CompanyManagement;
