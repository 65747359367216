import ColorType from '../components/Theme/ColorType';
import { BuildingDTO } from '../redux/types/building.dto';

export type RoleOption = {
  value: string;
  label: string;
  labelFr?: string;
  placeholder?: boolean;
};

export type MenuItems = {
  index: number;
  name: string;
  link: string;
  icon: string;
};

export interface CompanyData {
  id: number;
  name: string;
  address: string;
  allow_admin_support?: boolean;
  button: string;
}

export interface Company {
  id: number;
  name: string;
  address: string;
}

export interface UserData {
  id?: number;
  group?: string;
  action?: string;
  name: string;
  email: string;
  info?: string;
  job: string;
  mission: string;
  status: string;
  role: string;
  company?: {
    id?: number;
    name?: string;
    address?: string;
    allow_admin_support?: boolean;
  };
}

export interface GatewayData {
  id?: number;
  name: string;
  building: string;
  floor: string;
  area: string;
  subArea: string;
  button: string;
}

export interface DeviceData {
  id?: number;
  asset_number?: string;
  eui: string;
  model?: string;
  building: string;
  floor: string;
  area: string;
  subArea: string;
  button?: string;
  statusA?: string;
  buttonA?: string;
  statusB?: string;
  buttonB?: string;
}

export interface MessageData {
  id?: number;
  timestamp?: string;
  devEui: string;
  deviceModelDescription: string;
  datadecoded: string;
  data: string;
  IoRaSNR: string;
  rssi: string;
  rxInfo_time: string;
}

export interface ScheduleData {
  controller?: number;
  building: string;
  floor: string;
  area: string;
  subArea: string;
  status: string;
}
export interface HeadCell {
  disablePadding: boolean;
  id:
    | keyof CompanyData
    | keyof UserData
    | keyof GatewayData
    | keyof DeviceData
    | keyof ScheduleData
    | keyof MessageData;
  label: string;
  labelFr?: string;
  numeric: boolean;
  hasFilter?: boolean;
}

export interface BuildingData {
  id: number;
  name: string;
  address: string;
  button: string;
}

export type Order = 'asc' | 'desc';

export type colorsProp = {
  bgColor?: ColorType;
  circleBg?: ColorType;
};

export type Location = {
  floor: string;
  floor_area: string;
  floor_sub_area: string;
};

export type Meta = {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url?: string;
  prev_page_url?: string;
  path: string;
  from: number;
  to: number;
};

type Channel = {
  channel_code: string;
  status: string;
  status_label: string;
};
export interface DeviceUpdateProps {
  gateway?: {
    name: string;
    building: BuildingDTO;
    device_location: Location;
  };
  controller?: {
    id: number;
    asset_number: string;
    serial_number: string;
    eui: string;
    application_key: string;
    dev_application_eui: string;
    model: string;
    type: string;
    building: BuildingDTO;
    device_location: Location;
    aws_id: string;
    channels?: Channel[];
  };
  sensor?: {
    id: number;
    asset_number: string;
    serial_number: string;
    eui: string;
    application_key: string;
    dev_application_eui: string;
    model: string;
    type: string;
    building: BuildingDTO;
    device_location: Location;
    aws_id: string;
  };
  handleSendData: (data: any, componentType: string) => void;
}

export enum StatusKPI {
  healthy = 'healthy',
  warning = 'warning',
}

export type BuildingErrorProp = {
  buildingName?: string;
  status?: string;
  buildingAddress?: string;
  buildingTotalArea?: string;
  buildingType?: string;
  buildingControlSystem?: string;
  buildingLocation?: string;
  buildingOpeningDate?: string;
  buildingConstructionDate?: string;
  buildingOwner?: string;
  buildingGeneralContractor?: string;
  buildingElectrician?: string;
  buildingPlumbers?: string;
  buildingEngineers?: string;
  buildingArchitects?: string;
  buildingTechnology?: string;
  buildingHVAC?: string;
  buildingHVACSystem?: string;
  buildingProjectManager?: string;
  buildingOperationsManager?: string;
  buildingHelp?: string;
  buildingInsurer?: string;
  buildingReferal?: string;
  buildingSales?: string;
  floor?: string;
  buildingBasement?: string;
  buildingTotalRooms?: string;
  buildingPool?: string;
  buildingElectricityRoom?: string;
  buildingMechanicalRoom?: string;
  buildingTelecomRoomNumber?: string;
  buildingTelecomRoomLocation?: string;
  buildingCentralWifi?: string;
  buildingCentralLAN?: string;
  buildingInternetNumber?: string;
  buildingInternetLocation?: string;
  buildingMainWaterSize?: string;
  buildingMainWaterLocation?: string;
  buildingRoomWaterSize?: string;
  buildingRoomWaterLocation?: string;
  buildingOutlet?: string;
  buildingPRV?: string;
  buildingPlanSet?: string;
};

export const NumberOfFloors = [
  { value: '0', label: 'Choose a number of floors', placeholder: true },
  ...Array.from({ length: 10 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })),
];

export const BuildingStatuses = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
];

export const BuildingConfirmation = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
];

export const BuildingTypes = [
  { value: 'multi-dwelling', label: 'multi-dwelling' },
  { value: 'office', label: 'office' },
  { value: 'multi-use', label: 'multi-use' },
  { value: 'warehouse', label: 'warehouse' },
  { value: 'datacenter', label: 'datacenter' },
  { value: 'condominium', label: 'condominium' },
  { value: 'campus', label: 'campus' },
  { value: 'hospital', label: 'hospital' },
];

export const BuildingControlSystems = [
  { value: 'Siemens', label: 'Siemens' },
  { value: 'Delta', label: 'Delta' },
  { value: 'Eclypse', label: 'Eclypse' },
  { value: 'Johnson Controls', label: 'Johnson Controls' },
  { value: 'Honeywell', label: 'Honeywell' },
];

export const BuildingHVACSystems = [
  { value: 'carrier', label: 'Carrier' },
  { value: 'panasonic', label: 'Panasonic' },
  { value: 'honeywell', label: 'Honeywell' },
  { value: 'mitsubishi', label: 'Mitsubishi' },
  { value: 'other', label: 'Other' },
];

export const BuildingTelecom = [
  { value: 'Basement', label: 'Basement' },
  { value: 'Floor', label: 'Floor' },
  { value: 'Top', label: 'Top' },
  { value: 'Roof', label: 'Roof' },
];

export const MainWaterDiameters = [
  { value: '3', label: '3 Inches' },
  { value: '4', label: '4 Inches' },
  { value: '6', label: '6 Inches' },
  { value: '8', label: '8 Inches' },
];

export const RoomWaterDiameters = [
  { value: '1/2', label: '1/2 Inche' },
  { value: '3/4', label: '3/4 Inche' },
  { value: '1', label: '1 Inche' },
  { value: '1 + 1/4', label: '1 + 1/4 Inches' },
  { value: '1 + 1/2', label: '1 + 1/2 Inches' },
  { value: '2', label: '2 Inches' },
  { value: '2 + 1/2', label: '2 + 1/2 Inches' },
  { value: '3', label: '3 Inches' },
];
