import { useState, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useNavigate } from 'react-router';
import { BuildingOffice2 } from '../components/svg';
import { Input, SelectInput } from '../components/Input';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { errorsSelector as errorsArray } from '../redux/selectors/ui';
import validator from 'validator';
import { storeCompany } from '../redux/actions/companyAction';
import { setAxiosHeaders } from '../utils/helper';

type ErrorProp = {
  companyName?: string;
  companyAddress?: string;
  status?: string;
};

const Statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const AddCompany = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorsSelector = useAppSelector(errorsArray);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [status, setStatus] = useState('Active');
  const [errors, setErrors] = useState<ErrorProp>({});

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setCompanyName('');
      setCompanyAddress('');
      setStatus(Statuses[0].value);
    };
  }, [errorsSelector]);

  const handleChangeName = (text: string) => {
    setCompanyName(text);
  };

  const handleChangeAddress = (text: string) => {
    setCompanyAddress(text);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (validator.isEmpty(companyName)) {
      errors.companyName = 'A valid company name is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(storeCompany({ companyName, companyAddress, navigate }));
  };

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          <TitleDivContainer>
            <AvatarContainer>
              <BuildingOffice2 height={'72px'} width={'72px'} />
            </AvatarContainer>
            <TextContainer>
              <StyledBoldSpan>{companyName || 'Company Name'}</StyledBoldSpan>
              <StyledSpan>{companyAddress || 'Company Address'}</StyledSpan>
            </TextContainer>
          </TitleDivContainer>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            buttonLabel="Add"
            color={'primary'}
            OnClick={handleSendData}
            style={{
              borderRadius: 6,
            }}
          />
        </Grid>
      </GridHeaderContainer>
      <GridContentContainer container>
        <Grid xs={12} sm={5} item>
          <Input
            label="Company Name"
            placeholder="Company name"
            value={companyName}
            hasBorder
            OnChange={handleChangeName}
            error={!!errors?.companyName}
            errorMessage={errors?.companyName}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <SelectInput
            label="Status"
            disabled
            values={Statuses}
            value={status}
            OnChange={handleChangeStatus}
            error={!!errors?.status}
            errorMessage={errors?.status}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <Input
            label="Address"
            placeholder="Company address"
            value={companyAddress}
            hasBorder
            OnChange={handleChangeAddress}
            error={!!errors?.companyAddress}
            errorMessage={errors?.companyAddress}
          />
        </Grid>
      </GridContentContainer>
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default AddCompany;
