import { useState, useLayoutEffect, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useNavigate } from 'react-router';
import { BuildingOffice2, LockClosed, LockOpen } from '../components/svg';
import { Input, SelectInput } from '../components/Input';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { SelectChangeEvent } from '@mui/material/Select';
import validator from 'validator';
import {
  toggleCompanySupport,
  updateCompany,
} from '../redux/actions/companyAction';
import { isAdminSelector } from '../redux/selectors/user';
import {
  companySelector,
  companySupportSelector,
} from '../redux/selectors/company';
import ColorType from '../components/Theme/ColorType';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

type ErrorProp = {
  companyName?: string;
  companyAddress?: string;
  status?: string;
};

const Statuses = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
];

const CompanyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [status, setStatus] = useState('1');
  const [errors, setErrors] = useState<ErrorProp>({});
  const isAdmin = useAppSelector(isAdminSelector);
  const company = useAppSelector(companySelector);
  const companySupport = useAppSelector(companySupportSelector);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const isUpdated =
    company?.name === companyName &&
    company?.address === companyAddress &&
    // eslint-disable-next-line prettier/prettier
    (isAdmin && status === Number(company?.enabled).toString());

  useLayoutEffect(() => {
    if (company) {
      setCompanyId(company?.id);
      setCompanyName(company?.name);
      company?.address && setCompanyAddress(company.address);
      isAdmin && setStatus(Number(company?.enabled).toString());
    }
    return () => {
      setErrors({});
      setCompanyName('');
      setCompanyAddress('');
      setStatus(Statuses[0].value);
    };
  }, []);

  const handleChangeName = (text: string) => {
    setCompanyName(text);
  };

  const handleChangeAddress = (text: string) => {
    setCompanyAddress(text);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (validator.isEmpty(companyName)) {
      errors.companyName = 'A valid company name is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(
      updateCompany({
        companyId,
        companyName,
        companyAddress,
        isAdmin,
        status,
        navigate,
      })
    );
  };

  const handleLock = () => {
    dispatch(toggleCompanySupport());
  };

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          <TitleDivContainer>
            <AvatarContainer>
              <BuildingOffice2 height={'72px'} width={'72px'} />
            </AvatarContainer>
            <TextContainer>
              <StyledBoldSpan>{companyName}</StyledBoldSpan>
              <StyledSpan>{companyAddress}</StyledSpan>
            </TextContainer>
          </TitleDivContainer>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            buttonLabel={t('button-update')}
            color={'primary'}
            OnClick={handleSendData}
            disabled={isUpdated}
            style={{
              borderRadius: 6,
              padding: '10px 16px 10px 16px',
              marginRight: 12,
            }}
          />
          {!isAdmin && (
            <ActionButton
              buttonLabel={
                companySupport ? t('company-lock') : t('company-unlock')
              }
              variant="outlined"
              color={'tertiary'}
              {...(companySupport
                ? { iconLeft: <LockedIcon /> }
                : { iconLeft: <UnlockedIcon /> })}
              OnClick={handleLock}
              style={{
                borderRadius: 6,
                padding: '10px 16px 10px 16px',
              }}
            />
          )}
        </Grid>
      </GridHeaderContainer>
      <GridContentContainer container>
        <Grid xs={12} sm={isAdmin ? 5 : 12} item>
          <Input
            label={t('company-name-label')}
            placeholder={t('company-name-placeholder')}
            value={companyName}
            hasBorder
            OnChange={handleChangeName}
            error={!!errors?.companyName}
            errorMessage={errors?.companyName}
          />
        </Grid>
        {isAdmin && (
          <Grid xs={12} sm={5} item>
            <SelectInput
              label={t('company-status-label')}
              placeholder={t('company-status-placeholder')}
              values={Statuses}
              value={status}
              OnChange={handleChangeStatus}
              error={!!errors?.status}
              errorMessage={errors?.status}
            />
          </Grid>
        )}
        <Grid xs={12} sm={isAdmin ? 5 : 12} item>
          <Input
            label={t('company-address-label')}
            placeholder={t('company-address-label')}
            value={companyAddress}
            hasBorder
            OnChange={handleChangeAddress}
            error={!!errors?.companyAddress}
            errorMessage={errors?.companyAddress}
          />
        </Grid>
      </GridContentContainer>
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

const UnlockedIcon = styled(LockOpen)(() => ({
  width: 20,
  height: 20,
  marginRight: 6,
  stroke: ColorType.black,
}));

const LockedIcon = styled(LockClosed)(() => ({
  width: 20,
  height: 20,
  marginRight: 6,
  stroke: ColorType.black,
}));

export default CompanyDetails;
