/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import { useAppSelector } from '../../utils/redux';
import { Order } from '../../const/types';
import { scheduleHeadCells } from '../../const/arrays';
import {
  displayStatusDevices,
  getComparator,
  stableSort,
} from '../../utils/helper';
import PaginationTable from './PaginationTable';
import TableSelectHead from './TableSelectHead';
import Checkbox from '@mui/material/Checkbox';
import TableSelectToolbar from './TableSelectToolbar';
import { Status } from '../Badge';
import ColorType from '../Theme/ColorType';
import { Clog } from '../svg';
import { controllersSelector } from '../../redux/selectors/device';
import {
  ControllerDTO,
  ControllerItemDTO,
} from '../../redux/types/controller.dto';
import { Text } from '../Text';
import { useTranslation } from 'react-i18next';
import EmptySchedule from './EmptySchedule';

type Props = {
  newControllers: ControllerItemDTO[];
  setControllers: any;
};
const ScheduleTable = ({ newControllers, setControllers }: Props) => {
  const { t } = useTranslation();
  const controllers: ControllerDTO = useAppSelector(controllersSelector);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage] = useState(5);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [visibleRows, setVisibleRows] = useState<any>([]);

  useEffect(() => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(controllers.items.length / rowsPerPage);
      i += 1
    ) {
      pageNumbers.push(i);
    }
    setTotalPages(pageNumbers.length);
  }, [rowsPerPage, controllers]);

  const updateRows = useCallback(() => {
    const newRows = stableSort(
      controllers.items,
      getComparator(order, orderBy)
    );
    setVisibleRows(newRows);
  }, [controllers.items, order, orderBy]);

  useEffect(() => {
    updateRows();
  }, [controllers, updateRows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ControllerItemDTO
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = controllers.items.map((n: any) => `${n.id}`);
      setSelected(newSelected);
      setControllers(controllers.items);
      return;
    }
    setSelected([]);
    setControllers([]);
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    const newController = controllers?.items?.find(
      (c: any) => c.id === parseInt(id, 10)
    );
    const existingObject = newControllers.find(
      (item) => item.id === parseInt(id, 10)
    );

    if (!existingObject) {
      setControllers((prevData: any) => [...prevData, newController]);
    } else {
      const newData = newControllers.filter(
        (item) => item.id !== parseInt(id, 10)
      );
      setControllers(newData);
    }
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - controllers.items.length)
      : 0;

  if (controllers?.items?.length === 0)
    return (
      <EmptySchedule
        component={t('device-add-controller')}
        icon={<Clog width={56} height={56} fill={ColorType.black} />}
      />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <TableSelectToolbar
          title={t('device-add-controller')}
          description={t('schedules-table-description')}
          numSelected={selected.length}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <TableSelectHead
              headCells={scheduleHeadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              rowCount={controllers.items.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {visibleRows.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const isItemSelected = isSelected(row.id.toString());
                const circleBg = displayStatusDevices('active').circleBg;
                const bgColor = displayStatusDevices('active').bgColor;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      cursor: isItemSelected ? 'auto' : 'pointer',
                      borderBottom: '1px solid #D1D9E2',
                    }}
                    onClick={(event) =>
                      handleSelectClick(event, row.id.toString())
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TitleDivContainer>
                        <AvatarContainer>
                          <Clog
                            height={'14px'}
                            width={'14px'}
                            fill={ColorType.lightGrey}
                          />
                        </AvatarContainer>
                        <TextContainer>
                          <Text color={ColorType.grey50}>{row.eui}</Text>
                        </TextContainer>
                      </TitleDivContainer>
                    </TableCell>
                    <TableCell align="left">
                      <Text color={ColorType.grey500}>
                        {/* @ts-ignore */}
                        {row.building?.name}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text color={ColorType.grey500}>
                        {/* @ts-ignore */}
                        {row.device_location?.floor}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text color={ColorType.grey500}>
                        {/* @ts-ignore */}
                        {row.device_location?.floor_area}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text color={ColorType.grey500}>
                        {/* @ts-ignore */}
                        {row.device_location?.floor_sub_area}
                      </Text>
                    </TableCell>
                    <TableCell align="right">
                      <Status
                        text="active"
                        isShowCircle
                        circleColor={circleBg}
                        bgColor={bgColor}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationTable
          page={page}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Paper>
    </Box>
  );
};

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: ${ColorType.darkGrey};
  border-radius: 50px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export default ScheduleTable;
