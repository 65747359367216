import React from 'react';
import { styled } from '@mui/material/styles';
import { Chip } from '../Badge';
import { Leak, Valve, Warning } from '../svg';
import ColorType from '../Theme/ColorType';
import { Location } from '../../const/types';
import dayjs from 'dayjs';

type Props = {
  alertId: number;
  title: string;
  isAlert?: boolean;
  location?: Location;
  date: string;
  handleClick: (alertId: number) => void;
};
const NotificationCard = ({
  alertId,
  title,
  isAlert,
  location,
  date,
  handleClick,
}: Props) => {
  return (
    <Container isAlert={isAlert} onClick={() => handleClick(alertId)}>
      <Wrapper>
        <ContentContainer>
          <TitleSection>
            <TitleContainer>
              {isAlert ? (
                <Leak height={14} width={14} color={ColorType.primary} />
              ) : (
                <Valve height={14} width={14} fill={ColorType.blue} />
              )}
              <TitleText>{title}</TitleText>
            </TitleContainer>
            {isAlert && (
              <WarningContainer>
                <Warning height={14} width={14} color={ColorType.primary} />
              </WarningContainer>
            )}
          </TitleSection>
          <DetailContainer>
            {isAlert && (
              <Chip
                text={title}
                circleColor={ColorType.primary}
                bgColor={ColorType.softPink}
                icon={<Valve height={14} width={14} fill={ColorType.primary} />}
              />
            )}
            {location && (
              <LocationContainer>
                <LocationText>{location.floor}</LocationText>
                <BlackDot />
                <LocationText>{location.floor_area}</LocationText>
                <BlackDot />
                <LocationText>{location.floor_sub_area}</LocationText>
              </LocationContainer>
            )}
          </DetailContainer>
          <LocationText>{dayjs(date).fromNow()}</LocationText>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

const TitleText = styled('span')`
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 14.4px;
  word-wrap: break-word;
`;

const LocationContainer = styled('div')`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
`;

const LocationText = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 10px;
  font-family: Inter;
  font-weight: 400;
  line-height: 14px;
`;

const BlackDot = styled('span')`
  width: 2px;
  height: 2px;
  background: ${ColorType.softGrey};
  border-radius: 100px;
`;

const DetailContainer = styled('div')`
  gap: 8px;
  display: flex;
`;

const WarningContainer = styled('div')`
  padding: 3px;
  background: #ffd6e2;
  border-radius: 24px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const TitleContainer = styled('div')`
  gap: 4px;
  display: flex;
`;

const TitleSection = styled('div')`
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const ContentContainer = styled('div')`
  align-self: stretch;
  flex-direction: column;
  gap: 8px;
  display: flex;
`;
const Wrapper = styled('div')`
  flex: 1 1 0px;
  padding: 12px 20px 12px 12px;
`;

type ContainerProps = {
  isAlert?: boolean;
};
const Container = styled('div')<ContainerProps>`
  padding: 8px;
  background: ${({ isAlert }) => (isAlert ? '#ffe5ec' : ColorType.background)};
  border-bottom: 0.75px #b2b2b2 solid;
  display: inline-flex;
  width: -webkit-fill-available;
`;

export default NotificationCard;
