import { createTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material/styles/createPalette';
import { PaletteColor } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface Palette {
    greys: PaletteColor;
    tertiary: PaletteColor;
    typo: PaletteColor;
    light: PaletteColor;
  }
  interface PaletteOptions {
    greys?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    typo?: PaletteColorOptions;
    light?: PaletteColorOptions;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0018',
      dark: '#9b000ff5',
    },
    secondary: {
      main: '#64748B',
      dark: '#fff',
    },
    tertiary: {
      main: '#4A4A4A',
      dark: '#4A4A4A',
    },
    greys: {
      main: '##F0F0F0',
    },
    light: {
      main: '#fff',
    },
    typo: {
      main: '#FF0018',
      dark: '#4A4A4A',
      900: '#000',
      800: '#B2B2B2',
    },
  },
});
