import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface TableSelectToolbarProps {
  title: string;
  description?: string;
  numSelected: number;
}

const TableSelectToolbar = (props: TableSelectToolbarProps) => {
  const { t } = useTranslation();
  const { title, description, numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {t('table-select')}
        </Typography>
      ) : description ? (
        <Container>
          <SpanContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </SpanContainer>
        </Container>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Users
        </Typography>
      )}
      {/* {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )} */}
      {/* <Container>
        <SpanContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </SpanContainer>
      </Container> */}
    </Toolbar>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
`;

const SpanContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4;
  color: #2e3646;
`;

const Title = styled('span')`
  font-weight: 500;
  font-size: '16px';
  line-height: '24px';
`;
const Description = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5f6d7e;
`;

export default TableSelectToolbar;
