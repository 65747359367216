import { AppState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const isAuthenticated = ({ User: { authenticated } }: AppState) => {
  return authenticated;
};

export const isCodeAuth = ({ User: { doubleAuth } }: AppState) => {
  return doubleAuth;
};

export const isFullAuthenticated = createSelector(
  [isAuthenticated, isCodeAuth],
  (isAuth, isDouble) => isAuth && isDouble
);

export const userSelector = ({ User }: AppState) => {
  return User;
};

export const roleSelector = ({ User: { role } }: AppState) => {
  return role;
};

export const nameSelector = ({ User }: AppState) => {
  return User?.name ?? User.email;
};

export const emailSelector = ({ User }: AppState) => {
  return User.email;
};

export const initialsSelector = createSelector([nameSelector], (name) => {
  const words = name.split(' ');
  const initials = words.map((word: string) => word.charAt(0)).join('');
  return initials.toUpperCase();
});

export const isAdminSelector = createSelector(
  [roleSelector],
  (role) => role === 'super-admin'
);

export const isManagerSelector = createSelector(
  [roleSelector],
  (role) => role === 'admin'
);

export const isBuildingManagerSelector = createSelector(
  [roleSelector],
  (role) => role === 'building-manager'
);
