import { ReactElement } from 'react';
import LeftSideMenu from './LeftSideMenu';
import { styled } from '@mui/system';
import TopSideMenu from './TopSideMenu';
import Footer from './Footer';
import { useAppSelector } from '../utils/redux';
import { loadingSelector } from '../redux/selectors/ui';

type Props = {
  children: ReactElement;
};

const LayoutWithMenu = ({ children }: Props): JSX.Element => {
  const isLoading = useAppSelector(loadingSelector);
  return (
    <Container>
      <LeftSideMenu />
      <VerticalContainer>
        <TopSideMenu />
        <div style={{ flexGrow: 1 }}>{children}</div>
        {!isLoading && (
          <FooterContainer>
            <Footer />
          </FooterContainer>
        )}
      </VerticalContainer>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const VerticalContainer = styled('div')`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
`;

const FooterContainer = styled('div')`
  position: relative;
  bottom: 0;
  width: -webkit-fill-available;
`;

export default LayoutWithMenu;
