import ColorType from '../components/Theme/ColorType';
import { PoliciesTab } from '../components/Tabs';
import { styled } from '@mui/system';
import Tos from '../components/Policy/Tos';
import Privacy from '../components/Policy/Privacy';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../utils/redux';
import { isFullAuthenticated } from '../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { setAxiosHeaders } from '../utils/helper';

type Props = {
  activeTab: number;
};

const PrivacyAndTerms = ({ activeTab }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector(isFullAuthenticated);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  return (
    <Container>
      {!user && (
        <TitleContainer>
          <LogoContainer>
            <img
              src="/logo.png"
              alt="logo"
              height="50px"
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer', marginTop: 20 }}
            />
          </LogoContainer>
          <Title>{t('pp-terms-title')}</Title>
        </TitleContainer>
      )}
      <PoliciesTab
        activeTab={activeTab}
        TermsChildren={<Tos />}
        PrivacyChildren={<Privacy />}
      />
    </Container>
  );
};

const Container = styled('div')`
  padding: 8px;
  flex-direction: column;
  gap: 32px;
  display: flex;
  height: 94vh;
  @media (max-width: 535px) {
    height: 90vh;
  }
`;

const LogoText = styled('span')(() => ({
  color: ColorType.primary,
  fontWeight: 'bold',
  fontSize: 32,
  cursor: 'pointer',
  fontFamily: 'Inter',
}));

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 16px;
  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

const LogoContainer = styled('div')`
  margin-left: 3px;
  margin-top: -16px;
  @media (max-width: 535px) {
    margin-top: 0px;
  }
`;

const Title = styled('span')`
  font-size: 24px;
  font-family: Inter;
`;

export default PrivacyAndTerms;
