import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Links = [
  { name: 'Dashboard', nameFr: 'Accueil' },
  { name: 'My Account', nameFr: 'Mon compte' },
  { name: 'Users', nameFr: 'Utilisateurs' },
  { name: 'User Details', nameFr: 'Detail Utilisateur' },
  { name: 'Company Management', nameFr: 'Detail Entreprise' },
  { name: 'Company Details', nameFr: 'Detail Entreprise' },
  { name: 'Add Company', nameFr: 'Ajouter Entreprise' },
  { name: 'Building Management', nameFr: 'Gestion Bâtiment' },
  { name: 'Building Details', nameFr: 'Detail Batiment' },
  { name: 'Add Building', nameFr: 'Ajouter Bâtiment' },
  { name: 'Devices', nameFr: 'Dispositifs' },
  { name: 'Gateway', nameFr: 'Passerelle' },
  { name: 'Add Gateway', nameFr: 'Ajouter Passerelle' },
  { name: 'Controller', nameFr: 'Controlleurs' },
  { name: 'Add Controller', nameFr: 'Ajouter Controlleur' },
  { name: 'Sensor', nameFr: 'Capteurs' },
  { name: 'Add Sensor', nameFr: 'Ajouter Capteur' },
  { name: 'Schedulers', nameFr: 'Planificateur' },
];

interface BreadcrumbType {
  route: string;
  name: string;
  nameFr?: string;
  index: number;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatPathname = (pathname: string) => {
  const parts = pathname.split('-').filter((part) => part);
  return parts
    .map((part) => capitalizeFirstLetter(part.replace(/-/g, ' ')))
    .join(' ');
};

const Path = () => {
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  if (
    pathSegments.includes('company-details') ||
    pathSegments.includes('building-details') ||
    pathSegments.includes('user-details') ||
    pathSegments.includes('gateway') ||
    pathSegments.includes('controller') ||
    pathSegments.includes('sensor')
  ) {
    pathSegments.pop();
  }
  if (pathSegments[1] === '') {
    pathSegments[1] = 'Dashboard';
  }
  const breadcrumbItems = pathSegments
    .filter(Boolean)
    .map((route, index, routes) => {
      const breadcrumbRoute = routes.slice(0, index + 1).join('/');
      const name = formatPathname(route);
      let breadLink: BreadcrumbType = {
        route: breadcrumbRoute,
        name,
        nameFr: '',
        index: index + 1,
      };
      Links.map((link) =>
        name === link.name && activeLocale === 'fr'
          ? (breadLink = {
              route: breadcrumbRoute,
              name,
              nameFr: link?.nameFr,
              index: index + 1,
            })
          : { route: breadcrumbRoute, name, nameFr: '', index: index + 1 }
      );

      return breadLink;
    });

  return (
    <BreadCrumbContainer
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      style={{ marginBottom: 20 }}
    >
      {breadcrumbItems.map((path, index) => {
        const isLast = index === breadcrumbItems.length - 1;
        const link = isLast ? '' : `/${path.route}`;
        return (
          <StyledLink underline="hover" href={link} key={index} color="inherit">
            {activeLocale === 'fr' && path?.nameFr ? path.nameFr : path.name}
            {!isLast ? null : <NavigateNextIcon />}
          </StyledLink>
        );
      })}
    </BreadCrumbContainer>
  );
};

const BreadCrumbContainer = styled(Breadcrumbs)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
`;

export default Path;
