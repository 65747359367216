import { useState, useEffect } from 'react';
import { PasswordInput } from '../Input';
import { FormButton } from '../Button';
import H3 from '../Text/H3';
import CardItem from './CardItem';
import { Alert } from '../Snapbar';
import ColorType from '../Theme/ColorType';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import validator from 'validator';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import { resetPassword } from '../../redux/actions/userAction';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ErrorProp = {
  password?: string;
  message?: string;
};

const ResetCard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlToken = params.get('token');
  const urlEmail = params.get('email');
  const errorsSelector = useAppSelector(errorsArray);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<ErrorProp>({});

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setPassword('');
    };
  }, [errorsSelector]);

  const handleChangePassword = (text: string) => {
    setPassword(text);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    const passwordValidation = !validator.isStrongPassword(password);
    if (passwordValidation) {
      errors.password = t('reset-error');
    }
    return errors;
  };

  const handleOnClick = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      token: urlToken,
      email: urlEmail,
      password,
    };
    dispatch(resetPassword({ data, navigate }));
  };

  return (
    <CardItem bgColor="#1A1A1A">
      <>
        <div style={{ marginBottom: '50px' }}>
          <H3
            text={t('reset-title')}
            style={{ paddingBottom: 16, color: 'white' }}
          />
          <span style={{ color: '#B4B4B4' }}>{t('reset-description')}</span>
        </div>
        {errors?.message && (
          <Alert
            text={errors?.message}
            type="info"
            bgColor={ColorType.lightRed}
            txtColor={ColorType.darkRed}
            style={{ margin: '24px 0px 16px 0px' }}
          />
        )}
        <PasswordInput
          placeholder={t('reset-password')}
          value={password}
          OnChange={handleChangePassword}
          error={!!errors?.password}
          errorMessage={errors?.password}
          color="white"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormButton
            buttonLabel={t('reset-title')}
            color={'primary'}
            OnClick={handleOnClick}
            style={{
              borderRadius: 6,
              alignSelf: 'center',
              marginTop: 40,
              paddingInline: 30,
            }}
          />
          <FormButton
            buttonLabel={t('button-back')}
            variant="text"
            style={{ alignSelf: 'center' }}
            OnClick={() => navigate('/login')}
          />
        </div>
      </>
    </CardItem>
  );
};

export default ResetCard;
