import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { CodeValidationCard, LoginCard } from '../components/Card';
import { useAppDispatch } from '../utils/redux';
import { loginUser, verifyUserLogin } from '../redux/actions/userAction';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ColorType from '../components/Theme/ColorType';
import { setAxiosHeaders } from '../utils/helper';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState({});

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleSendData = () => {
    dispatch(verifyUserLogin({ data, setStep, navigate }));
  };

  const handleResendCode = () => {
    dispatch(loginUser({ data, setStep: null }));
  };

  const handleDisplay = () => {
    switch (step) {
      case 0:
        return <LoginCard setStep={setStep} setData={setData} />;
      case 1:
        return (
          <CodeValidationCard
            handleSendData={handleSendData}
            setData={setData}
            handleResendCode={handleResendCode}
          />
        );
      default:
        return <LoginCard setStep={setStep} setData={setData} />;
    }
  };
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: 38,
            lineHeight: '169%',
            color: ColorType.background,
          }}
        >
          BuildUp!
        </span>
      </div>
      {handleDisplay()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <span style={{ fontSize: 16, color: '#c9c9c9' }}>by</span>
        <img
          src="logo_company.svg"
          alt="THINGSFACTORY"
          style={{ height: '16px' }}
        />
      </div>
    </Container>
  );
};

const Container = styled('div')`
  background-image: url(login.png);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: 335px) {
    contain: strict;
  }
  @media (max-height: 560px) {
    overflow-y: scroll;
    height: 100%;
  }
`;

export default Login;
