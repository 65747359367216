import { useTranslation } from 'react-i18next';
import { FormButton } from '../Button';
import H3 from '../Text/H3';
import CardItem from './CardItem';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';

type SuccessProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const SuccessCard = ({ setStep }: SuccessProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnClick = () => {
    setStep((prev) => prev - 1);
    navigate('/');
  };

  return (
    <CardItem bgColor="#1A1A1A">
      <Container>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <H3
          text="If you have an account, A password reset link has been sent to your email address."
          style={{ paddingBottom: 16, color: 'white' }}
        />
        <Spacer />
        <FormButton
          buttonLabel={t('button-signin')}
          color={'primary'}
          OnClick={handleOnClick}
          style={{
            borderRadius: 6,
            marginTop: 20,
            paddingInline: 30,
          }}
        />
      </Container>
    </CardItem>
  );
};

const Container = styled('div')`
  text-align-last: center;
`;

const IconWrapper = styled('div')`
  text-align-last: center;
  background-color: #e9ffec;
  border-radius: 50px;
  display: inline-flex;
  padding: 24px;
  margin-bottom: 16px;
`;

const Icon = styled(CheckIcon)`
  color: #4bb725;
  font-size: 40px;
`;

const Spacer = styled('div')`
  margin-block: 24px;
`;

export default SuccessCard;
