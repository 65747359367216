import s from './style.module.css';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type InputType = {
  label?: string;
  options: any[];
  value: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  multiple?: boolean;
  OnChange?: (value: any) => void;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  displayOption?: (props: any, option: any) => JSX.Element;
};
const SelectSearch = ({
  label,
  options,
  value,
  disabled,
  errorMessage,
  multiple = false,
  OnChange,
  displayOption,
}: InputType) => {
  return (
    <>
      {label && (
        <FormHelperText id="label" className={s.label}>
          {label}
        </FormHelperText>
      )}
      <Autocomplete
        multiple={multiple}
        className={s.selectSearch}
        id="controller-selector"
        disabled={disabled}
        options={options}
        value={value}
        filterSelectedOptions
        onChange={OnChange}
        renderOption={(props, option) => {
          if (displayOption) return displayOption(props, option);
          else {
            return <li {...props}>{option.label}</li>;
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="choose controller" />
        )}
      />
      {errorMessage && (
        <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default SelectSearch;
