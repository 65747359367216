import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/system';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { HeadCell, Order } from '../../const/types';
import { useTranslation } from 'react-i18next';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { headCells, order, orderBy, onRequestSort } = props;
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: '20px' }}
          >
            {headCell.hasFilter ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Text>
                  {activeLocale === 'en' ? headCell.label : headCell.labelFr}
                </Text>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Text>
                {activeLocale === 'en' ? headCell.label : headCell.labelFr}
              </Text>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Text = styled('span')`
  font-size: 13px;
  font-weight: 500;
  font-family: Inter;
  color: #5f6d7e;
`;

export default EnhancedTableHead;
