import { useState, useEffect } from 'react';
import { Input, PasswordInput } from '../Input';
import { FormButton } from '../Button';
import { styled } from '@mui/system';
import H3 from '../Text/H3';
import SimpleLink from '../Link/SimpleLink';
import CardItem from './CardItem';
import { Alert } from '../Snapbar';
import ColorType from '../Theme/ColorType';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { loginUser } from '../../redux/actions/userAction';
import validator from 'validator';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import PopupTos from '../Modal/PopupTos';
import PopupPrivacy from '../Modal/PopupPrivacy';

type LoginProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<object>>;
};

type ErrorProp = {
  email?: string;
  password?: string;
  message?: string;
};

const LoginCard = ({ setStep, setData }: LoginProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const errorsSelector = useAppSelector(errorsArray);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState<ErrorProp>({});
  const [tosOpen, setTosOpen] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setEmail('');
      setPassword('');
    };
  }, [errorsSelector]);

  useEffect(() => {
    if (tosChecked && privacyChecked) setChecked(true);
  }, [privacyChecked, tosChecked]);

  const handleChangeInput = (text: string) => {
    setEmail(text);
  };

  const handleChangePassword = (text: string) => {
    setPassword(text);
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleOpenTos = () => setTosOpen(true);
  const handleCloseTos = () => {
    setTosChecked(false);
    setTosOpen(false);
  };

  const handleClickTos = () => {
    setTosChecked(true);
    setTosOpen(false);
  };

  const handleOpenPrivacy = () => setPrivacyOpen(true);
  const handleClosePrivacy = () => {
    setPrivacyChecked(false);
    setPrivacyOpen(false);
  };

  const handleClickPrivacy = () => {
    setPrivacyChecked(true);
    setPrivacyOpen(false);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    const passwordValidation = validator.isEmpty(password);
    if (!validator.isEmail(email)) {
      errors.email = 'A valid email is required';
    }
    if (passwordValidation) {
      errors.password = 'A password is required';
    }
    return errors;
  };

  const handleOnClick = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      email,
      password,
    };
    setData(data);
    dispatch(loginUser({ data, setStep }));
  };

  return (
    <CardItem title="BuildUp!" subTitle={t('login-title')} bgColor="#1A1A1A">
      <>
        {errors?.message && (
          <Alert
            text={errors?.message}
            type="info"
            bgColor={ColorType.lightRed}
            txtColor={ColorType.darkRed}
            style={{ marginBottom: '8px' }}
          />
        )}
        <H3
          text={t('login-login-title')}
          style={{ paddingBottom: 16, color: 'white' }}
        />
        <Input
          placeholder={t('login-email-label')}
          value={email}
          type="email"
          OnChange={handleChangeInput}
          error={!!errors?.email}
          errorMessage={errors?.email}
        />
        <Spacer />
        <PasswordInput
          placeholder={t('login-password-label')}
          value={password}
          OnChange={handleChangePassword}
          error={!!errors?.password}
          errorMessage={errors?.password}
          color="white"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px 12px 20px 0px',
          }}
        >
          <SimpleLink
            text={t('login-forgot-password')}
            color="typo.main"
            OnClick={handleForgotPassword}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={checked}
            // color="primary"
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: '#FF0018',
              },
            }}
            // style={{ color: 'white' }}
            onChange={() => setChecked((prev) => !prev)}
          />
          <span
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: '#B4B4B4',
            }}
          >
            {t('register-read')}{' '}
            <PopUpSpan onClick={handleOpenTos}>{t('register-tos')}</PopUpSpan>{' '}
            {t('linker-and')}{' '}
            <PopUpSpan onClick={handleOpenPrivacy}>
              {t('register-pp')}.
            </PopUpSpan>
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormButton
            buttonLabel={t('button-signin')}
            color={'primary'}
            disabled={!checked}
            OnClick={handleOnClick}
            style={{
              borderRadius: 6,
              // float: 'right',
              marginTop: 20,
              paddingInline: 30,
            }}
            sx={{
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#c0c0c0',
              },
            }}
          />
        </div>
        <PopupTos
          open={tosOpen}
          handleClose={handleCloseTos}
          handleClickTos={handleClickTos}
        />
        <PopupPrivacy
          open={privacyOpen}
          handleClose={handleClosePrivacy}
          handleClickPrivacy={handleClickPrivacy}
        />
      </>
    </CardItem>
  );
};

const Spacer = styled('div')`
  margin-top: 4px;
  margin-bottom: 4px;
`;

const PopUpSpan = styled('a')`
  color: #ff0018;
  cursor: pointer;
  text-decoration: unset;
`;

export default LoginCard;
