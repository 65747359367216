import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Fr from './fr-translation.json';
import En from './en-translation.json';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

i18n.use(initReactI18next).init({
  lng: 'fr',
  fallbackLng: 'fr',
  debug: true,
  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translation: En,
    },
    fr: {
      translation: Fr,
    },
  },
});

export default i18n;
