import { Circle } from '../svg';
import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';

type Props = {
  isShowCircle?: boolean;
  circleColor?: ColorType;
  bgColor?: ColorType;
  text: string;
};

type WrapperProps = {
  bgColor: ColorType;
};

const Status = ({
  isShowCircle,
  circleColor = ColorType.softGrey,
  bgColor = ColorType.lightGrey,
  text,
}: Props) => {
  return (
    <Container>
      <Wrapper bgColor={bgColor}>
        {isShowCircle && <Circle fill={circleColor} />}
        <StyledSpan bgColor={circleColor}>{text}</StyledSpan>
      </Wrapper>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 11px;
  background-color: ${({ bgColor }) => bgColor};
`;
const StyledSpan = styled('div')<WrapperProps>`
  font-size: 12px;
  color: ${({ bgColor }) => bgColor};
  font-family: Inter;
`;

export default Status;
