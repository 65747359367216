/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import { Wifi } from '../svg';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { Order } from '../../const/types';
import { gatewayHeadCells } from '../../const/arrays';
import {
  displayStatusDevices,
  getComparator,
  stableSort,
} from '../../utils/helper';
import { useNavigate } from 'react-router';
import { GatewayDTO } from '../../redux/types/gateway.dto';
import EmptyComponent from './EmptyComponent';
import { gatewaysSelector } from '../../redux/selectors/device';
import { styled } from '@mui/material/styles';
import ColorType from '../Theme/ColorType';
import { Status } from '../Badge';
import { Text } from '../Text';
import { loadingSelector } from '../../redux/selectors/ui';
import { CircularProgress } from '@mui/material';
import { getGateways } from '../../redux/actions/deviceAction';
import NewPaginationTable from './NewPaginationTable';
import { buildingSelector } from '../../redux/selectors/building';
import { useTranslation } from 'react-i18next';
import { companyIdSelector } from '../../redux/selectors/company';

const GatewayTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const gateways: GatewayDTO = useAppSelector(gatewaysSelector);
  const building = useAppSelector(buildingSelector);
  const companyId = useAppSelector(companyIdSelector);
  const loading = useAppSelector(loadingSelector);
  const [controls, setControls] = useState({
    page: 0,
    rowsPerPage: 15,
  });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [totalPages, setTotalPages] = useState(0);
  const [visibleRows, setVisibleRows] = useState<any>([]);

  useEffect(() => {
    dispatch(getGateways(building?.id, companyId));
    setTotalPages(gateways?.meta?.last_page);
  }, [controls]);

  const updateRows = useCallback(() => {
    const newRows = stableSort(gateways.items, getComparator(order, orderBy));
    setVisibleRows(newRows);
  }, [gateways.items, order, orderBy]);

  useEffect(() => {
    updateRows();
  }, [gateways, updateRows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (row: any) => {
    navigate(`gateway/${row.id}`, { state: { gateway: row } });
  };

  if (gateways?.items?.length === 0)
    return (
      <EmptyComponent
        component={t('devices-gateway')}
        icon={<Wifi width={56} height={56} />}
        handleClick={() => navigate(`add-gateway`)}
      />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar
          title={t('devices-gateway')}
          description={t('devices-gateway-description')}
          isWithButton
          handleButtonClick={() => navigate(`add-gateway`)}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress color="primary" />
            </LoadingContainer>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                headCells={gatewayHeadCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows?.map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const circleBg = displayStatusDevices('active').circleBg;
                  const bgColor = displayStatusDevices('active').bgColor;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                      sx={{
                        borderBottom: '1px solid #D1D9E2',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClick(row)}
                    >
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: '20px' }}
                      >
                        <Text>{row.id}</Text>
                      </TableCell> */}
                      <TableCell align="left">
                        <TitleDivContainer>
                          <AvatarContainer>
                            <Wifi
                              height={'14px'}
                              width={'14px'}
                              stroke={ColorType.lightGrey}
                            />
                          </AvatarContainer>
                          <TextContainer>
                            {/* @ts-ignore */}
                            <Text>{row.name}</Text>
                          </TextContainer>
                        </TitleDivContainer>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.building?.name}</Text>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.device_location?.floor}</Text>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.device_location?.floor_area}</Text>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.device_location?.floor_sub_area}</Text>
                      </TableCell>
                      <TableCell align="right">
                        <Status
                          text="active"
                          isShowCircle
                          circleColor={circleBg}
                          bgColor={bgColor}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <NewPaginationTable
          page={controls.page}
          totalPages={totalPages}
          setControls={setControls}
        />
      </Paper>
    </Box>
  );
};

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: ${ColorType.darkGrey};
  border-radius: 50px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export default GatewayTable;
