import {
  SET_ADMIN_MENU,
  SET_BUILDING_MANAGER_MENU,
  SET_BUILDING_MENU,
  SET_COMPONENT_MENU,
  SET_MANAGER_MENU,
  SET_SCHEDULER_MENU,
  SET_SUPER_ADMIN_MENU,
  SET_UNAUTHENTICATED,
} from '../types';
import { Actions } from '../types/action.types';
import { MenuState } from '../types/menu.types';

const initialState: MenuState = {
  menu: [
    {
      index: 1,
      name: 'Dashboard',
      nameFr: 'Tableau de bord',
      link: '/',
      icon: 'RectangleGroup',
    },
  ],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: Actions) {
  switch (action.type) {
    case SET_BUILDING_MANAGER_MENU:
      return {
        ...state,
        menu: [...state.menu, ...action.payload],
      };
    case SET_MANAGER_MENU:
      return {
        ...state,
        menu: [...state.menu, ...action.payload],
      };
    case SET_ADMIN_MENU:
    case SET_SUPER_ADMIN_MENU:
      return {
        ...state,
        menu: [...state.menu, ...action.payload],
      };
    case SET_BUILDING_MENU:
      const newMenuItem = {
        index: 5,
        name: 'Building Management',
        nameFr: 'Gestion du Batiment',
        link: '/building-management',
        icon: 'BuildingOffice',
      };
      // Check if the menu item already exists in the array
      const existingIndex = state.menu.findIndex(
        (item) => item.index === newMenuItem.index
      );

      if (existingIndex === -1) {
        return { ...state, menu: [...state.menu, newMenuItem] };
      }
      return { ...state, menu: [...state.menu] };
    case SET_COMPONENT_MENU:
      const newComponentItem = {
        index: 6,
        name: 'Devices',
        nameFr: 'Dispositifs',
        link: '/devices',
        icon: 'Bolt',
      };
      // Check if the menu item already exists in the array
      const existingComponentIndex = state.menu.findIndex(
        (item) => item.index === newComponentItem.index
      );

      if (existingComponentIndex === -1) {
        return { ...state, menu: [...state.menu, newComponentItem] };
      }
      return { ...state, menu: [...state.menu] };
    case SET_SCHEDULER_MENU:
      const newScheduleItem = {
        index: 7,
        name: 'Schedulers',
        nameFr: 'Planificateurs',
        link: '/schedulers',
        icon: 'CalendarDays',
      };
      // Check if the menu item already exists in the array
      const existingScheduleIndex = state.menu.findIndex(
        (item) => item.index === newScheduleItem.index
      );

      if (existingScheduleIndex === -1) {
        return { ...state, menu: [...state.menu, newScheduleItem] };
      }
      return { ...state, menu: [...state.menu] };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
