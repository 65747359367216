import { AppState } from '../store';

export const dashboardBuildingsSelector = ({
  dashboard: { buildings },
}: AppState) => {
  return buildings;
};

export const dashboardAlertsSelector = ({
  dashboard: { alerts },
}: AppState) => {
  return alerts;
};

export const dashboardResolvedAlertsSelector = ({
  dashboard: { archivedAlerts },
}: AppState) => {
  return archivedAlerts;
};

export const dashboardEventsSelector = ({
  dashboard: { events },
}: AppState) => {
  return events;
};
