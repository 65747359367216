import Typography from '@mui/material/Typography/Typography';
import { styled } from '@mui/material/styles';

type H6Props = {
  text: string;
  style?: React.CSSProperties;
};

const H6 = ({ text, style }: H6Props) => {
  return (
    <TextH6 color="typo.800" style={style}>
      {text}
    </TextH6>
  );
};

const TextH6 = styled(Typography)({
  fontSize: 13,
  fontWeight: 300,
  lineHeight: '21px',
});

export default H6;
