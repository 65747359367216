import { Checkbox } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const ChannelCard = ({
  device,
  deviceList,
  setDeviceList,
  assignedChannels,
}: {
  device: any;
  deviceList: any;
  setDeviceList: any;
  assignedChannels?: any[];
}) => {
  const { t } = useTranslation();
  const [channelA, setChannelA] = useState(false);
  const [channelB, setChannelB] = useState(false);

  useEffect(() => {
    assignedChannels?.map((control) => {
      if (control.channels === 'A' && device.value === control.controller_id) {
        setChannelA(true);
      } else if (
        control.channels === 'B' &&
        device.value === control.controller_id
      ) {
        setChannelB(true);
      }
    });
  }, []);

  useEffect(() => {
    let channel: string;
    if (channelA && channelB) {
      channel = 'AB';
    } else if (channelB) {
      channel = 'B';
    } else {
      channel = 'A';
    }
    setDeviceList((prevItems: any) => {
      const ch = `${device.value}_${channel}`;
      const itemExists = prevItems.some(
        (item: any) => item.id === device.value
      );
      if (itemExists) {
        return prevItems.map((item: any) =>
          item.id === device.value ? { ...item, device: ch, channel } : item
        );
      }
      return [...prevItems, { id: device.value, device: ch, channel }];
    });
  }, [channelA, channelB, device.value, setDeviceList]);

  useEffect(() => {
    deviceList.map((d: any) => {
      if (d.id === device.value && d.channel === 'A') {
        setChannelA(true);
      }
      if (d.id === device.value && d.channel === 'B') {
        setChannelB(true);
      }
      if (d.id === device.value && d.channel === 'AB') {
        setChannelA(true);
        setChannelB(true);
      }
    });
  }, [device]);

  return (
    <Container>
      <FlexContainer>
        <BoldSpan>
          {t('devices-Controller')}: {device.label}
        </BoldSpan>
      </FlexContainer>
      <CenteredContainer>
        <VerticalContainer>
          <span>{t('device-update-channel-use')} A</span>
          <Checkbox
            checked={channelA}
            sx={{
              color: 'grey',
              '&.Mui-checked': {
                color: '#FF0018',
              },
            }}
            onChange={() => setChannelA((prev: boolean) => !prev)}
          />
        </VerticalContainer>
        <VerticalContainer>
          <span>{t('device-update-channel-use')} B</span>
          <Checkbox
            checked={channelB}
            sx={{
              color: 'grey',
              '&.Mui-checked': {
                color: '#FF0018',
              },
            }}
            onChange={() => setChannelB((prev: boolean) => !prev)}
          />
        </VerticalContainer>
      </CenteredContainer>
    </Container>
  );
};

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
}));

const FlexContainer = styled('div')(() => ({
  flex: 1,
}));

const CenteredContainer = styled(FlexContainer)(() => ({
  justifyContent: 'center',
  gap: 8,
}));

const VerticalContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const BoldSpan = styled('span')(() => ({
  fontWeight: 'bold',
}));

export default ChannelCard;
