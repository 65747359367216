import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const buildingsSelector = ({ Building: { buildings } }: AppState) => {
  return buildings;
};

export const buildingFloorsSelector = (
  { Building: { buildings } }: AppState,
  id: number
) => {
  return buildings.find((building) => building.id === id)?.floors;
};

export const buildingsListSelector = createSelector(
  [buildingsSelector],
  (buildings) => {
    const idList = [
      { value: 0, label: 'Select a Building' },
      ...buildings.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    ];
    return idList;
  }
);

export const buildingsListFrSelector = createSelector(
  [buildingsSelector],
  (buildings) => {
    const idList = [
      { value: 0, label: 'Sélectionnez un batiment' },
      ...buildings.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    ];
    return idList;
  }
);

export const firstBuildingSelector = createSelector(
  [buildingsSelector],
  (buildings) => {
    if (buildings.length > 0) return buildings[0].id;
    return 0;
  }
);

export const buildingSelector = ({ Building: { building } }: AppState) => {
  return building;
};

export const buildingIdSelector = ({ Building: { building } }: AppState) => {
  return building?.id;
};

export const buildingsFullListSelector = createSelector(
  [buildingsSelector],
  (buildings) => {
    const idList = [
      { value: 0, label: 'All' },
      ...buildings.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    ];
    return idList;
  }
);
