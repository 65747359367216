import Path from '../components/Link/Path';
import { Clog, Sensor, Wifi } from '../components/svg';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { DeviceTab } from '../components/Tabs';
import { DeviceUpdateCard } from '../components/Card';
import { useLocation, useNavigate } from 'react-router';
import { buildingSelector } from '../redux/selectors/building';
import { updateComponent } from '../redux/actions/deviceAction';
import ColorType from '../components/Theme/ColorType';
import { setAxiosHeaders } from '../utils/helper';
import { useEffect } from 'react';

type HeaderContentProps = {
  icon: JSX.Element;
  title: string;
  description: string;
};

const ComponentDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const building = useAppSelector(buildingSelector);
  const gateway = location?.state?.gateway;
  const controller = location?.state?.controller;
  const sensor = location?.state?.sensor;
  const componentId = gateway?.id ?? controller?.id ?? sensor?.id;

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleUpdateComponent = (data: any, componentType: string) => {
    dispatch(
      updateComponent({
        componentType,
        componentId,
        data,
        navigate,
      })
    );
  };

  const HeaderContent = ({ icon, title, description }: HeaderContentProps) => (
    <TitleDivContainer>
      <AvatarContainer>{icon}</AvatarContainer>
      <TextContainer>
        <StyledBoldSpan>{title}</StyledBoldSpan>
        <StyledSpan>{description}</StyledSpan>
      </TextContainer>
    </TitleDivContainer>
  );

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          {gateway && (
            <HeaderContent
              icon={<Wifi height={'72px'} width={'72px'} />}
              title={`Gateway ${gateway.name}`}
              description={building?.name || ''}
            />
          )}
          {controller && (
            <HeaderContent
              icon={
                <Clog height={'72px'} width={'72px'} fill={ColorType.black} />
              }
              title={`Controller ${controller?.eui}`}
              description={building?.name || ''}
            />
          )}
          {sensor && (
            <HeaderContent
              icon={
                <Sensor height={'72px'} width={'72px'} fill={ColorType.black} />
              }
              title={`Sensor ${sensor?.eui}`}
              description={building?.name || ''}
            />
          )}
        </Grid>
      </GridHeaderContainer>
      <DeviceTab
        DeviceInformationChildren={
          <DeviceUpdateCard
            handleSendData={handleUpdateComponent}
            gateway={gateway}
            controller={controller}
            sensor={sensor}
          />
        }
      />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

export default ComponentDetails;
