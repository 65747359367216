import { AppState } from '../store';

export const errorsSelector = ({ UI: { errors } }: AppState) => {
  return errors;
};

export const loadingSelector = ({ UI: { loading } }: AppState) => {
  return loading;
};

export const snackBarSelector = ({ UI: { snackBar } }: AppState) => {
  return snackBar;
};
