import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const menuItemsSelector = ({ Menu: { menu } }: AppState) => {
  return menu;
};

export const menuListSelector = createSelector([menuItemsSelector], (menu) => {
  return menu;
});
