import { produce } from 'immer';
import { SET_USERS, SET_UNAUTHENTICATED, UPDATE_USERS } from '../types';
import { Actions } from '../types/action.types';
import { usersState } from '../types/users.types';

const initialState: usersState = {
  users: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: Actions) {
  switch (action.type) {
    case SET_USERS:
      const usersArray = action.payload;
      return produce(state, (draftState) => {
        draftState.users = usersArray;
      });
    case UPDATE_USERS:
      const newUser = action.payload;
      const newUsers = [...state.users];
      newUsers[newUser.id] = newUser;
      return {
        ...state,
        buildings: newUsers,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
