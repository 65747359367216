import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';

type Props = {
  circleColor?: ColorType;
  bgColor?: ColorType;
  text: string;
  icon?: JSX.Element;
};

type WrapperProps = {
  bgColor: ColorType;
};

const Chip = ({
  circleColor = ColorType.softGrey,
  bgColor = ColorType.lightGrey,
  text,
  icon,
}: Props) => {
  return (
    <Container>
      <Wrapper bgColor={bgColor}>
        {icon && icon}
        <StyledSpan bgColor={circleColor}>{text}</StyledSpan>
      </Wrapper>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 11px;
  background-color: ${({ bgColor }) => bgColor};
`;
const StyledSpan = styled('div')<WrapperProps>`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ bgColor }) => bgColor};
`;

export default Chip;
