import { useState, useRef, useEffect } from 'react';
import { FormButton, UnderlineButton } from '../Button';
import { styled } from '@mui/system';
import H3 from '../Text/H3';
import CardItem from './CardItem';
import { CodeInput } from '../Input';
import Timer from '../Timer/Timer';
import { Alert } from '../Snapbar';
import { useAppSelector } from '../../utils/redux';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

type LoginProps = {
  handleSendData: () => void;
  setData: React.Dispatch<React.SetStateAction<object>>;
  handleResendCode: () => void;
};

type ErrorProp = {
  code?: string;
};

const CodeValidationCard = ({
  handleSendData,
  setData,
  handleResendCode,
}: LoginProps) => {
  const { t } = useTranslation();
  const errorsSelector = useAppSelector(errorsArray);
  const inputRefs = useRef<any>([]);
  const [digits, setDigits] = useState(Array(4).fill(''));
  const [seconds, setSeconds] = useState(60);
  const [isLinkDisabled, setIsLinkDisabled] = useState(true);
  const [errors, setErrors] = useState<ErrorProp>({});

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
    };
  }, [errorsSelector]);

  useEffect(() => {
    if (seconds === 0) {
      setIsLinkDisabled(false);
    } else {
      setIsLinkDisabled(true);
    }

    return () => {
      setIsLinkDisabled(true);
    };
  }, [seconds, handleResendCode]);

  const handleChange = (index: any, value: any) => {
    const newDigits = [...digits];
    newDigits[index] = value;

    if (value !== '' && index < newDigits.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    setDigits(newDigits);
    setData((prev) => ({ ...prev, token: newDigits.join('') }));
  };

  const isCodeFilled = digits.every(Boolean);

  const handleResend = () => {
    setSeconds(60);
    handleResendCode();
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (!isCodeFilled) {
      errors.code = 'the code is required';
    }
    return errors;
  };

  const handleClick = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    handleSendData();
  };

  return (
    <CardItem
      title="BuildUp!"
      subTitle={t('login-title')}
      bgColor="#1A1A1A"
      alert={
        <Alert text={t('login-confirm-message')} type="info" hasIcon={false} />
      }
    >
      <>
        <H3
          text={t('login-confirm-digits')}
          style={{ paddingBottom: 16, color: 'white' }}
        />
        <CodeContainer>
          {digits.map((digit, index) => (
            <CodeInput
              key={index}
              value={digit}
              indexNumber={index}
              isDark
              OnChange={handleChange}
              inputRefs={inputRefs}
            />
          ))}
        </CodeContainer>
        {errors?.code && (
          <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
            {errors?.code}
          </FormHelperText>
        )}
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <UnderlineButton
            OnClick={handleResend}
            text={t('login-confirm-resend')}
            disabled={isLinkDisabled}
            isDarkMode
          />
          <Timer seconds={seconds} setSeconds={setSeconds} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormButton
            buttonLabel={t('button-verify')}
            color={'primary'}
            OnClick={handleClick}
            style={{
              borderRadius: 6,
              marginTop: 40,
            }}
          />
        </div>
      </>
    </CardItem>
  );
};

const CodeContainer = styled('div')`
  flex-direction: row;
  gap: 5px;
  display: flex;
  justify-content: center;
`;

export default CodeValidationCard;
