import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import ColorType from '../Theme/ColorType';
import { useTranslation } from 'react-i18next';
import { termsSectionsEn, termsSectionsFr } from '../Policy/PrivacyAndTos';
import i18n from '../../i18n/config';

type PopupTosProps = {
  open: boolean;
  handleClose: () => void;
  handleClickTos: () => void;
};

const PopupTos = ({ open, handleClose, handleClickTos }: PopupTosProps) => {
  const { t } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const termsSections =
    activeLocale === 'en' ? termsSectionsEn : termsSectionsFr;

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>{t('tos-title')}</DialogTitleContainer>
      <DialogSubTitleContainer>{t('tos-by-click')}</DialogSubTitleContainer>

      <DialogContentContainer>
        <TextContainer>
          <SubTitle>{t('last-terms-update')}</SubTitle>
          {termsSections.map((section) => (
            <div id={`section-${section.id}`}>
              <Text>
                <b>{section.title}</b>
              </Text>
              <Text dangerouslySetInnerHTML={{ __html: section.text }} />
            </div>
          ))}
        </TextContainer>
        <Container>
          <ExtraSpan>
            {t('tos-essential')}{' '}
            <a href="/tos" target="_blank">
              {t('tos-here')}
            </a>{' '}
            {t('tos-proceed')}
          </ExtraSpan>
          <br />
          <ExtraSpan>
            {t('tos-questions')} <b>contact@thingsfactory.com.</b>
          </ExtraSpan>
        </Container>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-accept')}
          color={'primary'}
          OnClick={handleClickTos}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    width: '60%',
    maxWidth: '60%',
    borderRadius: 16,
    '@media (max-width: 670px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontWeight: 600,
  fontFamily: 'Inter',
  color: ColorType.black,
  '@media (max-width: 670px)': {
    fontSize: 20,
  },
}));

const DialogSubTitleContainer = styled(DialogTitle)(() => ({
  paddingInline: 48,
  fontSize: 18,
  fontWeight: 400,
  fontFamily: 'Inter',
  color: ColorType.darkGrey,
  '@media (max-width: 670px)': {
    fontSize: 16,
  },
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

const TextContainer = styled('div')`
  margin: 0px 12px 0px 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${ColorType.softGrey};
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 670px) {
    margin: 0px 10px 10px 10px;
  }
`;

const SubTitle = styled('span')`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
`;

const Text = styled('span')`
  overflow-y: scroll;
  height: 35vh;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  @media (max-width: 670px) {
    height: 25vh;
  }
`;

const Container = styled('div')`
  padding: 16px 48px;
  display: flex;
  flex-direction: column;
`;

const ExtraSpan = styled('span')`
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export default PopupTos;
