import {
  ADD_BUILDING,
  SET_BUILDING,
  SET_BUILDINGS,
  SET_UNAUTHENTICATED,
  SET_USER_SECOND_AUTH,
  UPDATE_BUILDING,
} from '../types';
import { Actions } from '../types/action.types';
import { BuildingsState } from '../types/building.types';

const initialState: BuildingsState = {
  buildings: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: Actions) {
  switch (action.type) {
    case SET_USER_SECOND_AUTH:
      return {
        ...state,
        ...(action.payload?.buildings && {
          buildings: action.payload.buildings,
        }),
      };
    case SET_BUILDINGS:
      return {
        ...state,
        buildings: action.payload,
      };
    case SET_BUILDING:
      const building = state.buildings.find(
        (building) => building.id === action.payload
      );
      return {
        ...state,
        building,
      };
    case ADD_BUILDING:
      return {
        ...state,
        buildings: [...state.buildings, action.payload],
      };
    case UPDATE_BUILDING:
      const buildingData = action.payload;
      const buildingId = action.payload.id;
      const updatedBuildings = state.buildings.map((building) =>
        building.id === buildingId ? buildingData : building
      );
      return {
        ...state,
        buildings: updatedBuildings,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
