import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import { Input, SelectInput } from '../Input';
import {
  BuildingConfirmation,
  BuildingErrorProp,
  BuildingTelecom,
  MainWaterDiameters,
  RoomWaterDiameters,
} from '../../const/types';
import { useTranslation } from 'react-i18next';

type Props = {
  floor: string;
  buildingBasement: string;
  buildingTotalRooms: string;
  buildingPool: string;
  buildingElectricityRoom: string;
  buildingMechanicalRoom: string;
  buildingTelecomRoomNumber: string;
  buildingTelecomRoomLocation: string;
  buildingCentralWifi: number;
  buildingCentralLAN: number;
  buildingInternetNumber: number;
  buildingInternetLocation: string;
  buildingMainWaterSize: string;
  buildingRoomWaterSize: string;
  buildingOutlet: number;
  buildingPRV: string;
  buildingPlanSet: string;
  handleChangeFloor: (text: string) => void;
  handleChangeBuildingBasement: (text: string) => void;
  handleChangeBuildingTotalRooms: (text: string) => void;
  handleChangeBuildingPool: (text: string) => void;
  handleChangeBuildingElectricityRoom: (text: string) => void;
  handleChangeBuildingMechanicalRoom: (text: string) => void;
  handleChangeBuildingTelecomRoomNumber: (text: string) => void;
  handleChangeBuildingTelecomRoomLocation: (event: SelectChangeEvent) => void;
  handleChangeBuildingCentralWifi: (event: SelectChangeEvent) => void;
  handleChangeBuildingCentralLAN: (event: SelectChangeEvent) => void;
  handleChangeBuildingInternetNumber: (event: SelectChangeEvent) => void;
  handleChangeBuildingInternetLocation: (text: string) => void;
  handleChangeBuildingMainWaterSize: (event: SelectChangeEvent) => void;
  handleChangeBuildingRoomWaterSize: (event: SelectChangeEvent) => void;
  handleChangeBuildingOutlet: (event: SelectChangeEvent) => void;
  handleChangeBuildingPRV: (text: string) => void;
  handleChangeBuildingPlanSet: (text: string) => void;
  errors: BuildingErrorProp;
};

const EquipementCard = ({
  floor,
  buildingBasement,
  buildingTotalRooms,
  buildingPool,
  buildingElectricityRoom,
  buildingMechanicalRoom,
  buildingTelecomRoomNumber,
  buildingTelecomRoomLocation,
  buildingCentralWifi,
  buildingCentralLAN,
  buildingInternetNumber,
  buildingInternetLocation,
  buildingMainWaterSize,
  buildingRoomWaterSize,
  buildingOutlet,
  buildingPRV,
  buildingPlanSet,
  handleChangeFloor,
  handleChangeBuildingBasement,
  handleChangeBuildingTotalRooms,
  handleChangeBuildingPool,
  handleChangeBuildingElectricityRoom,
  handleChangeBuildingMechanicalRoom,
  handleChangeBuildingTelecomRoomNumber,
  handleChangeBuildingTelecomRoomLocation,
  handleChangeBuildingCentralWifi,
  handleChangeBuildingCentralLAN,
  handleChangeBuildingInternetNumber,
  handleChangeBuildingInternetLocation,
  handleChangeBuildingMainWaterSize,
  handleChangeBuildingRoomWaterSize,
  handleChangeBuildingOutlet,
  handleChangeBuildingPRV,
  handleChangeBuildingPlanSet,
  errors,
}: Props) => {
  const { t } = useTranslation();
  return (
    <GridContentContainer container>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-constructor-label')}
          placeholder={t('building-detail-constructor-placeholder')}
          type="string"
          value={floor}
          hasBorder
          OnChange={handleChangeFloor}
          error={!!errors?.floor}
          errorMessage={errors?.floor}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-basements-label')}
          placeholder={t('building-detail-basements-placeholder')}
          type="number"
          value={buildingBasement}
          hasBorder
          OnChange={handleChangeBuildingBasement}
          error={!!errors?.buildingBasement}
          errorMessage={errors?.buildingBasement}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-rooms-label')}
          type="number"
          placeholder={t('building-detail-rooms-placeholder')}
          value={buildingTotalRooms}
          hasBorder
          OnChange={handleChangeBuildingTotalRooms}
          error={!!errors?.buildingTotalRooms}
          errorMessage={errors?.buildingTotalRooms}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-pool-label')}
          type="number"
          placeholder={t('building-detail-pool-placeholder')}
          value={buildingPool}
          hasBorder
          OnChange={handleChangeBuildingPool}
          error={!!errors?.buildingPool}
          errorMessage={errors?.buildingPool}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-electricity-label')}
          type="number"
          placeholder={t('building-detail-electricity-placeholder')}
          value={buildingElectricityRoom}
          hasBorder
          OnChange={handleChangeBuildingElectricityRoom}
          error={!!errors?.buildingElectricityRoom}
          errorMessage={errors?.buildingElectricityRoom}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-mechanical-label')}
          type="number"
          placeholder={t('building-detail-mechanical-placeholder')}
          value={buildingMechanicalRoom}
          hasBorder
          OnChange={handleChangeBuildingMechanicalRoom}
          error={!!errors?.buildingMechanicalRoom}
          errorMessage={errors?.buildingMechanicalRoom}
        />
      </Grid>
      <GridContentContainer
        xs={12}
        sm={3.8}
        container
        sx={{ flexDirection: 'row', alignItems: 'flex-end' }}
      >
        <Grid sm={5.7} item>
          <Input
            label={t('building-detail-telecom-label')}
            type="number"
            placeholder={t('building-detail-telecom-placeholder')}
            value={buildingTelecomRoomNumber}
            hasBorder
            OnChange={handleChangeBuildingTelecomRoomNumber}
            error={!!errors?.buildingTelecomRoomNumber}
            errorMessage={errors?.buildingTelecomRoomNumber}
          />
        </Grid>
        <Grid sm={5.7} item>
          <SelectInput
            label={t('building-detail-located-label')}
            placeholder={t('building-detail-located-placeholder')}
            values={BuildingTelecom}
            value={buildingTelecomRoomLocation}
            OnChange={handleChangeBuildingTelecomRoomLocation}
            error={!!errors?.buildingTelecomRoomLocation}
            errorMessage={errors?.buildingTelecomRoomLocation}
          />
        </Grid>
      </GridContentContainer>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-wifi-label')}
          placeholder={t('building-detail-wifi-placeholder')}
          values={BuildingConfirmation}
          value={String(buildingCentralWifi)}
          OnChange={handleChangeBuildingCentralWifi}
          error={!!errors?.buildingCentralWifi}
          errorMessage={errors?.buildingCentralWifi}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-lan-label')}
          placeholder={t('building-detail-lan-placeholder')}
          values={BuildingConfirmation}
          value={String(buildingCentralLAN)}
          OnChange={handleChangeBuildingCentralLAN}
          error={!!errors?.buildingCentralLAN}
          errorMessage={errors?.buildingCentralLAN}
        />
      </Grid>
      <GridContentContainer
        xs={12}
        sm={3.8}
        container
        sx={{ flexDirection: 'row', alignItems: 'flex-end' }}
      >
        <Grid sm={5.7} item>
          <SelectInput
            label={t('building-detail-internet-label')}
            placeholder={t('building-detail-internet-placeholder')}
            values={BuildingConfirmation}
            value={String(buildingInternetNumber)}
            OnChange={handleChangeBuildingInternetNumber}
            error={!!errors?.buildingInternetNumber}
            errorMessage={errors?.buildingInternetNumber}
          />
        </Grid>
        <Grid sm={5.7} item>
          <Input
            placeholder={t('building-detail-located-label')}
            value={buildingInternetLocation}
            hasBorder
            OnChange={handleChangeBuildingInternetLocation}
            error={!!errors?.buildingInternetLocation}
            errorMessage={errors?.buildingInternetLocation}
          />
        </Grid>
      </GridContentContainer>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-main-diameter-label')}
          placeholder={t('building-detail-main-diameter-placeholder')}
          values={MainWaterDiameters}
          value={buildingMainWaterSize}
          OnChange={handleChangeBuildingMainWaterSize}
          error={!!errors?.buildingMainWaterSize}
          errorMessage={errors?.buildingMainWaterSize}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-room-water-label')}
          placeholder={t('building-detail-room-water-placeholder')}
          values={RoomWaterDiameters}
          value={buildingRoomWaterSize}
          OnChange={handleChangeBuildingRoomWaterSize}
          error={!!errors?.buildingRoomWaterSize}
          errorMessage={errors?.buildingRoomWaterSize}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-outlet-label')}
          placeholder={t('building-detail-outlet-placeholder')}
          values={BuildingConfirmation}
          value={String(buildingOutlet)}
          OnChange={handleChangeBuildingOutlet}
          error={!!errors?.buildingOutlet}
          errorMessage={errors?.buildingOutlet}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-prv-label')}
          type="number"
          placeholder={t('building-detail-prv-placeholder')}
          value={buildingPRV}
          hasBorder
          OnChange={handleChangeBuildingPRV}
          error={!!errors?.buildingPRV}
          errorMessage={errors?.buildingPRV}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-plan-label')}
          placeholder={t('building-detail-plan-placeholder')}
          value={buildingPlanSet}
          hasBorder
          OnChange={handleChangeBuildingPlanSet}
          error={!!errors?.buildingPlanSet}
          errorMessage={errors?.buildingPlanSet}
        />
      </Grid>
    </GridContentContainer>
  );
};

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default EquipementCard;
