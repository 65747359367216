import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { ScheduleDTO } from '../types/schedule.dto';

export const schedulesSelector = ({ Schedule: { schedules } }: AppState) => {
  return schedules as ScheduleDTO[];
};

export const schedulesByControllerSelector = (controllerId: number) =>
  createSelector([schedulesSelector], (schedules: ScheduleDTO[]) =>
    schedules.filter((schedule) =>
      schedule?.controllers?.some(
        (controller) => controller.id === controllerId
      )
    )
  );
