import { useEffect, useState } from 'react';
import Path from '../components/Link/Path';
import { styled } from '@mui/material/styles';
import { ActionButton } from '../components/Button';
import { Clock } from '../components/svg';
import ColorType from '../components/Theme/ColorType';
import { Theme } from '@mui/system';
import { Grid } from '@mui/material';
import { SchedulerTab as Tab } from '../components/Tabs';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import ScheduleModal from '../components/Modal/ScheduleModal';
import { ScheduleByAll, ScheduleByControllerCard } from '../components/Card';
import { getSchedules } from '../redux/actions/scheduleAction';
import { companyIdSelector } from '../redux/selectors/company';
import { nameSelector, roleSelector } from '../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
});

const SchedulerManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companyId = useAppSelector(companyIdSelector);
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    dispatch(getSchedules(companyId));
  }, [companyId, dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  return (
    <Container>
      <HeaderContainer container>
        <StyledDiv item>
          <Path />
          <TitleSpan>
            {userName},{' '}
            <DescriptionSpan style={{ fontSize: 24 }}>
              {handleDisplayRole(role)}
            </DescriptionSpan>
          </TitleSpan>
          <DescriptionSpan>{t('schedules-title')}</DescriptionSpan>
        </StyledDiv>
        <ButtonContainer item>
          <ActionButton
            buttonLabel={t('schedules-add-button')}
            color={'primary'}
            iconLeft={
              <Clock stroke={ColorType.background} width={20} height={20} />
            }
            OnClick={handleOpen}
            style={{
              borderRadius: 6,
              gap: 5,
            }}
          />
        </ButtonContainer>
      </HeaderContainer>
      <Tab
        ByControllerChildren={<ScheduleByControllerCard />}
        ByScheduleChildren={<ScheduleByAll />}
      />
      <ScheduleModal open={open} handleClose={handleClose} style={style} />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const ButtonContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

export default SchedulerManagement;
