/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, MenuItem } from '@mui/material';
import s from './style.module.css';
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

type InputType = {
  label?: string;
  values: any[];
  value: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  multiple?: boolean;
  OnChange?: (event: SelectChangeEvent) => void;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};
const SelectInput = ({
  label,
  values,
  value,
  OnChange,
  error,
  disabled,
  errorMessage,
  multiple = false,
  open,
  onClose,
  onOpen,
}: InputType) => {
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  return (
    <div>
      {label && (
        <FormHelperText id="label" className={s.label}>
          {label}
        </FormHelperText>
      )}
      <Select
        error={error}
        disabled={disabled}
        labelId="role-label"
        id="role-select"
        fullWidth
        value={value}
        className={s.selectInput}
        IconComponent={ExpandMoreIcon}
        onChange={OnChange}
        multiple={multiple}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
      >
        {values.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option?.placeholder}
          >
            {activeLocale === 'fr' && option.labelFr
              ? option.labelFr
              : option.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default SelectInput;
