import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChannelCard from '../Card/ChannelCard';

type ChannelModalProps = {
  devices?: any[];
  open: boolean;
  setChannels: any;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  assignedChannels?: any[];
};

const ChannelModal = ({
  devices,
  setChannels,
  assignedChannels,
  open,
  handleClose,
}: ChannelModalProps) => {
  const { t } = useTranslation();
  const [deviceList, setDeviceList] = useState([]);

  const handleSendData = () => {
    setChannels(deviceList);
    handleClose();
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>
        {t('device-update-channel-button')}
      </DialogTitleContainer>
      <DialogContentContainer>
        {devices?.map((device) => {
          return (
            <ChannelCard
              device={device}
              deviceList={deviceList}
              setDeviceList={setDeviceList}
              assignedChannels={assignedChannels}
            />
          );
        })}
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-confirm')}
          color={'primary'}
          OnClick={handleSendData}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '80%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontSize: 28,
  fontWeight: 600,
  fontFamily: 'Inter',
  color: '#2E3646',
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
  gap: 18,
  display: 'flex',
  flexDirection: 'column',
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

export default ChannelModal;
