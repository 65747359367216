import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Input } from '../Input';
import { FormButton } from '../Button';
import H3 from '../Text/H3';
import CardItem from './CardItem';
import { Alert } from '../Snapbar';
import ColorType from '../Theme/ColorType';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import validator from 'validator';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import { sendEmailforgotPassword } from '../../redux/actions/userAction';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ForgotProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

type ErrorProp = {
  email?: string;
  message?: string;
};

const ForgotCard = ({ setStep }: ForgotProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorsSelector = useAppSelector(errorsArray);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<ErrorProp>({});

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setEmail('');
    };
  }, [errorsSelector]);

  const handleChangeInput = (text: string) => {
    setEmail(text);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (!validator.isEmail(email)) {
      errors.email = t('forgot-error');
    }
    return errors;
  };

  const handleOnClick = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(sendEmailforgotPassword({ email, setStep }));
  };

  return (
    <CardItem bgColor="#1A1A1A">
      <>
        <div style={{ marginBottom: '50px' }}>
          <H3
            text={`${t('forgot-password')}?`}
            style={{ paddingBottom: 16, color: 'white' }}
          />
          <span style={{ color: '#B4B4B4' }}>{t('forgot-description')}</span>
        </div>
        {errors?.message && (
          <Alert
            text={errors?.message}
            type="info"
            bgColor={ColorType.lightRed}
            txtColor={ColorType.darkRed}
            style={{ margin: '24px 0px 16px 0px' }}
          />
        )}
        <Input
          placeholder={t('login-email-label')}
          value={email}
          type="email"
          OnChange={handleChangeInput}
          error={!!errors?.email}
          errorMessage={errors?.email}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormButton
            buttonLabel={t('forgot-reset')}
            color={'primary'}
            OnClick={handleOnClick}
            style={{
              borderRadius: 6,
              marginTop: 40,
              alignSelf: 'center',
              paddingInline: 30,
            }}
          />

          <FormButton
            buttonLabel={t('button-back')}
            variant="text"
            style={{ alignSelf: 'center' }}
            OnClick={() => navigate('/login')}
          />
        </div>
      </>
    </CardItem>
  );
};

const PopUpSpan = styled('a')`
  color: #ff0018;
  cursor: pointer;
  text-decoration: unset;
`;

export default ForgotCard;
