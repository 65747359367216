import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  SET_SNACK,
  SET_ERROR_MESSAGE,
} from '../types';

const initialState = {
  loading: false,
  errors: null,
  snackBar: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        errors: { ...state.errors, message: action.payload },
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
        snackBar: null,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case SET_SNACK:
      return {
        ...state,
        snackBar: action.payload,
      };
    default:
      return state;
  }
}
