const Company = `
<svg id="line" height="22px" width="23px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <g fill="#293a4c">
    <g>
      <path d="m56 52.5h-40c-2.48145 0-4.5-2.01855-4.5-4.5v-18c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v18c0 .82715.67285 1.5 1.5 1.5h40c.82715 0 1.5-.67285 1.5-1.5v-28c0-.82715-.67285-1.5-1.5-1.5h-15c-.82812 0-1.5-.67188-1.5-1.5s.67188-1.5 1.5-1.5h15c2.48145 0 4.5 2.01855 4.5 4.5v28c0 2.48145-2.01855 4.5-4.5 4.5z"/>
      <path d="m30.00195 60.5c-.06201 0-.125-.00391-.18799-.01172-.82227-.10254-1.40527-.85254-1.30225-1.6748l1-8c.10254-.82227.85303-1.40332 1.67432-1.30176.82227.10254 1.40527.85254 1.30225 1.6748l-1 8c-.09473.75879-.74072 1.31348-1.48633 1.31348z"/>
      <path d="m41.99805 60.5c-.74512 0-1.3916-.55469-1.48633-1.31348l-1-8c-.10254-.82227.48047-1.57227 1.30176-1.6748.81934-.09961 1.57227.47949 1.6748 1.30176l1 8c.10254.82227-.48047 1.57227-1.30176 1.6748-.06348.00781-.12598.01172-.18848.01172z"/>
      <path d="m48 60.5h-24c-.82861 0-1.5-.67188-1.5-1.5s.67139-1.5 1.5-1.5h24c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
      <path d="m59 44.5h-46c-.82861 0-1.5-.67188-1.5-1.5s.67139-1.5 1.5-1.5h46c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
      <path d="m53 24.5h-12c-.82812 0-1.5-.67188-1.5-1.5s.67188-1.5 1.5-1.5h12c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
      <path d="m53 31.5h-12c-.82812 0-1.5-.67188-1.5-1.5s.67188-1.5 1.5-1.5h12c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
      <path d="m53 38.5h-34c-.82861 0-1.5-.67188-1.5-1.5s.67139-1.5 1.5-1.5h34c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
      <path d="m36.5 48.5h-1c-.82812 0-1.5-.67188-1.5-1.5s.67188-1.5 1.5-1.5h1c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
    </g>
    <g>
      <path d="m19 31.5h-14c-.82861 0-1.5-.67188-1.5-1.5v-25c0-.82812.67139-1.5 1.5-1.5h14c.82861 0 1.5.67188 1.5 1.5v25c0 .82812-.67139 1.5-1.5 1.5zm-12.5-3h11v-22h-11z"/>
      <path d="m35 31.5h-10c-.82861 0-1.5-.67188-1.5-1.5v-19c0-.82812.67139-1.5 1.5-1.5h10c.82812 0 1.5.67188 1.5 1.5v19c0 .82812-.67188 1.5-1.5 1.5zm-8.5-3h7v-16h-7z"/>
      <path d="m34 31.5h-29c-.82861 0-1.5-.67188-1.5-1.5s.67139-1.5 1.5-1.5h29c.82812 0 1.5.67188 1.5 1.5s-.67188 1.5-1.5 1.5z"/>
    </g>
    <path d="m9.49268 12.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <path d="m14.50732 12.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <path d="m9.49268 19.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <path d="m14.50732 19.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <path d="m9.49268 26.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <path d="m14.50732 26.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    <g>
      <path d="m30 19.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
      <path d="m30 26.5c-.82861 0-1.5-.67188-1.5-1.5v-2c0-.82812.67139-1.5 1.5-1.5s1.5.67188 1.5 1.5v2c0 .82812-.67139 1.5-1.5 1.5z"/>
    </g>
  </g>
</svg>


`;

export default Company;
