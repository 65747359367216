import Typography from '@mui/material/Typography/Typography';
import { styled } from '@mui/material/styles';

type SubTitleProps = {
  text: string;
  isUpperCase?: boolean;
};

const SubTitle = ({ text, isUpperCase }: SubTitleProps) => {
  return (
    <TextSubTitle color="typo.dark" isUpperCase={isUpperCase}>
      {text}
    </TextSubTitle>
  );
};

type TextSubTitleProps = {
  isUpperCase?: boolean;
};

const TextSubTitle = styled(Typography)(
  ({ isUpperCase = false }: TextSubTitleProps) => ({
    fontSize: 14,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '17px',
    textTransform: isUpperCase ? 'uppercase' : 'none',
  })
);

export default SubTitle;
