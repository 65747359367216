import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { useNavigate } from 'react-router';
import { NotificationCard } from '../Card';
import { dashboardAlertsSelector } from '../../redux/selectors/dashboard';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useEcho from '../../utils/echo';
import { companyIdSelector } from '../../redux/selectors/company';
import { getAlertData } from '../../redux/actions/dashboardAction';

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};
const INITIALITEMS = 5;

const NotificationMenu = ({ anchorEl, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(dashboardAlertsSelector);
  const navigate = useNavigate();
  const echo = useEcho();
  const companyId = useAppSelector(companyIdSelector);

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    // Here we are going to listen for real-time events.
    if (echo && companyId) {
      // @ts-expect-error private
      const channel = echo.private(`buildup.company.${companyId}`);

      channel.listen('leak.detected', (event: any) => {
        dispatch(getAlertData());
      });

      channel.listen('leak.sealed', (event: any) => {
        dispatch(getAlertData());
      });

      channel.listen('alert.resolved', (event: any) => {
        dispatch(getAlertData());
      });

      return () => {
        //@ts-expect-error leave echo
        echo.leave(`buildup.company.${companyId}`);
      };
    }
  }, [echo]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleClick = async (controllerId: number) => {
    try {
      dispatch({ type: 'LOADING_UI' });
      const res = await axios.get(`/devices/${controllerId}`);
      const device = res?.data?.data;
      dispatch({ type: 'CLEAR_ERRORS' });
      navigate(`/devices/controller/${controllerId}`, {
        state: { controller: device },
      });
    } catch (err) {
      //@ts-expect-error response
      const errors = err?.response?.data?.data;
      dispatch({ type: 'SET_ERRORS', payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
  };

  return (
    <Container
      anchorEl={anchorEl}
      id="notif-menu"
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <TitleContainer>
        <TitleText>Notifications</TitleText>
      </TitleContainer>
      {alerts?.map((alert: any, index: number) => {
        const isAlert = alert.type === 'Leak detected on Sensor';

        return (
          <FullWidthMenuItem key={index}>
            <NotificationCard
              title={alert.type}
              location={alert.device_location}
              date={alert.created_at}
              isAlert={isAlert}
              alertId={alert.device_id}
              handleClick={handleClick}
            />
          </FullWidthMenuItem>
        );
      })}
      {alerts.length > INITIALITEMS && (
        <FooterContainer>
          <TitleText onClick={toggleShowAll}>
            {showAll ? t('button-less') : t('button-more')}
          </TitleText>
        </FooterContainer>
      )}
    </Container>
  );
};

const Container = styled(Menu)``;

const TitleContainer = styled('div')`
  padding: 8px;
  border-bottom: 1px #b2b2b2 solid;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TitleText = styled('span')`
  text-align: justify;
  color: #4a4a4a;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

const FooterContainer = styled('div')`
  padding: 8px;
  border-top: 1px #b2b2b2 solid;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const FullWidthMenuItem = styled(MenuItem)`
  padding: 1px 0px;
`;

export default NotificationMenu;
