import { useEffect, useState } from 'react';
import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
import axios from 'axios';
window.Pusher = Pusher;

const useEcho = () => {
  const [echoInstance, setEchoInstance] = useState(null);

  useEffect(() => {
    // axios.defaults.headers.common[
    //   'Authorization'
    // ] = `Bearer 945|XDvQhcd9bi7impUt0pTuPSvFZkjz554hq6xFPr3Ja289d26f`;
    // We are going to create the Echo instance here...
    const url = process.env.REACT_APP_API_URL;
    const baseUrl = url.replace('/api/v1', '');
    const echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.REACT_APP_REVERB_APP_KEY,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post(`${baseUrl}/broadcasting/auth`, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
      wsHost: process.env.REACT_APP_REVERB_HOST,
      wsPort: process.env.REACT_APP_REVERB_PORT,
      wssPort: '443',
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
    });
    setEchoInstance(echo);
  }, []);

  return echoInstance;
};

export default useEcho;
