import axios from 'axios';
import {
  SET_USERS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ERRORS,
  SET_SNACK,
  UPDATE_USERS,
} from '../types';

export const getUsers = (buildingId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const source = axios.CancelToken.source();
    let res;
    if (buildingId) {
      res = await axios.get(`/users?building_id=${buildingId}`, {
        cancelToken: source.token,
      });
    } else {
      res = await axios.get(`/users`, { cancelToken: source.token });
    }
    dispatch({ type: SET_USERS, payload: res.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getAdminUsers = (companyId, buildingId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    let res;
    if (buildingId) {
      res = await axios.get(
        `/users?company_id=${companyId}&building_id=${buildingId}`
      );
    } else {
      res = await axios.get(`/users?company_id=${companyId}`);
    }
    dispatch({ type: SET_USERS, payload: res.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const updateUsers =
  ({ data, userId, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { id, email, status, ...newData } = data;

      await axios.post(`/user/update/${userId}`, newData);
      dispatch({ type: UPDATE_USERS, payload: data });
      dispatch({ type: CLEAR_ERRORS });
      const snackBar = {
        isOpen: true,
        text: 'User Updated!',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      navigate('/users');
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const changeUserPin =
  ({ userId, handleClose }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/user/generate/pin/${userId}`);
      dispatch({ type: CLEAR_ERRORS });
      const snackBar = {
        isOpen: true,
        text: 'Pin Updated!',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };
