import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';
import { BuildingOffice, Clog, Sensor, Wifi } from '../svg';
import { StatusKPI } from '../../const/types';

type Props = {
  buildingName: string;
  status: string;
  gatewayKPI: number;
  controllerKPI: number;
  sensorKPI: number;
};
const BuildingKpiCard = ({
  buildingName,
  status,
  gatewayKPI,
  controllerKPI,
  sensorKPI,
}: Props) => {
  const isWarning = status === StatusKPI.warning;
  return (
    <Container>
      <HeaderContainer>
        <TitleAvatarContainer>
          <Avatar>
            <IconContainer>
              <BuildingOffice />
            </IconContainer>
          </Avatar>
          <TitleContainer>
            <Title>{buildingName}</Title>
          </TitleContainer>
        </TitleAvatarContainer>
        <BadgeContainer isWarning={isWarning}>
          <Circle isWarning={isWarning} />
          <BadgeText>{status}</BadgeText>
        </BadgeContainer>
      </HeaderContainer>
      <KpiContainer>
        <KpiWrapper>
          <Clog width={14} height={14} fill={ColorType.black} />
          <KpiText>{gatewayKPI}</KpiText>
        </KpiWrapper>
        <KpiWrapper>
          <Wifi width={14} height={14} />
          <KpiText>{controllerKPI}</KpiText>
        </KpiWrapper>
        <KpiWrapper>
          <Sensor width={14} height={14} fill={ColorType.black} />
          <KpiText>{sensorKPI}</KpiText>
        </KpiWrapper>
      </KpiContainer>
    </Container>
  );
};

const Container = styled('div')`
  height: 130px;
  padding: 16px 20px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  overflow: hidden;
  border: 0.5px ${ColorType.softGrey} solid;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  display: flex;
`;
const HeaderContainer = styled('div')`
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
`;
const TitleAvatarContainer = styled('div')`
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
`;
const Avatar = styled('div')`
  width: 40px;
  height: 40px;
  background: #f0f0f0;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const IconContainer = styled('div')`
  display: flex;
`;
const TitleContainer = styled('div')`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;
const Title = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

type BadgeProp = {
  isWarning: boolean;
};
const BadgeContainer = styled('div')<BadgeProp>`
  padding: 4px 8px;
  background: ${({ isWarning }) =>
    isWarning ? '#FFF4EB' : ColorType.lightGreen};
  border-radius: 11px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
`;
const Circle = styled('div')<BadgeProp>`
  width: 8px;
  height: 8px;
  background: ${({ isWarning }) =>
    isWarning ? ColorType.softOrange : '#0ff780'};
  border-radius: 100px;
`;
const BadgeText = styled('span')`
  color: ${ColorType.black};
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;
const KpiContainer = styled('div')`
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
`;
const KpiWrapper = styled('div')`
  padding: 4px 8px;
  background: ${ColorType.lightGrey};
  border-radius: 11px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
`;
const KpiText = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  word-wrap: break-word;
`;

export default BuildingKpiCard;
