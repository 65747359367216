import { useState, useLayoutEffect, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useLocation, useNavigate } from 'react-router';
import { BuildingOffice2 } from '../components/svg';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { SelectChangeEvent } from '@mui/material/Select';
import validator from 'validator';
import { updateBuilding } from '../redux/actions/buildingAction';
import { BuildingDTO } from '../redux/types/building.dto';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ColorType from '../components/Theme/ColorType';
import {
  ContactStaffCard,
  EquipementCard,
  GeneralInformationCard,
} from '../components/Card';
import {
  BuildingConfirmation,
  BuildingControlSystems,
  BuildingErrorProp,
  BuildingHVACSystems,
  BuildingStatuses,
  BuildingTelecom,
  BuildingTypes,
  MainWaterDiameters,
  NumberOfFloors,
  RoomWaterDiameters,
} from '../const/types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';
import { isAdminSelector } from '../redux/selectors/user';
import { companyIdSelector } from '../redux/selectors/company';

const BuildingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const building: BuildingDTO = location?.state?.building;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const company = useAppSelector(companyIdSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const [infoOpen, setInfoOpen] = useState(true);
  const [contactOpen, setContactOpen] = useState(true);
  const [equipementOpen, setEquipementOpen] = useState(true);
  const [buildingId, setBuildingId] = useState(0);
  const [buildingName, setBuildingName] = useState('');
  const [status, setStatus] = useState('1');
  const [buildingAddress, setBuildingAddress] = useState('');
  const [buildingTotalArea, setBuildingTotalArea] = useState('');
  const [buildingType, setBuildingType] = useState<string | undefined>('');
  const [buildingControlSystem, setBuildingControlSystem] = useState<
    string | undefined
  >('');
  const [buildingLocation, setBuildingLocation] = useState('');
  const [buildingOpeningDate, setBuildingOpeningDate] = useState('');
  const [buildingConstructionDate, setBuildingConstructionDate] = useState('');
  const [buildingOwner, setBuildingOwner] = useState('');
  const [buildingGeneralContractor, setBuildingGeneralContractor] =
    useState('');
  const [buildingElectrician, setBuildingElectrician] = useState('');
  const [buildingPlumbers, setBuildingPlumbers] = useState('');
  const [buildingEngineers, setBuildingEngineers] = useState('');
  const [buildingArchitects, setBuildingArchitects] = useState('');
  const [buildingTechnology, setBuildingTechnology] = useState('');
  const [buildingHVAC, setBuildingHVAC] = useState('');
  const [buildingHVACSystem, setBuildingHVACSystem] = useState('');
  const [buildingProjectManager, setBuildingProjectManager] = useState('');
  const [buildingOperationsManager, setBuildingOperationsManager] =
    useState('');
  const [buildingHelp, setBuildingHelp] = useState('');
  const [buildingInsurer, setBuildingInsurer] = useState('');
  const [buildingReferal, setBuildingReferal] = useState('');
  const [buildingSales, setBuildingSales] = useState('');
  const [floor, setFloor] = useState('0');
  const [buildingBasement, setBuildingBasement] = useState('');
  const [buildingTotalRooms, setBuildingTotalRooms] = useState('');
  const [buildingPool, setBuildingPool] = useState('');
  const [buildingElectricityRoom, setBuildingElectricityRoom] = useState('');
  const [buildingMechanicalRoom, setBuildingMechanicalRoom] = useState('');
  const [buildingTelecomRoomNumber, setBuildingTelecomRoomNumber] =
    useState('');
  const [buildingTelecomRoomLocation, setBuildingTelecomRoomLocation] =
    useState('');
  const [buildingCentralWifi, setBuildingCentralWifi] = useState(0);
  const [buildingCentralLAN, setBuildingCentralLAN] = useState(0);
  const [buildingInternetNumber, setBuildingInternetNumber] = useState(0);
  const [buildingInternetLocation, setBuildingInternetLocation] = useState('');
  const [buildingMainWaterSize, setBuildingMainWaterSize] = useState('');
  const [buildingRoomWaterSize, setBuildingRoomWaterSize] = useState('');
  const [buildingOutlet, setBuildingOutlet] = useState(0);
  const [buildingPRV, setBuildingPRV] = useState('');
  const [buildingPlanSet, setBuildingPlanSet] = useState('');
  const [errors, setErrors] = useState<BuildingErrorProp>({});
  const isUpdated =
    building.name === buildingName &&
    building.address === buildingAddress &&
    building.floors === parseInt(floor, 10) &&
    status === Number(building?.status).toString();

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleClickInfo = () => {
    setInfoOpen(!infoOpen);
  };

  const handleClickContact = () => {
    setContactOpen(!contactOpen);
  };

  const handleClickEquipement = () => {
    setEquipementOpen(!equipementOpen);
  };

  useLayoutEffect(() => {
    if (building) {
      setBuildingId(building.id);
      setBuildingName(building.name);
      setFloor(building.floors.toString());
      setBuildingAddress(building.address);
      setStatus(Number(building.status).toFixed());
      setBuildingType(building.type);
      setBuildingControlSystem(building?.control_system);
      setBuildingHVACSystem(building?.hvac_system || '');
      setBuildingTelecomRoomLocation(building?.telecom_rooms_location || '');
      setBuildingCentralWifi(building?.central_wifi || 0);
      setBuildingCentralLAN(building?.central_lan || 0);
      setBuildingInternetNumber(building?.central_internet || 0);
      setBuildingMainWaterSize(building?.main_water_diameter || '');
      setBuildingRoomWaterSize(building?.room_water_diameter || '');
      setBuildingOutlet(building['120v_outlet_in_rooms'] || 0);

      setBuildingTotalArea(building?.total_area || '');
      setBuildingLocation(building?.coordinates || '');
      setBuildingOpeningDate(
        building?.opening_date || dayjs().format('YYYY/MM/DD')
      );
      setBuildingConstructionDate(
        building?.construction_date || dayjs().format('YYYY/MM/DD')
      );
      setBuildingOwner(building?.owner || '');
      setBuildingGeneralContractor(building?.general_contractor || '');
      setBuildingElectrician(building?.electrician || '');
      setBuildingPlumbers(building?.plumber || '');
      setBuildingEngineers(building?.engineer || '');
      setBuildingArchitects(building?.architect || '');
      setBuildingTechnology(building?.technology || '');
      setBuildingHVAC(building?.hvac || '');
      setBuildingProjectManager(building?.project_manager || '');
      setBuildingOperationsManager(building?.operations_manager || '');
      setBuildingHelp(building['911_number'] || '');
      setBuildingInsurer(building?.insurer || '');
      setBuildingReferal(building?.referral || '');
      setBuildingSales(building?.sales_representative || '');
      setBuildingBasement(building?.basements || '');
      setBuildingTotalRooms(building?.total_rooms || '');
      setBuildingPool(building?.pools || '0');
      setBuildingElectricityRoom(building?.electricity_rooms || '0');
      setBuildingMechanicalRoom(building?.mechanical_rooms || '0');
      setBuildingInternetLocation(building?.central_internet_location || '');
      setBuildingPRV(building?.prv || '');
      setBuildingPlanSet(building?.plan_set_location || '');
      setBuildingControlSystem(building?.control_system);
      setBuildingTelecomRoomNumber(building?.telecom_rooms || '');
    }
    return () => {
      setErrors({});
      setBuildingName('');
      setBuildingAddress('');
      setStatus(BuildingStatuses[0].value);
      setBuildingType(BuildingTypes[0].value);
      setBuildingControlSystem(BuildingControlSystems[0].value);
      setBuildingHVACSystem(BuildingHVACSystems[0].value);
      setBuildingTelecomRoomLocation(BuildingTelecom[0].value);
      setBuildingCentralWifi(BuildingConfirmation[0].value);
      setBuildingCentralLAN(BuildingConfirmation[0].value);
      setBuildingMainWaterSize(MainWaterDiameters[0].value);
      setBuildingRoomWaterSize(RoomWaterDiameters[0].value);
      setBuildingOutlet(BuildingConfirmation[0].value);
    };
  }, [building]);

  const handleChangeName = (text: string) => {
    setBuildingName(text);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleChangeAddress = (text: string) => {
    setBuildingAddress(text);
  };

  const handleChangeTotalArea = (text: string) => {
    setBuildingTotalArea(text);
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setBuildingType(event.target.value);
  };

  const handleChangeControlSystem = (event: SelectChangeEvent) => {
    setBuildingControlSystem(event.target.value);
  };

  const handleChangeLocation = (text: string) => {
    setBuildingLocation(text);
  };

  const handleChangeOpeningDate = (text: string) => {
    setBuildingOpeningDate(text);
  };

  const handleChangeConstructionDate = (text: string) => {
    setBuildingConstructionDate(text);
  };

  const handleChangeBuildingOwner = (text: string) => {
    setBuildingOwner(text);
  };

  const handleChangeBuildingGeneralContractor = (text: string) => {
    setBuildingGeneralContractor(text);
  };

  const handleChangeBuildingElectrician = (text: string) => {
    setBuildingElectrician(text);
  };

  const handleChangeBuildingPlumbers = (text: string) => {
    setBuildingPlumbers(text);
  };

  const handleChangeBuildingEngineers = (text: string) => {
    setBuildingEngineers(text);
  };

  const handleChangeBuildingArchitects = (text: string) => {
    setBuildingArchitects(text);
  };

  const handleChangeBuildingTechnology = (text: string) => {
    setBuildingTechnology(text);
  };

  const handleChangeBuildingHVAC = (text: string) => {
    setBuildingHVAC(text);
  };

  const handleChangeBuildingHVACSystem = (event: SelectChangeEvent) => {
    setBuildingHVACSystem(event.target.value);
  };

  const handleChangeBuildingProjectManager = (text: string) => {
    setBuildingProjectManager(text);
  };

  const handleChangeBuildingOperationsManager = (text: string) => {
    setBuildingOperationsManager(text);
  };

  const handleChangeBuildingHelp = (text: string) => {
    setBuildingHelp(text);
  };

  const handleChangeBuildingInsurer = (text: string) => {
    setBuildingInsurer(text);
  };

  const handleChangeBuildingReferal = (text: string) => {
    setBuildingReferal(text);
  };

  const handleChangeBuildingSales = (text: string) => {
    setBuildingSales(text);
  };

  const handleChangeFloor = (text: string) => {
    setFloor(text);
  };

  const handleChangeBuildingBasement = (text: string) => {
    setBuildingBasement(text);
  };

  const handleChangeBuildingTotalRooms = (text: string) => {
    setBuildingTotalRooms(text);
  };

  const handleChangeBuildingPool = (text: string) => {
    setBuildingPool(text);
  };

  const handleChangeBuildingElectricityRoom = (text: string) => {
    setBuildingElectricityRoom(text);
  };

  const handleChangeBuildingMechanicalRoom = (text: string) => {
    setBuildingMechanicalRoom(text);
  };

  const handleChangeBuildingTelecomRoomNumber = (text: string) => {
    setBuildingTelecomRoomNumber(text);
  };

  const handleChangeBuildingTelecomRoomLocation = (
    event: SelectChangeEvent
  ) => {
    setBuildingTelecomRoomLocation(event.target.value);
  };

  const handleChangeBuildingCentralWifi = (event: SelectChangeEvent) => {
    setBuildingCentralWifi(Number(event.target.value));
  };

  const handleChangeBuildingCentralLAN = (event: SelectChangeEvent) => {
    setBuildingCentralLAN(Number(event.target.value));
  };

  const handleChangeBuildingInternetNumber = (event: SelectChangeEvent) => {
    setBuildingInternetNumber(Number(event.target.value));
  };

  const handleChangeBuildingInternetLocation = (text: string) => {
    setBuildingInternetLocation(text);
  };

  const handleChangeBuildingMainWaterSize = (event: SelectChangeEvent) => {
    setBuildingMainWaterSize(event.target.value);
  };

  const handleChangeBuildingRoomWaterSize = (event: SelectChangeEvent) => {
    setBuildingRoomWaterSize(event.target.value);
  };

  const handleChangeBuildingOutlet = (event: SelectChangeEvent) => {
    setBuildingOutlet(Number(event.target.value));
  };

  const handleChangeBuildingPRV = (text: string) => {
    setBuildingPRV(text);
  };

  const handleChangeBuildingPlanSet = (text: string) => {
    setBuildingPlanSet(text);
  };

  const validateData = () => {
    const errors: BuildingErrorProp = {};
    if (validator.isEmpty(buildingName)) {
      errors.buildingName =
        activeLocale === 'fr'
          ? 'Un nom de bâtiment valide est requis'
          : 'A valid building name is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      id: building.id,
      name: buildingName,
      address: buildingAddress,
      status,
      total_area: buildingTotalArea,
      type: buildingType,
      control_system: buildingControlSystem,
      coordinates: buildingLocation,
      opening_date: dayjs(buildingOpeningDate).format('YYYY-MM-DD'),
      construction_date: dayjs(buildingConstructionDate).format('YYYY-MM-DD'),
      owner: buildingOwner,
      general_contractor: buildingGeneralContractor,
      electrician: buildingElectrician,
      plumber: buildingPlumbers,
      engineer: buildingEngineers,
      architect: buildingArchitects,
      technology: buildingTechnology,
      hvac: buildingHVAC,
      hvac_system: buildingHVACSystem,
      project_manager: buildingProjectManager,
      operations_manager: buildingOperationsManager,
      '911_number': buildingHelp,
      insurer: buildingInsurer,
      referral: buildingReferal,
      sales_representative: buildingSales,
      floors: floor,
      basements: buildingBasement,
      total_rooms: buildingTotalRooms,
      pools: buildingPool,
      electricity_rooms: buildingElectricityRoom,
      mechanical_rooms: buildingMechanicalRoom,
      telecom_rooms: buildingTelecomRoomNumber,
      telecom_rooms_location: buildingTelecomRoomLocation,
      central_wifi: buildingCentralWifi,
      central_lan: buildingCentralLAN,
      central_internet: buildingInternetNumber,
      central_internet_location: buildingInternetLocation,
      main_water_diameter: buildingMainWaterSize,
      room_water_diameter: buildingRoomWaterSize,
      '120v_outlet_in_rooms': buildingOutlet,
      prv: buildingPRV,
      plan_set_location: buildingPlanSet,
      ...(isAdmin && { company_id: company }),
    };
    dispatch(updateBuilding({ data, buildingId, navigate }));
  };

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          <TitleDivContainer>
            <AvatarContainer>
              <BuildingOffice2
                height={'72px'}
                width={'72px'}
                color={'#E79D0E'}
              />
            </AvatarContainer>
            <TextContainer>
              <StyledBoldSpan>{buildingName}</StyledBoldSpan>
              <StyledSpan>{buildingAddress}</StyledSpan>
            </TextContainer>
          </TitleDivContainer>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            buttonLabel={t('button-update')}
            color={'primary'}
            OnClick={handleSendData}
            // disabled={isUpdated}
            style={{
              borderRadius: 6,
            }}
          />
        </Grid>
      </GridHeaderContainer>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClickInfo}>
          <SectionContainer>
            <SectionTitle>{t('building-detail-general')}</SectionTitle>
            {infoOpen ? <ExpandLess /> : <ExpandMore />}
          </SectionContainer>
        </ListItemButton>
        <CollapseItem in={infoOpen} timeout="auto" unmountOnExit>
          <GeneralInformationCard
            buildingName={buildingName}
            status={status}
            buildingAddress={buildingAddress}
            buildingTotalArea={buildingTotalArea}
            buildingType={buildingType}
            buildingControlSystem={buildingControlSystem}
            buildingLocation={buildingLocation}
            buildingOpeningDate={buildingOpeningDate}
            buildingConstructionDate={buildingConstructionDate}
            handleChangeName={handleChangeName}
            handleChangeStatus={handleChangeStatus}
            handleChangeAddress={handleChangeAddress}
            handleChangeTotalArea={handleChangeTotalArea}
            handleChangeType={handleChangeType}
            handleChangeControlSystem={handleChangeControlSystem}
            handleChangeLocation={handleChangeLocation}
            handleChangeOpeningDate={handleChangeOpeningDate}
            handleChangeConstructionDate={handleChangeConstructionDate}
            errors={errors}
          />
        </CollapseItem>
        <ListItemButton onClick={handleClickContact}>
          <SectionContainer>
            <SectionTitle>{t('building-detail-contact')}</SectionTitle>
            {contactOpen ? <ExpandLess /> : <ExpandMore />}
          </SectionContainer>
        </ListItemButton>
        <CollapseItem in={contactOpen} timeout="auto" unmountOnExit>
          <ContactStaffCard
            buildingOwner={buildingOwner}
            buildingGeneralContractor={buildingGeneralContractor}
            buildingElectrician={buildingElectrician}
            buildingPlumbers={buildingPlumbers}
            buildingEngineers={buildingEngineers}
            buildingArchitects={buildingArchitects}
            buildingTechnology={buildingTechnology}
            buildingHVAC={buildingHVAC}
            buildingHVACSystem={buildingHVACSystem}
            buildingProjectManager={buildingProjectManager}
            buildingOperationsManager={buildingOperationsManager}
            buildingHelp={buildingHelp}
            buildingInsurer={buildingInsurer}
            buildingReferal={buildingReferal}
            buildingSales={buildingSales}
            handleChangeBuildingOwner={handleChangeBuildingOwner}
            handleChangeBuildingGeneralContractor={
              handleChangeBuildingGeneralContractor
            }
            handleChangeBuildingElectrician={handleChangeBuildingElectrician}
            handleChangeBuildingPlumbers={handleChangeBuildingPlumbers}
            handleChangeBuildingEngineers={handleChangeBuildingEngineers}
            handleChangeBuildingArchitects={handleChangeBuildingArchitects}
            handleChangeBuildingTechnology={handleChangeBuildingTechnology}
            handleChangeBuildingHVAC={handleChangeBuildingHVAC}
            handleChangeBuildingHVACSystem={handleChangeBuildingHVACSystem}
            handleChangeBuildingProjectManager={
              handleChangeBuildingProjectManager
            }
            handleChangeBuildingOperationsManager={
              handleChangeBuildingOperationsManager
            }
            handleChangeBuildingHelp={handleChangeBuildingHelp}
            handleChangeBuildingInsurer={handleChangeBuildingInsurer}
            handleChangeBuildingReferal={handleChangeBuildingReferal}
            handleChangeBuildingSales={handleChangeBuildingSales}
            errors={errors}
          />
        </CollapseItem>
        <ListItemButton onClick={handleClickEquipement}>
          <SectionContainer>
            <SectionTitle>{t('building-detail-equipment')}</SectionTitle>
            {equipementOpen ? <ExpandLess /> : <ExpandMore />}
          </SectionContainer>
        </ListItemButton>
        <CollapseItem in={equipementOpen} timeout="auto" unmountOnExit>
          <EquipementCard
            floor={floor}
            buildingBasement={buildingBasement}
            buildingTotalRooms={buildingTotalRooms}
            buildingPool={buildingPool}
            buildingElectricityRoom={buildingElectricityRoom}
            buildingMechanicalRoom={buildingMechanicalRoom}
            buildingTelecomRoomNumber={buildingTelecomRoomNumber}
            buildingTelecomRoomLocation={buildingTelecomRoomLocation}
            buildingCentralWifi={buildingCentralWifi}
            buildingCentralLAN={buildingCentralLAN}
            buildingInternetNumber={buildingInternetNumber}
            buildingInternetLocation={buildingInternetLocation}
            buildingMainWaterSize={buildingMainWaterSize}
            buildingRoomWaterSize={buildingRoomWaterSize}
            buildingOutlet={buildingOutlet}
            buildingPRV={buildingPRV}
            buildingPlanSet={buildingPlanSet}
            handleChangeFloor={handleChangeFloor}
            handleChangeBuildingBasement={handleChangeBuildingBasement}
            handleChangeBuildingTotalRooms={handleChangeBuildingTotalRooms}
            handleChangeBuildingPool={handleChangeBuildingPool}
            handleChangeBuildingElectricityRoom={
              handleChangeBuildingElectricityRoom
            }
            handleChangeBuildingMechanicalRoom={
              handleChangeBuildingMechanicalRoom
            }
            handleChangeBuildingTelecomRoomNumber={
              handleChangeBuildingTelecomRoomNumber
            }
            handleChangeBuildingTelecomRoomLocation={
              handleChangeBuildingTelecomRoomLocation
            }
            handleChangeBuildingCentralWifi={handleChangeBuildingCentralWifi}
            handleChangeBuildingCentralLAN={handleChangeBuildingCentralLAN}
            handleChangeBuildingInternetNumber={
              handleChangeBuildingInternetNumber
            }
            handleChangeBuildingInternetLocation={
              handleChangeBuildingInternetLocation
            }
            handleChangeBuildingMainWaterSize={
              handleChangeBuildingMainWaterSize
            }
            handleChangeBuildingRoomWaterSize={
              handleChangeBuildingRoomWaterSize
            }
            handleChangeBuildingOutlet={handleChangeBuildingOutlet}
            handleChangeBuildingPRV={handleChangeBuildingPRV}
            handleChangeBuildingPlanSet={handleChangeBuildingPlanSet}
            errors={errors}
          />
        </CollapseItem>
      </List>
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: #fff4eb;
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

const SectionContainer = styled('div')(() => ({
  display: 'flex',
  width: '-webkit-fill-available',
  justifyContent: 'space-between',
  alignSelf: 'center',
  borderBottom: '1px solid #0F172A',
}));

const SectionTitle = styled('span')(() => ({
  color: ColorType.darkGrey,
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '22px',
  fontFamily: 'Rubik',
}));

const CollapseItem = styled(Collapse)(() => ({
  paddingInline: 16,
}));

export default BuildingDetails;
