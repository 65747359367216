import TableHead from '@mui/material/TableHead';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { HeadCell, Order } from '../../const/types';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

interface TableSelectHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  numSelected: number;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TableSelectHead = (props: TableSelectHeadProps) => {
  const {
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onSelectAllClick,
  } = props;
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: '20px' }}
          >
            {headCell.hasFilter ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {activeLocale === 'en' ? headCell.label : headCell.labelFr}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>
                {activeLocale === 'en' ? headCell.label : headCell.labelFr}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableSelectHead;
