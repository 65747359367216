import Link from '@mui/material/Link';

type SimpleLinkProps = {
  text: string;
  link?: string;
  color?: string;
  OnClick?: () => void;
};
const SimpleLink = ({ text, link, color, OnClick }: SimpleLinkProps) => {
  return (
    <Link
      href={link ? link : ''}
      onClick={OnClick}
      variant="subtitle1"
      color={color ?? 'typo.900'}
    >
      {text}
    </Link>
  );
};

export default SimpleLink;
