import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/system';
import { ActionButton } from '../Button';
import { Plus } from '../svg';
import ColorType from '../Theme/ColorType';
import { useTranslation } from 'react-i18next';

interface EnhancedTableToolbarProps {
  title: string;
  description: string;
  isWithButton?: boolean;
  handleButtonClick?: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { t } = useTranslation();
  const { title, description, handleButtonClick } = props;
  const handleClick = () => {
    if (handleButtonClick) return handleButtonClick();
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Container>
        <SpanContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </SpanContainer>
        {props?.isWithButton && (
          <ActionButton
            buttonLabel={t('button-add-device')}
            color={'primary'}
            iconLeft={<PlusIcon />}
            OnClick={handleClick}
            style={{
              borderRadius: 6,
            }}
          />
        )}
      </Container>
    </Toolbar>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
`;

const SpanContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4;
  color: #2e3646;
`;

const Title = styled('span')`
  font-weight: 500;
  font-size: '16px';
  line-height: '24px';
  font-family: Inter;
`;
const Description = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5f6d7e;
  font-family: Inter;
`;

const PlusIcon = styled(Plus)(() => ({
  width: 20,
  height: 20,
  marginRight: '6px',
  stroke: ColorType.background,
}));

export default EnhancedTableToolbar;
