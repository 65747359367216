import React from 'react';
import Pagination from '@mui/material/Pagination';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import IconButton from '../Button/IconButton';

type PaginationTableProps = {
  page: number;
  totalPages: number;
  setPage: (value: React.SetStateAction<number>) => void;
};

const PaginationTable = ({
  page,
  totalPages,
  setPage,
}: PaginationTableProps) => {
  const handlePrevClick = () => {
    setPage((prev) => prev - 1);
  };

  const handleNextClick = () => {
    setPage((prev) => prev + 1);
  };

  const handlePageChange = (e: any, page: number) => {
    setPage(page - 1);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 25px 20px 25px',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1, justifyContent: 'flex-start', display: 'flex' }}>
        {page > 0 && (
          <IconButton handleClick={handlePrevClick}>
            <>
              <ArrowBack fontSize="small" />
              <span>Prev</span>
            </>
          </IconButton>
        )}
      </div>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
          hideNextButton
          hidePrevButton
        />
      </div>
      <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
        {page < totalPages - 1 && (
          <IconButton handleClick={handleNextClick}>
            <>
              <span>Next</span>
              <ArrowForward fontSize="small" />
            </>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default PaginationTable;
