import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import { Input, SelectInput } from '../Input';
import { BuildingErrorProp, BuildingHVACSystems } from '../../const/types';
import { useTranslation } from 'react-i18next';

type Props = {
  buildingOwner: string;
  buildingGeneralContractor: string;
  buildingElectrician: string;
  buildingPlumbers: string;
  buildingEngineers: string;
  buildingArchitects: string;
  buildingTechnology: string;
  buildingHVAC: string;
  buildingHVACSystem: string;
  buildingProjectManager: string;
  buildingOperationsManager: string;
  buildingHelp: string;
  buildingInsurer: string;
  buildingReferal: string;
  buildingSales: string;
  handleChangeBuildingOwner: (text: string) => void;
  handleChangeBuildingGeneralContractor: (text: string) => void;
  handleChangeBuildingElectrician: (text: string) => void;
  handleChangeBuildingPlumbers: (text: string) => void;
  handleChangeBuildingEngineers: (text: string) => void;
  handleChangeBuildingArchitects: (text: string) => void;
  handleChangeBuildingTechnology: (text: string) => void;
  handleChangeBuildingHVAC: (text: string) => void;
  handleChangeBuildingHVACSystem: (event: SelectChangeEvent) => void;
  handleChangeBuildingProjectManager: (text: string) => void;
  handleChangeBuildingOperationsManager: (text: string) => void;
  handleChangeBuildingHelp: (text: string) => void;
  handleChangeBuildingInsurer: (text: string) => void;
  handleChangeBuildingReferal: (text: string) => void;
  handleChangeBuildingSales: (text: string) => void;
  errors: BuildingErrorProp;
};

const ContactStaffCard = ({
  buildingOwner,
  buildingGeneralContractor,
  buildingElectrician,
  buildingPlumbers,
  buildingEngineers,
  buildingArchitects,
  buildingTechnology,
  buildingHVAC,
  buildingHVACSystem,
  buildingProjectManager,
  buildingOperationsManager,
  buildingHelp,
  buildingInsurer,
  buildingReferal,
  buildingSales,
  handleChangeBuildingOwner,
  handleChangeBuildingGeneralContractor,
  handleChangeBuildingElectrician,
  handleChangeBuildingPlumbers,
  handleChangeBuildingEngineers,
  handleChangeBuildingArchitects,
  handleChangeBuildingTechnology,
  handleChangeBuildingHVAC,
  handleChangeBuildingHVACSystem,
  handleChangeBuildingProjectManager,
  handleChangeBuildingOperationsManager,
  handleChangeBuildingHelp,
  handleChangeBuildingInsurer,
  handleChangeBuildingReferal,
  handleChangeBuildingSales,
  errors,
}: Props) => {
  const { t } = useTranslation();
  return (
    <GridContentContainer container>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-owner-label')}
          placeholder={t('building-detail-owner-placeholder')}
          value={buildingOwner}
          hasBorder
          OnChange={handleChangeBuildingOwner}
          error={!!errors?.buildingOwner}
          errorMessage={errors?.buildingOwner}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-constructor-label')}
          placeholder={t('building-detail-constructor-placeholder')}
          value={buildingGeneralContractor}
          hasBorder
          OnChange={handleChangeBuildingGeneralContractor}
          error={!!errors?.buildingGeneralContractor}
          errorMessage={errors?.buildingGeneralContractor}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-electrician-label')}
          placeholder={t('building-detail-electrician-placeholder')}
          value={buildingElectrician}
          hasBorder
          OnChange={handleChangeBuildingElectrician}
          error={!!errors?.buildingElectrician}
          errorMessage={errors?.buildingElectrician}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-plumbers-label')}
          placeholder={t('building-detail-plumbers-placeholder')}
          value={buildingPlumbers}
          hasBorder
          OnChange={handleChangeBuildingPlumbers}
          error={!!errors?.buildingPlumbers}
          errorMessage={errors?.buildingPlumbers}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-engineers-label')}
          placeholder={t('building-detail-engineers-placeholder')}
          value={buildingEngineers}
          hasBorder
          OnChange={handleChangeBuildingEngineers}
          error={!!errors?.buildingEngineers}
          errorMessage={errors?.buildingEngineers}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-architects-label')}
          placeholder={t('building-detail-architects-placeholder')}
          value={buildingArchitects}
          hasBorder
          OnChange={handleChangeBuildingArchitects}
          error={!!errors?.buildingArchitects}
          errorMessage={errors?.buildingArchitects}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-technology-label')}
          placeholder={t('building-detail-technology-placeholder')}
          value={buildingTechnology}
          hasBorder
          OnChange={handleChangeBuildingTechnology}
          error={!!errors?.buildingTechnology}
          errorMessage={errors?.buildingTechnology}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-hvac-label')}
          placeholder={t('building-detail-hvac-placeholder')}
          value={buildingHVAC}
          hasBorder
          OnChange={handleChangeBuildingHVAC}
          error={!!errors?.buildingHVAC}
          errorMessage={errors?.buildingHVAC}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-hvacsys-label')}
          placeholder={t('building-detail-hvacsys-placeholder')}
          values={BuildingHVACSystems}
          value={buildingHVACSystem}
          OnChange={handleChangeBuildingHVACSystem}
          error={!!errors?.buildingHVACSystem}
          errorMessage={errors?.buildingHVACSystem}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-project-label')}
          placeholder={t('building-detail-project-placeholder')}
          value={buildingProjectManager}
          hasBorder
          OnChange={handleChangeBuildingProjectManager}
          error={!!errors?.buildingProjectManager}
          errorMessage={errors?.buildingProjectManager}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-operations-label')}
          placeholder={t('building-detail-operations-placeholder')}
          value={buildingOperationsManager}
          hasBorder
          OnChange={handleChangeBuildingOperationsManager}
          error={!!errors?.buildingOperationsManager}
          errorMessage={errors?.buildingOperationsManager}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-phone-label')}
          placeholder={t('building-detail-phone-placeholder')}
          value={buildingHelp}
          hasBorder
          OnChange={handleChangeBuildingHelp}
          error={!!errors?.buildingHelp}
          errorMessage={errors?.buildingHelp}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-insurer-label')}
          placeholder={t('building-detail-insurer-placeholder')}
          value={buildingInsurer}
          hasBorder
          OnChange={handleChangeBuildingInsurer}
          error={!!errors?.buildingInsurer}
          errorMessage={errors?.buildingInsurer}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-referal-label')}
          placeholder={t('building-detail-referal-placeholder')}
          value={buildingReferal}
          hasBorder
          OnChange={handleChangeBuildingReferal}
          error={!!errors?.buildingReferal}
          errorMessage={errors?.buildingReferal}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-sales-label')}
          placeholder={t('building-detail-sales-placeholder')}
          value={buildingSales}
          hasBorder
          OnChange={handleChangeBuildingSales}
          error={!!errors?.buildingSales}
          errorMessage={errors?.buildingSales}
        />
      </Grid>
    </GridContentContainer>
  );
};

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default ContactStaffCard;
