import { theme } from './components/Theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './routes';
import Snack from './components/Snapbar/Snack';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './i18n/config.ts';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { setAxiosHeaders } from './utils/helper';

axios.defaults.headers.common['Authorization'] =
  localStorage.getItem('IdToken');

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    // Read selected language from storage
    const storedLanguage = localStorage.getItem('lang');
    if (storedLanguage) {
      // Configure i18n with the stored language
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    // Set the initial headers
    setAxiosHeaders();

    // Update headers when language changes
    const handleLanguageChange = () => {
      setAxiosHeaders();
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Routes />
        <Snack />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
