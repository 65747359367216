import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { ControllerDTO, ControllerItemDTO } from '../types/controller.dto';

export const gatewaysSelector = ({ Device: { gateways } }: AppState) => {
  return gateways;
};

export const controllersSelector = ({ Device: { controllers } }: AppState) => {
  return controllers;
};

export const messagesSelector = ({ Device: { messages } }: AppState) => {
  return messages;
};

export const controllerByIdSelector = (controllerId: number) =>
  createSelector([controllersSelector], (controllers: ControllerDTO[]) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    controllers?.items?.find(
      (controller: ControllerItemDTO) => controller.id === controllerId
    )
  );

export const sensorsSelector = ({ Device: { sensors } }: AppState) => {
  return sensors;
};

export const controllersSelectListSelector = createSelector(
  [controllersSelector],
  (controllers: ControllerDTO) => {
    const idList = [
      ...controllers?.items?.map((item) => ({
        value: item.id,
        label: item.asset_number,
        etage: item?.device_location?.floor,
        area: item?.device_location?.floor_area,
        subArea: item?.device_location?.floor_sub_area,
        devEui: item?.dev_application_eui,
      })),
    ];
    return idList;
  }
);

export const controllersListSelector = createSelector(
  [controllersSelector],
  (controllers: ControllerDTO) => {
    const idList = [
      { value: 0, label: 'Select a Controller', placeholder: true },
      ...controllers.items.map((item: any) => ({
        value: item.id,
        label: item.asset_number,
      })),
    ];
    return idList;
  }
);

export const controllerScheduleListSelector = createSelector(
  [controllersSelector],
  (controllers: ControllerDTO) => {
    const idList = [
      { value: 0, label: 'All' },
      ...controllers.items.map((item: any) => ({
        value: item.id,
        label: item.asset_number,
      })),
    ];
    return idList;
  }
);
