/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router';
import ColorType from '../Theme/ColorType';
import { styled } from '@mui/material/styles';
import { SwitchStatus } from '../Input';
import { Status } from '../Badge';
import { Circle, Clog, Sensor } from '../svg';
import { displayLastSeen, displayStatusDevices } from '../../utils/helper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Text } from '../Text';
import { ControllerItemDTO } from '../../redux/types/controller.dto';
import { useAppDispatch } from '../../utils/redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

type Props = {
  controllerRow: ControllerItemDTO;
  controllerIndex: number;
  handleChangeStatus: (controller: any) => void;
};

const ControllerItem = ({
  controllerRow,
  controllerIndex,
  handleChangeStatus,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const channelA = controllerRow.channels?.find((c) => c.channel_code === 'A');
  const channelB = controllerRow.channels?.find((c) => c.channel_code === 'B');

  const statusA =
    controllerRow.channels?.find((c) => c.channel_code === 'A')?.status ===
    'open';
  const statusB =
    controllerRow.channels?.find((c) => c.channel_code === 'B')?.status ===
    'open';
  const labelId = `enhanced-table-checkbox-${controllerIndex}`;
  // const circleBg = displayStatusDevices(
  //   controllerRow.status === 'open'
  //     ? 'active'
  //     : controllerRow.status === 'closed'
  //     ? 'inactive'
  //     : 'offline'
  // ).circleBg;
  // const bgColor = displayStatusDevices(
  //   controllerRow.status === 'open'
  //     ? 'active'
  //     : controllerRow.status === 'closed'
  //     ? 'inactive'
  //     : 'offline'
  // ).bgColor;

  const circleBgA = displayStatusDevices(
    channelA?.status === 'open'
      ? 'active'
      : channelA?.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).circleBg;
  const bgColorA = displayStatusDevices(
    channelA?.status === 'open'
      ? 'active'
      : channelA?.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).bgColor;

  const circleBgB = displayStatusDevices(
    channelB?.status === 'open'
      ? 'active'
      : channelB?.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).circleBg;
  const bgColorB = displayStatusDevices(
    channelB?.status === 'open'
      ? 'active'
      : channelB?.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).bgColor;
  const lastSeenControllerA = displayLastSeen(
    /* @ts-ignore */
    controllerRow?.last_seen,
    /* @ts-ignore */
    controllerRow.channels.find((c) => c.channel_code === 'A')?.status ===
      'offline'
  );
  const lastSeenControllerB = displayLastSeen(
    /* @ts-ignore */
    controllerRow?.last_seen,
    /* @ts-ignore */
    controllerRow.channels.find((c) => c.channel_code === 'B')?.status ===
      'offline'
  );
  const devices = controllerRow?.sensors;

  const handleClick = (row: any) => {
    navigate(`controller/${row.id}`, { state: { controller: row } });
  };

  const handleClickSensor = async (row: any) => {
    try {
      dispatch({ type: 'LOADING_UI' });
      const res = await axios.get(`/devices/${row.id}`);
      const sensor = res?.data?.data;
      dispatch({ type: 'CLEAR_ERRORS' });
      navigate(`sensor/${row.id}`, { state: { sensor } });
    } catch (err) {
      /* @ts-ignore */
      const errors = err?.response?.data?.data;
      dispatch({ type: 'SET_ERRORS', payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
  };
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={controllerRow.id}
        sx={{
          borderBottom: '1px solid #D1D9E2',
          cursor: 'pointer',
        }}
      >
        {/* <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          sx={{ paddingLeft: '20px' }}
          onClick={() => handleClick(controllerRow)}
        >
          <Text color={ColorType.grey50}>{controllerRow.id}</Text>
        </TableCell> */}
        <TableCell align="left">
          <TitleDivContainer>
            <AvatarContainer>
              <Clog
                height={'14px'}
                width={'14px'}
                fill={ColorType.lightGrey}
                onClick={() => handleClick(controllerRow)}
              />
            </AvatarContainer>
            <TextContainer>
              <Text>{controllerRow.eui}</Text>
            </TextContainer>
            {controllerRow.sensors.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TitleDivContainer>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(controllerRow)}>
          <Text>{controllerRow.model}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(controllerRow)}>
          <Text>{controllerRow.building?.name}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(controllerRow)}>
          <Text>{controllerRow.device_location?.floor}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(controllerRow)}>
          <Text>{controllerRow.device_location?.floor_area}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(controllerRow)}>
          <Text>{controllerRow.device_location?.floor_sub_area}</Text>
        </TableCell>
        <TableCell align="right" onClick={() => handleClick(controllerRow)}>
          {channelA && (
            <>
              <Status
                text={channelA.status_label}
                isShowCircle
                circleColor={circleBgA}
                bgColor={bgColorA}
              />
              <LastSeenRow>
                {/* @ts-ignore */}
                {channelA.status === 'closed' && (
                  <Circle fill={ColorType.primary} />
                )}
                <LastSeenText>{lastSeenControllerA}</LastSeenText>
              </LastSeenRow>
            </>
          )}
        </TableCell>
        <TableCell align="right">
          {channelA && (
            <div
              style={{
                gap: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              {/* {controllerRow?.status_label && (
              <span style={{ fontSize: 12 }}>{controllerRow.status_label}</span>
            )} */}
              <SwitchStatus
                key={controllerRow.id}
                checked={statusA}
                handleCheckedChange={() => {
                  const newRow = { ...controllerRow };
                  newRow.channel = 'A';
                  newRow.channel_status = channelA.status;
                  handleChangeStatus(newRow);
                }}
              />
              {/* {!controllerRow.status && (
              <span style={{ fontSize: 12 }}>{t('controller-status-off')}</span>
            )} */}
            </div>
          )}
        </TableCell>
        <TableCell align="right" onClick={() => handleClick(controllerRow)}>
          {channelB && (
            <>
              <Status
                text={channelB.status_label}
                isShowCircle
                circleColor={circleBgB}
                bgColor={bgColorB}
              />
              <LastSeenRow>
                {/* @ts-ignore */}
                {channelB.status === 'closed' && (
                  <Circle fill={ColorType.primary} />
                )}
                <LastSeenText>{lastSeenControllerB}</LastSeenText>
              </LastSeenRow>
            </>
          )}
        </TableCell>
        <TableCell align="right">
          {channelB && (
            <div
              style={{
                gap: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <SwitchStatus
                key={controllerRow.id}
                checked={statusB}
                handleCheckedChange={() => {
                  const newRow = { ...controllerRow };
                  newRow.channel = 'B';
                  newRow.channel_status = channelB.status;

                  handleChangeStatus(newRow);
                }}
              />
            </div>
          )}
        </TableCell>
      </TableRow>
      {open &&
        devices &&
        devices.map((row) => {
          const circleBg = displayStatusDevices(
            row.status === 'open' || row.status === 'online'
              ? 'active'
              : row.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).circleBg;
          const bgColor = displayStatusDevices(
            row.status === 'open' || row.status === 'online'
              ? 'active'
              : row.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).bgColor;
          const lastSeenController = displayLastSeen(
            /* @ts-ignore */
            row?.last_seen,
            /* @ts-ignore */
            row?.status === 'offline'
          );
          return (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              sx={{
                borderBottom: '1px solid #D1D9E2',
                cursor: 'pointer',
              }}
            >
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                sx={{ paddingLeft: '20px' }}
                onClick={() => handleClickSensor(row)}
              />
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <TitleDivContainer>
                  <AvatarContainer>
                    <Sensor
                      height={'14px'}
                      width={'14px'}
                      fill={ColorType.lightGrey}
                    />
                  </AvatarContainer>
                  <TextContainer>
                    <Text color={ColorType.grey500}>{row.eui}</Text>
                  </TextContainer>
                </TitleDivContainer>
              </TableCell>
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <Text color={ColorType.grey50}>{row.model}</Text>
              </TableCell>
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <Text color={ColorType.grey50}>{row.building?.name}</Text>
              </TableCell>
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor}
                </Text>
              </TableCell>
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor_area}
                </Text>
              </TableCell>
              <TableCell align="left" onClick={() => handleClickSensor(row)}>
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor_sub_area}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Status
                  text={row.status_label || ''}
                  isShowCircle
                  circleColor={circleBg}
                  bgColor={bgColor}
                />
                {/*@ts-ignore */}
                {row?.status === 'closed' && (
                  <Circle fill={ColorType.primary} />
                )}
                <LastSeenText>{lastSeenController}</LastSeenText>
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleClickSensor(row)}
              ></TableCell>
              <TableCell
                align="right"
                onClick={() => handleClickSensor(row)}
              ></TableCell>
              <TableCell
                align="right"
                onClick={() => handleClickSensor(row)}
              ></TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: ${ColorType.darkGrey};
  border-radius: 50px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const LastSeenText = styled('span')`
  font-size: 10px;
  color: ${ColorType.darkGrey};
  font-family: Inter;
`;

const LastSeenRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  gap: 2px;
`;

export default ControllerItem;
