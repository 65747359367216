import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { snackBarSelector } from '../../redux/selectors/ui';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type AlertColor = 'success' | 'info' | 'warning' | 'error';

const Snack = () => {
  const dispatch = useAppDispatch();
  const snackBar = useAppSelector(snackBarSelector);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('error');

  useEffect(() => {
    if (!!snackBar) {
      setOpen(snackBar.isOpen);
      setText(snackBar.text);
      setSeverity(snackBar.severity);
    }
  }, [snackBar]);

  const handleClose = () => {
    dispatch({ type: 'CLEAR_ERRORS' });
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Snack;
