import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const companiesSelector = ({ Company: { companies } }: AppState) => {
  return companies;
};

// export const companiesListSelector = ({ Company: { companies } }: AppState) => {
//   const idList = [
//     { value: 0, label: 'Select a Company', placeholder: true },
//     ...companies.map((item: any) => ({
//       value: item.id,
//       label: item.name,
//     })),
//   ];
//   return idList;
// };

export const companiesListSelector = createSelector(
  [companiesSelector],
  (companies) => {
    const idList = [
      { value: 0, label: 'Select a Company' },
      ...companies.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    ];
    return idList;
  }
);

export const companiesListFrSelector = createSelector(
  [companiesSelector],
  (companies) => {
    const idList = [
      { value: 0, label: 'Sélectionnez une entreprise' },
      ...companies.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    ];
    return idList;
  }
);

export const companySelector = ({ Company: { company } }: AppState) => {
  return company;
};

export const companyIdSelector = ({ Company: { company } }: AppState) => {
  return company?.id;
};

export const companyStatusSelector = ({ Company: { company } }: AppState) => {
  return company?.enabled;
};

export const companySupportSelector = ({ Company: { company } }: AppState) => {
  return company?.allow_admin_support;
};
