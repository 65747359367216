import React from 'react';
import Pagination from '@mui/material/Pagination';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import IconButton from '../Button/IconButton';

type NewPaginationTableProps = {
  page: number;
  totalPages: number;
  setControls: any;
};

const NewPaginationTable = ({
  page,
  totalPages,
  setControls,
}: NewPaginationTableProps) => {
  const handlePrevClick = () => {
    setControls((controls: any) => ({
      ...controls,
      page: controls.page - 1,
    }));
  };

  const handleNextClick = () => {
    setControls((controls: any) => ({
      ...controls,
      page: controls.page + 1,
    }));
  };

  const handlePageChange = (e: any, page: number) => {
    setControls((controls: any) => ({
      ...controls,
      page: page - 1,
    }));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 25px 20px 25px',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1, justifyContent: 'flex-start', display: 'flex' }}>
        {page > 0 && (
          <IconButton handleClick={handlePrevClick}>
            <>
              <ArrowBack fontSize="small" />
              <span>Prev</span>
            </>
          </IconButton>
        )}
      </div>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
          hideNextButton
          hidePrevButton
        />
      </div>
      <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
        {page < totalPages - 1 && (
          <IconButton handleClick={handleNextClick}>
            <>
              <span>Next</span>
              <ArrowForward fontSize="small" />
            </>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default NewPaginationTable;
