/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';

import Paper from '@mui/material/Paper';

import { Clog } from '../svg';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { Order } from '../../const/types';
import { controllerHeadCells } from '../../const/arrays';
import { getComparator, stableSort } from '../../utils/helper';
import NewPaginationTable from './NewPaginationTable';
import { useNavigate } from 'react-router';
import EmptyComponent from './EmptyComponent';
import { controllersSelector } from '../../redux/selectors/device';
import { styled } from '@mui/material/styles';
import ColorType from '../Theme/ColorType';

import {
  ControllerDTO,
  ControllerItemDTO,
} from '../../redux/types/controller.dto';
import ConfirmPin from '../Modal/ConfirmPin';
import { Table, TableBody, CircularProgress } from '@mui/material';
import { CONTROLLER } from '../../const/values';
import { getDevicesByType } from '../../redux/actions/deviceAction';
import { loadingSelector } from '../../redux/selectors/ui';
import { ControllerItem } from '../Card';
import { isAdminSelector, isManagerSelector } from '../../redux/selectors/user';
import { buildingSelector } from '../../redux/selectors/building';
import { useTranslation } from 'react-i18next';
import useEcho from '../../utils/echo';
import { companyIdSelector } from '../../redux/selectors/company';

const ControllerTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const echo = useEcho();
  const controllers: ControllerDTO = useAppSelector(controllersSelector);
  const loading = useAppSelector(loadingSelector);
  const [controls, setControls] = useState({
    page: 0,
    rowsPerPage: 15,
  });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [controller, setController] = useState<ControllerItemDTO>();
  const [visibleRows, setVisibleRows] = useState<any>([]);
  const building = useAppSelector(buildingSelector);
  const companyId = useAppSelector(companyIdSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const isSuper = isAdmin || isManager;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(
      getDevicesByType({
        componentType: CONTROLLER,
        page: controls.page + 1,
        companyId,
        buildingId: building?.id,
        keyword: '',
      })
    );
    setTotalPages(controllers?.meta?.last_page);
  }, [controls]);

  const updateRows = useCallback(() => {
    const newRows = stableSort(
      controllers.items,
      getComparator(order, orderBy)
    );
    setVisibleRows(newRows);
  }, [controllers.items, order, orderBy]);

  useEffect(() => {
    updateRows();
  }, [controllers, updateRows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ControllerDTO
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeStatus = (controller: any) => {
    const data = {
      asset_number: controller.asset_number,
      serial_number: controller.serial_number,
      eui: controller.eui,
      application_key: controller.application_key,
      model: controller.model,
      building_id: controller.building.id,
      floor: controller.device_location.floor,
      floor_area: controller.device_location.floor_area,
      floor_sub_area: controller.device_location.floor_sub_area,
      status: controller?.status,
      channel: controller.channel,
      channel_status: controller.channel_status,
    };
    setData(data);
    setController(controller);
    handleOpen();
  };

  useEffect(() => {
    // Here we are going to listen for real-time events.
    if (echo && companyId) {
      echo
        // @ts-expect-error private
        .private(`buildup.company.${companyId}`)
        .listen('.device.status.changed', (event: any) => {
          if (event.type === 'controller') {
            dispatch({
              type: 'UPDATE_STATUS_CONTROLLER_LIVE',
              payload: {
                id: event.id,
                status: event.status,
                status_label: event.status_label,
                channels: event.channels,
              },
            });
          }
        });
      return () => {
        //@ts-expect-error leave echo
        echo.leave(`buildup.company.${companyId}`);
      };
    }
  }, [echo]);

  if (controllers?.items?.length === 0)
    return (
      <EmptyComponent
        component="Controller"
        icon={<Clog width={56} height={56} fill={ColorType.black} />}
        handleClick={() => navigate(`add-controller`)}
      />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar
          title={t('devices-Controller')}
          description={t('devices-controller-description')}
          isWithButton
          handleButtonClick={() => navigate(`add-controller`)}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress color="primary" />
            </LoadingContainer>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                headCells={controllerHeadCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows?.map((row: any, index: any) => (
                  <ControllerItem
                    key={index}
                    controllerRow={row}
                    controllerIndex={index}
                    handleChangeStatus={handleChangeStatus}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <NewPaginationTable
          page={controls.page}
          totalPages={totalPages}
          setControls={setControls}
        />
      </Paper>
      <ConfirmPin
        open={open}
        handleClose={handleClose}
        data={data}
        controller={controller}
      />
    </Box>
  );
};

const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export default ControllerTable;
