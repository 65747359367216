import React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { logoutUser } from '../../redux/actions/userAction';
import { useNavigate } from 'react-router';
import { initialsSelector } from '../../redux/selectors/user';
import { useTranslation } from 'react-i18next';

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};

const ProfileMenu = ({ anchorEl, open, handleClose }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initials = useAppSelector(initialsSelector);

  const handleLogout = () => {
    dispatch(logoutUser());
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate('/my-account')}>
        <Avatar
          sx={{
            height: '20px',
            width: '20px',
            marginRight: '16px',
            fontSize: '14px',
          }}
        >
          {initials}
        </Avatar>{' '}
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('menu-logout')}
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
