import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Input } from '../Input';
import Grid from '@mui/material/Grid';
import { FormButton } from '../Button';
import { DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../utils/redux';
import { loadingSelector } from '../../redux/selectors/ui';
import { useTranslation } from 'react-i18next';

type PopupVerifyProps = {
  open: boolean;
  message?: string;
  description?: string;
  label?: string;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  handleClick: () => void;
  handleClose: () => void;
};

const PopupVerify = ({
  open,
  label,
  token,
  setToken,
  handleClick,
  handleClose,
}: PopupVerifyProps) => {
  const { t } = useTranslation();
  const loading = useAppSelector(loadingSelector);
  const l = t('popup-verify-label');

  const handleChangeConfirm = (text: string) => {
    setToken(text);
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogContentContainer>
        <Grid xs={12} sm={12} item>
          <Input
            label={label || l}
            placeholder={t('popup-verify-code')}
            value={token}
            hasBorder
            OnChange={handleChangeConfirm}
          />
        </Grid>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <FormButton
            buttonLabel={t('button-confirm')}
            color={'primary'}
            OnClick={handleClick}
            style={{
              borderRadius: 6,
            }}
          />
        )}
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    borderRadius: 16,
  },
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

export default PopupVerify;
