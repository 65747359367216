import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';
import Footer from '../../routes/Footer';
import { termsSectionsEn, termsSectionsFr } from './PrivacyAndTos';
import i18n from '../../i18n/config';
import { useTranslation } from 'react-i18next';

const Tos = () => {
  const { t } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const termsSections =
    activeLocale === 'en' ? termsSectionsEn : termsSectionsFr;

  return (
    <BodyContainer>
      <Wrapper>
        {termsSections.map((section) => {
          return (
            <StyledLink key={section.id} href={`#section-${section.id}`}>
              {section.title}
            </StyledLink>
          );
        })}
      </Wrapper>
      <TextContainer>
        <SubTitle>{t('last-terms-update')}</SubTitle>
        <OverflowContainer>
          {termsSections.map((section) => (
            <div
              id={`section-${section.id}`}
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Text>
                <b>{section.title}</b>
              </Text>
              <Text dangerouslySetInnerHTML={{ __html: section.text }} />
            </div>
          ))}
          <FooterContainer>
            <Footer />
          </FooterContainer>
        </OverflowContainer>
      </TextContainer>
    </BodyContainer>
  );
};

const BodyContainer = styled('div')`
  display: flex;
  flex-direction: row;
  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

const SubTitle = styled('span')`
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
`;

const TextContainer = styled('div')`
  flex: 3;
  padding: 70px 100px 40px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 535px) {
    padding: 70px 10px 40px 10px;
  }
`;

const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${ColorType.lightGrey};
  padding: 40px 16px;
`;

const OverflowContainer = styled('div')`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const Text = styled('span')`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // line-height: 120%;
`;

const StyledLink = styled('a')`
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: ${ColorType.darkGrey};
`;

const FooterContainer = styled('div')`
  position: relative;
  bottom: 0;
`;

export default Tos;
