import { useState, useEffect } from 'react';
import Path from '../components/Link/Path';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import {
  isAdminSelector,
  nameSelector,
  roleSelector,
} from '../redux/selectors/user';
import { BuildingKpiCard } from '../components/Card';
import Grid from '@mui/material/Grid';
import { ActionButton } from '../components/Button';
import ColorType from '../components/Theme/ColorType';
import {
  Eye,
  ValveOutlined,
  WarningOutlined,
  WaterDrop,
} from '../components/svg';
import {
  getAlertData,
  getBuildingData,
  getEventData,
  getResolvedAlertsData,
} from '../redux/actions/dashboardAction';
import {
  dashboardAlertsSelector,
  dashboardBuildingsSelector,
  dashboardEventsSelector,
  dashboardResolvedAlertsSelector,
} from '../redux/selectors/dashboard';
import { Location, StatusKPI } from '../const/types';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../redux/actions/userAction';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

enum Views {
  alerts = 'Alerts',
  kpis = 'KPIs',
}

enum ViewsFR {
  alerts = 'Alertes',
  kpis = 'KPIs',
}

type BuildingType = {
  building: string;
  gateways_count: number;
  controllers_count: number;
  sensors_count: number;
  status: StatusKPI;
};

type AlertType = {
  type: string;
  status: string;
  created_at: string;
  device_id: number;
  device_type: string;
};

type EventType = {
  type: string;
  user: string;
  created_at: string;
  device_location: Location;
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const buildings = useAppSelector(dashboardBuildingsSelector);
  const alerts = useAppSelector(dashboardAlertsSelector);
  const archivedAlerts = useAppSelector(dashboardResolvedAlertsSelector);
  const events = useAppSelector(dashboardEventsSelector);
  const [view, setView] = useState(Views.alerts);
  const [viewFR, setViewFR] = useState(ViewsFR.alerts);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getBuildingData());
      dispatch(getAlertData());
      dispatch(getResolvedAlertsData());
      dispatch(getEventData());
    }
    dispatch(getUser());
  }, [dispatch, isAdmin]);

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  const handleSwitchView = () => {
    setView((prev) => (prev === Views.alerts ? Views.kpis : Views.alerts));
    setViewFR((prev) =>
      prev === ViewsFR.alerts ? ViewsFR.kpis : ViewsFR.alerts
    );
  };

  const handleClick = async (controllerId: number, deviceType: string) => {
    try {
      dispatch({ type: 'LOADING_UI' });
      const res = await axios.get(`/devices/${controllerId}`);
      const device = res?.data?.data;
      dispatch({ type: 'CLEAR_ERRORS' });
      navigate(
        `devices/${
          deviceType === 'sensor' ? 'sensor' : 'controller'
        }/${controllerId}`,
        {
          state:
            deviceType === 'sensor'
              ? { sensor: device }
              : { controller: device },
        }
      );
    } catch (err) {
      //@ts-expect-error response
      const errors = err?.response?.data?.data;
      dispatch({ type: 'SET_ERRORS', payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
  };
  return (
    <Container>
      <HeaderContainer>
        <StyledDiv>
          <Path />
          <TitleSpan>
            {userName},{' '}
            <DescriptionSpan style={{ fontSize: 24 }}>
              {handleDisplayRole(role)}
            </DescriptionSpan>
          </TitleSpan>
          <DescriptionSpan>{t('home-description')}</DescriptionSpan>
        </StyledDiv>
        {!isAdmin && (
          <ButtonContainer>
            <ActionButton
              buttonLabel={activeLocale === 'en' ? view : viewFR}
              color={'primary'}
              OnClick={handleSwitchView}
              style={{
                borderRadius: 6,
              }}
            />
          </ButtonContainer>
        )}
      </HeaderContainer>
      {view === Views.alerts ? (
        <Grid container spacing={2}>
          {buildings.map((building: BuildingType, index: number) => {
            return (
              <Grid key={index} item xs={12} sm={4}>
                <BuildingKpiCard
                  buildingName={building.building}
                  status={building.status}
                  gatewayKPI={building.gateways_count}
                  controllerKPI={building.controllers_count}
                  sensorKPI={building.sensors_count}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              paddingRight: 16,
              backgroundColor: ColorType.lightGrey,
              border: '1px solid #DDE1E6',
              borderRadius: '16px',
            }}
          >
            <Title>{t('home-events-title')}</Title>
            <EventBlock>
              {Object.entries(events).map(([date, subEvents]) => (
                <EventWrapper key={date}>
                  {(subEvents as EventType[])?.map(
                    (event: EventType, index: number) => {
                      return (
                        <EventContainer key={index}>
                          <div style={{ display: 'flex', gap: '12px' }}>
                            <IconContainer>
                              <WaterDrop height={18} width={18} />
                            </IconContainer>
                            <EventDataContainer>
                              <EventTypeContainer>
                                {event.type}
                              </EventTypeContainer>
                              <LocationTextWrapper>
                                <LocationTextGrey>
                                  {t('home-events-floor')}{' '}
                                  {event.device_location.floor}
                                </LocationTextGrey>
                                <BlackCircle />
                                <LocationTextGrey>
                                  {event.device_location.floor_area}
                                </LocationTextGrey>
                                <BlackCircle />
                                <LocationText>
                                  {event.device_location.floor_sub_area}
                                </LocationText>
                              </LocationTextWrapper>
                            </EventDataContainer>
                          </div>
                          <TimeAgo>{dayjs(event.created_at).fromNow()}</TimeAgo>
                        </EventContainer>
                      );
                    }
                  )}
                </EventWrapper>
              ))}
            </EventBlock>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              paddingTop: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 16,
                backgroundColor: ColorType.lightGrey,
                border: '1px solid #DDE1E6',
                borderRadius: '16px',
              }}
            >
              <Title>{t('home-active-alerts')}</Title>
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '8px',
                  display: 'flex',
                  height: '250px',
                  overflow: 'auto',
                  marginTop: 16,
                }}
              >
                {alerts.map((alert: AlertType, index: number) => {
                  const isAlert = alert.type === 'Leak detected on Sensor';
                  return (
                    <div
                      key={index}
                      style={{ width: '-webkit-fill-available' }}
                    >
                      <StatusRow>
                        <div style={{ display: 'flex', gap: '12px' }}>
                          {isAlert ? (
                            <IconColorContainer>
                              <WarningOutlined height={24} width={24} />
                            </IconColorContainer>
                          ) : (
                            <IconContainer>
                              <ValveOutlined
                                height={18}
                                width={18}
                                fill={ColorType.background}
                              />
                            </IconContainer>
                          )}
                          <StatusTitleWrapper>
                            <StatusTitleContainer>
                              <StatusTitle>{alert.type}</StatusTitle>
                            </StatusTitleContainer>
                            <StyledSpan>
                              {isAlert
                                ? t('home-alert-resolved')
                                : t('home-alert-pending')}
                            </StyledSpan>
                          </StatusTitleWrapper>
                        </div>
                        <ActionButton
                          buttonLabel={t('button-view')}
                          variant="outlined"
                          color={'secondary'}
                          iconLeft={<Eye />}
                          OnClick={() =>
                            handleClick(alert.device_id, alert.device_type)
                          }
                          style={{
                            borderRadius: 4,
                            fontSize: 11,
                            padding: '8px 16px',
                            lineHeight: '11px',
                            gap: '6px',
                          }}
                        />
                      </StatusRow>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 16,
                backgroundColor: ColorType.lightGrey,
                border: '1px solid #DDE1E6',
                borderRadius: '16px',
              }}
            >
              <Title>{t('home-history-title')}</Title>
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '8px',
                  display: 'flex',
                  height: '250px',
                  overflow: 'auto',
                  marginTop: 16,
                }}
              >
                {archivedAlerts.map((alert: AlertType, index: number) => {
                  const isAlert = alert.type === 'Leak detected on Sensor';
                  return (
                    <div
                      key={index}
                      style={{ width: '-webkit-fill-available' }}
                    >
                      <StatusRow>
                        <div style={{ display: 'flex', gap: '12px' }}>
                          {isAlert ? (
                            <IconColorContainer>
                              <WarningOutlined height={24} width={24} />
                            </IconColorContainer>
                          ) : (
                            <IconContainer>
                              <ValveOutlined
                                height={18}
                                width={18}
                                fill={ColorType.background}
                              />
                            </IconContainer>
                          )}
                          <StatusTitleWrapper>
                            <StatusTitleContainer>
                              <StatusTitle>{alert.type}</StatusTitle>
                            </StatusTitleContainer>
                            <StyledSpan>
                              {isAlert
                                ? t('home-alert-resolved')
                                : t('home-alert-pending')}
                            </StyledSpan>
                          </StatusTitleWrapper>
                        </div>
                        <ActionButton
                          buttonLabel={t('button-view')}
                          variant="outlined"
                          color={'secondary'}
                          iconLeft={<Eye />}
                          OnClick={() =>
                            handleClick(alert.device_id, alert.device_type)
                          }
                          style={{
                            borderRadius: 4,
                            fontSize: 11,
                            padding: '8px 16px',
                            lineHeight: '11px',
                            gap: '6px',
                          }}
                        />
                      </StatusRow>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const Title = styled('span')`
  color: ${ColorType.black};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const IconContainer = styled('div')`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ColorType.black};
  padding: 8px;
  border-radius: 50%;
`;

const IconColorContainer = styled(IconContainer)`
  background-color: ${ColorType.softPink};
`;

const EventContainer = styled('div')`
  align-self: stretch;
  padding: 16px 8px;
  border-bottom: 0.5px ${ColorType.softGrey} solid;
  justify-content: space-between;
  align-items: center;
  gap: 29px;
  display: flex;
`;

const EventTypeContainer = styled('span')`
  color: ${ColorType.black};
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
`;

const TimeAgo = styled('span')`
  color: ${ColorType.softGrey};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
`;

const LocationText = styled('span')`
  color: ${ColorType.black};
  font-size: 14px;
  font-weight: 300;
  line-height: 15.6px;
`;

const LocationTextGrey = styled(LocationText)`
  color: ${ColorType.darkGrey};
`;

const BlackCircle = styled('div')`
  width: 1px;
  height: 10px;
  background: #4a4a4a;
`;

const LocationTextWrapper = styled('div')`
  align-items: center;
  gap: 8px;
  display: inline-flex;
`;

const EventDataContainer = styled('div')`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
`;

const EventWrapper = styled('div')`
  align-self: stretch;
  padding-top: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

const EventBlock = styled('div')`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  max-height: 600px;
  overflow: auto;
`;

const StatusRow = styled('div')`
  align-self: stretch;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: 0.5px ${ColorType.softGrey} solid;
  align-items: center;
  gap: 8px;
  display: flex;
`;

const StatusTitleWrapper = styled('div')`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
`;

const StatusTitleContainer = styled('div')`
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
`;

const StatusTitle = styled('span')`
  color: ${ColorType.black};
  font-size: 14px;
  font-weight: bold;
  line-height: 16.8px;
`;

const Separator = styled('div')`
  align-self: stretch;
  border: 0.5px ${ColorType.softGrey} solid;
  margin-top: 10px;
`;

const StyledSpan = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: ${ColorType.darkGrey};
`;

export default Home;
