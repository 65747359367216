import { useState, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useNavigate } from 'react-router';
import { BuildingOffice2 } from '../components/svg';
import { Input } from '../components/Input';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { errorsSelector as errorsArray } from '../redux/selectors/ui';
import validator from 'validator';
import { storeBuilding } from '../redux/actions/buildingAction';
import {
  companyIdSelector,
  companyStatusSelector,
} from '../redux/selectors/company';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';
import { isAdminSelector } from '../redux/selectors/user';

type ErrorProp = {
  buildingName?: string;
  buildingAddress?: string;
  floor?: string;
  status?: string;
};

const Floors = [
  { value: '0', label: 'Choose a number of floors', placeholder: true },
  ...Array.from({ length: 10 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })),
];

const Statuses = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
];

const AddBuilding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const errorsSelector = useAppSelector(errorsArray);
  const companyId = useAppSelector(companyIdSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const companyStatus = useAppSelector(companyStatusSelector);
  const isAdminAndCompanyDisabled = isAdmin && !!companyId && !companyStatus;
  const [buildingName, setBuildingName] = useState('');
  const [buildingAddress, setBuildingAddress] = useState('');
  const [floor, setFloor] = useState('');
  const [status, setStatus] = useState('Active');
  const [errors, setErrors] = useState<ErrorProp>({});

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setBuildingName('');
      setBuildingAddress('');
      setFloor(Floors[0].value);
      setStatus(Statuses[0].value);
    };
  }, [errorsSelector]);

  const handleChangeName = (text: string) => {
    setBuildingName(text);
  };

  const handleChangeAddress = (text: string) => {
    setBuildingAddress(text);
  };

  const handleChangeFloor = (text: string) => {
    setFloor(text);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (validator.isEmpty(buildingName)) {
      errors.buildingName =
        activeLocale === 'fr'
          ? 'Un nom de bâtiment valide est requis'
          : 'A valid building name is required';
    } else if (isAdminAndCompanyDisabled) {
      const snackBar = {
        isOpen: true,
        text: t('users-disabled-message'),
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(
      storeBuilding({
        buildingName,
        buildingAddress,
        buildingFloor: floor,
        companyId,
        navigate,
      })
    );
  };

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          <TitleDivContainer>
            <AvatarContainer>
              <BuildingOffice2
                height={'72px'}
                width={'72px'}
                color={'#E79D0E'}
              />
            </AvatarContainer>
            <TextContainer>
              <StyledBoldSpan>
                {buildingName || t('building-add-title')}
              </StyledBoldSpan>
              <StyledSpan>
                {buildingAddress || t('building-add-address')}
              </StyledSpan>
            </TextContainer>
          </TitleDivContainer>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            buttonLabel={t('button-add')}
            color={'primary'}
            OnClick={handleSendData}
            style={{
              borderRadius: 6,
            }}
          />
        </Grid>
      </GridHeaderContainer>
      <GridContentContainer container>
        <Grid xs={12} sm={5} item>
          <Input
            label={t('building-add-title')}
            placeholder={t('building-add-title')}
            value={buildingName}
            hasBorder
            OnChange={handleChangeName}
            error={!!errors?.buildingName}
            errorMessage={errors?.buildingName}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <Input
            label={t('building-add-floors')}
            placeholder={t('building-add-floors')}
            value={floor}
            hasBorder
            OnChange={handleChangeFloor}
            error={!!errors?.floor}
            errorMessage={errors?.floor}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <Input
            label={t('building-add-address-label')}
            placeholder={t('building-add-address')}
            value={buildingAddress}
            hasBorder
            OnChange={handleChangeAddress}
            error={!!errors?.buildingAddress}
            errorMessage={errors?.buildingAddress}
          />
        </Grid>
      </GridContentContainer>
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: #fff4eb;
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default AddBuilding;
