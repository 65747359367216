import { ADD_SCHEDULE, SET_SCHEDULES, SET_UNAUTHENTICATED } from '../types';

const initialState = {
  schedules: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, action.payload],
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
