import { styled } from '@mui/system';
import Typography from '@mui/material/Typography/Typography';

type UnderlineButtonProps = {
  text: string;
  disabled?: boolean;
  isDarkMode?: boolean;
  OnClick: () => void;
};
const UnderlineButton = ({
  text,
  disabled = false,
  isDarkMode = false,
  OnClick,
}: UnderlineButtonProps) => {
  return (
    <Text
      onClick={() => {
        !disabled && OnClick();
      }}
      disabled={disabled}
      isDarkMode={isDarkMode}
    >
      {text}
    </Text>
  );
};

type TextProps = {
  disabled: boolean;
  isDarkMode: boolean;
};
const Text = styled(Typography)<TextProps>`
  color: ${({ disabled, isDarkMode }) =>
    disabled ? '#B2B2B2' : isDarkMode ? '#fff' : '#000'};
  font-size: 14px;
  font-weight: 400;
  padding: '10px 80px';
  text-decoration-line: underline;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export default UnderlineButton;
