import React, { useState, useLayoutEffect, useEffect } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ColorType from '../components/Theme/ColorType';
import { SelectInput } from '../components/Input';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { isAdminSelector } from '../redux/selectors/user';
import { MenuItems } from '../const/types';
import { iconMappings } from '../const/arrays';
import {
  buildingIdSelector,
  buildingsListFrSelector,
  buildingsListSelector,
} from '../redux/selectors/building';
import { SelectChangeEvent } from '@mui/material';
import { selectBuilding } from '../redux/actions/buildingAction';
import {
  companiesListFrSelector,
  companiesListSelector,
  companyIdSelector,
} from '../redux/selectors/company';
import { selectCompany } from '../redux/actions/companyAction';
import { menuItemsSelector } from '../redux/selectors/menu';
import { RectangleGroup } from '../components/svg/String';
import { MenuDTO } from '../redux/types/menu.dto';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const LeftSideMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [company, setCompany] = useState('');
  const [building, setBuilding] = useState('');

  const menuItems = useAppSelector(menuItemsSelector);
  const companies = useAppSelector(companiesListSelector);
  const companiesFr = useAppSelector(companiesListFrSelector);
  const companyId = useAppSelector(companyIdSelector);
  const buildings = useAppSelector(buildingsListSelector);
  const buildingsFr = useAppSelector(buildingsListFrSelector);
  const buildingId = useAppSelector(buildingIdSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  useLayoutEffect(() => {
    const itemIndex = (menuItems as MenuDTO[]).find(
      (item) => item.link === location.pathname
    )?.index;
    setSelectedIndex(Number(itemIndex));
  }, [location.pathname, menuItems]);

  useEffect(() => {
    if (companyId) setCompany(companyId.toString());
    else {
      setCompany(companies[0].value.toString());
    }
  }, [companies, companyId]);

  useEffect(() => {
    if (buildingId) setBuilding(buildingId.toString());
    else {
      setBuilding(buildings[0].value.toString());
    }
  }, [buildingId, buildings]);

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleNavigation = (item: MenuItems) => {
    setSelectedIndex(item.index);
    if (location.pathname !== item.link) navigate(item.link);
  };

  const handleChangeCompany = (event: SelectChangeEvent) => {
    dispatch(selectCompany({ companyId: parseInt(event.target.value, 10) }));
  };

  const handleChangeBuilding = (
    event: SelectChangeEvent,
    companyId: string
  ) => {
    dispatch(
      selectBuilding({
        buildingId: parseInt(event.target.value, 10),
        companyId,
        isAdmin,
      })
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{ alignSelf: 'center', marginTop: 20 }}
          onClick={handleDrawer}
        >
          {open ? (
            <img src="/logo.png" alt="logo" height="50px" />
          ) : (
            <img src="/logo192.png" alt="logo" height="34px" />
          )}
        </DrawerHeader>
        <ListWithMargin>
          {(menuItems as MenuDTO[])
            .sort((a, b) => a.index - b.index)
            .map((item) => (
              <ListItem
                key={item.index}
                disablePadding
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  selected={selectedIndex === item.index}
                  onClick={() => handleNavigation(item)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: ColorType.black,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          iconMappings.find((ic) => ic.name === item.icon)
                            ?.icon || RectangleGroup,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={activeLocale === 'en' ? item.name : item.nameFr}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                    primaryTypographyProps={{
                      color: ColorType.black,
                      fontWeight: 'bold',
                      fontSize: 15,
                      fontFamily: 'Inter',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </ListWithMargin>
        {open && (
          <StyledList>
            {isAdmin && (
              <SelectInput
                values={activeLocale === 'en' ? companies : companiesFr}
                value={company}
                OnChange={handleChangeCompany}
              />
            )}
            <SelectInput
              values={activeLocale === 'en' ? buildings : buildingsFr}
              value={building}
              OnChange={(e) => handleChangeBuilding(e, companyId)}
            />
          </StyledList>
        )}
      </Drawer>
    </Box>
  );
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ListWithMargin = styled(List)`
  margin-bottom: 45px;
`;

const StyledList = styled(List)`
  margin-inline: 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default LeftSideMenu;
