import { Navigate } from 'react-router-dom';
import { isFullAuthenticated as isFullAuthenticatedSelector } from '../redux/selectors/user';
import { useAppSelector } from '../utils/redux';

const PublicRoute = ({ children }) => {
  const isFullAuthenticated = useAppSelector(isFullAuthenticatedSelector);
  if (isFullAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default PublicRoute;
