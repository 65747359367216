import React from 'react';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import ColorType from '../Theme/ColorType';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type AlertProp = {
  text: string;
  type: AlertColor;
  txtColor?: ColorType;
  bgColor?: ColorType;
  hasIcon?: boolean;
  style?: any;
};

const InfoSnap = ({
  text,
  type = 'info',
  hasIcon,
  txtColor = ColorType.infoDark,
  bgColor = ColorType.infoBackground,
  style,
}: AlertProp) => {
  return (
    <Alert
      severity={type}
      icon={hasIcon}
      sx={[
        style,
        {
          color: txtColor,
          backgroundColor: bgColor,
          boxShadow: 'none',
        },
      ]}
    >
      {text}
    </Alert>
  );
};

export default InfoSnap;
