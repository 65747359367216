import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';

type InputType = {
  label?: string;
  type?: string;
  value: string;
  placeholder?: string;
  OnChange: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  color?: string;
};
const PasswordInput = ({
  value,
  placeholder,
  OnChange,
  error,
  errorMessage,
  color,
}: InputType) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
        value={value}
        onChange={(event) => OnChange(event.target.value)}
        fullWidth
        error={error}
        style={{ backgroundColor: color ?? 'transparent' }}
      />
      {errorMessage && (
        <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default PasswordInput;
