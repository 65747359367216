import {
  SET_CONTROLLERS,
  SET_DEVICE,
  SET_GATEWAYS,
  SET_MESSAGES,
  SET_SENSORS,
  SET_UNAUTHENTICATED,
  UPDATE_STATUS_CONTROLLER,
  UPDATE_STATUS_CONTROLLER_LIVE,
  UPDATE_STATUS_SENSOR,
} from '../types';

const initialState = {
  gateways: [],
  controllers: {
    items: [],
    meta: {},
  },
  sensors: [],
  messages: [],
  device: {},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GATEWAYS:
      const gateways = action.payload;
      return {
        ...state,
        gateways: {
          items: gateways.items,
          meta: gateways.meta,
        },
      };
    case SET_CONTROLLERS:
      const controllers = action.payload;
      return {
        ...state,
        controllers: {
          items: controllers.items,
          meta: controllers.meta,
        },
      };
    case SET_SENSORS:
      const sensors = action.payload;
      return {
        ...state,
        sensors: {
          items: sensors.items,
          meta: sensors.meta,
        },
      };
    case SET_MESSAGES:
      const messages = action.payload;
      return {
        ...state,
        messages: {
          items: messages.items,
          meta: messages.meta,
        },
      };
    case UPDATE_STATUS_CONTROLLER:
      return {
        ...state,
        controllers: {
          ...state.controllers,
          items: state.controllers.items.map((controller) =>
            controller.id === action.payload
              ? { ...controller, status: !controller.status }
              : controller
          ),
        },
      };
    case UPDATE_STATUS_CONTROLLER_LIVE:
      return {
        ...state,
        controllers: {
          ...state.controllers,
          items: state.controllers.items.map((controller) =>
            controller.id === action.payload.id
              ? {
                  ...controller,
                  status: action.payload.status,
                  status_label: action.payload.status_label,
                  channels: action.payload.channels,
                }
              : controller
          ),
        },
      };
    case UPDATE_STATUS_SENSOR:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          items: state.sensors.items.map((sensor) => ({
            ...sensor,
            controllers: sensor.controllers.map((controller) =>
              controller.id === action.payload.id
                ? {
                    ...controller,
                    status: action.payload.status,
                    status_label: action.payload.status_label,
                    channels: action.payload.sensors,
                  }
                : controller
            ),
          })),
        },
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
