import { ReactElement } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SubTitle, Title } from '../Text';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

type CardItemProps = {
  title?: string;
  subTitle?: string;
  children?: ReactElement;
  alert?: ReactElement;
  backArrow?: boolean;
  bgColor?: string;
};
const CardItem = ({
  title,
  subTitle,
  children,
  alert,
  backArrow,
  bgColor,
}: CardItemProps) => {
  const navigate = useNavigate();
  return (
    <CenteredContainer>
      <CenteredCard>
        <ContentCard style={{ backgroundColor: bgColor ? bgColor : 'white' }}>
          {/* {(title || subTitle) && (
            <TitleContainer>
              {subTitle && <SubTitle text={subTitle} isUpperCase={true} />}
              {title && <Title text={title} />}
            </TitleContainer>
          )} */}
          {backArrow && (
            <ArrowContainer>
              <ArrowBackIcon
                onClick={() => navigate(-1)}
                style={{ cursor: 'pointer' }}
              />
            </ArrowContainer>
          )}
          {alert && <AlertContainer>{alert}</AlertContainer>}
          {children && <FormContainer>{children}</FormContainer>}
        </ContentCard>
      </CenteredCard>
    </CenteredContainer>
  );
};

const CenteredContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    min-width: 90%;
  }
`;

const CenteredCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 452px;

  @media (max-width: 640px) {
    // min-width: 100%;
    width: 90%;
  }
  @media (max-height: 638px) {
    height: 90% !important;
  }
`;

const ContentCard = styled(CardContent)`
  width: -webkit-fill-available;
`;

// const TitleContainer = styled('div')`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 100px;
//   margin-bottom: 45px;
// `;

const ArrowContainer = styled('div')`
  margin-right: 15%;
  margin-left: 15%;
  margin-top: 15%;
  @media (max-width: 640px) {
    margin-right: 1%;
    margin-left: 1%;
    margin-top: 10px;
  }
`;

const AlertContainer = styled('div')`
  margin-right: 15%;
  margin-left: 15%;
  margin-bottom: 75px;
  @media (max-width: 640px) {
    margin-right: 1%;
    margin-left: 1%;
    padding: 35px;
  }
`;

const FormContainer = styled('div')`
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 30px;
  @media (max-width: 640px) {
    margin-right: 1%;
    margin-left: 1%;
    padding: 30px;
  }
`;

export default CardItem;
