export const privacySectionsEn = [
  {
    id: 1,
    title: 'Introduction',
    text: "Welcome to BuildUp! We are committed to protecting the privacy and security of our users' data. This Privacy Policy outlines our practices regarding the collection, use, and sharing of information through our web and mobile applications.",
  },
  {
    id: 2,
    title: 'Information We Collect',
    text: '- Personal Information: Includes name, email address, phone number, and any other information you provide during account registration.<br/> - Usage Data: Information on how you use the application, including but not limited to, the types of buildings monitored, system performance data, and settings preferences.<br/> - Device Information: Information about the mobile device or computer you use to access our services, such as hardware model, operating system, and unique device identifiers.',
  },
  {
    id: 3,
    title: 'How We Use Your Information',
    text: '- To provide, maintain, and improve the BuildUp! services.<br /> - To communicate with you, including sending updates about your account and informational notices.<br /> - For customer support and to respond to your inquiries.<br /> - For security purposes, to protect our services and our users against fraudulent activities.<br /> - To analyze and monitor usage of our applications for trend identification and marketing purposes.',
  },
  {
    id: 4,
    title: 'How We Share Your Information',
    text: '- Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as data hosting and maintenance, customer service, marketing, and analytics.<br /> - Compliance and Harm Prevention: We may disclose your information when legally required or when necessary to protect our rights and safety, or the rights and safety of others.<br /> - Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.',
  },
  {
    id: 5,
    title: 'Your Privacy Rights',
    text: 'Depending on your location, you may have certain rights under data protection laws, such as the right to access, correct, or delete your personal information that we hold. You may also have the right to object to or restrict certain processing activities.',
  },
  {
    id: 6,
    title: 'Data Security',
    text: 'We implement appropriate technical and organizational measures to protect the security of your personal information. However, no security system is impenetrable, and we cannot guarantee the security of our databases.',
  },
  {
    id: 7,
    title: 'International Data Transfers',
    text: 'Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.',
  },
  {
    id: 8,
    title: 'Children’s Privacy',
    text: 'Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13.',
  },
  {
    id: 9,
    title: 'Changes to This Privacy Policy',
    text: 'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.',
  },
  {
    id: 10,
    title: 'Contact Us',
    text: 'If you have any questions about this Privacy Policy, please contact us at: info@thingsfactory.com',
  },
];

export const privacySectionsFr = [
  {
    id: 1,
    title: 'Introduction',
    text: "Bienvenue à BuildUp ! Nous nous engageons à protéger la confidentialité et la sécurité des données de nos utilisateurs. Cette politique de confidentialité décrit nos pratiques concernant la collecte, l'utilisation et le partage d'informations via nos applications Web et mobiles.",
  },
  {
    id: 2,
    title: 'Informations que nous recueillons',
    text: "- Informations personnelles : Comprend le nom, l'adresse e-mail, le numéro de téléphone et toute autre information que vous fournissez lors de l'enregistrement du compte.<br /> - Données d'utilisation : Informations sur la façon dont vous utilisez l'application, y compris, mais sans s'y limiter, les types de bâtiments surveillés, les données de performance du système et les préférences de paramètres.<br /> - Informations sur l'appareil : Informations sur l'appareil mobile ou l'ordinateur que vous utilisez pour accéder à nos services, telles que le modèle de matériel, le système d'exploitation et les identifiants uniques de l'appareil.",
  },
  {
    id: 3,
    title: 'Comment nous utilisons vos informations',
    text: "- Pour fournir, maintenir et améliorer l'accumulation ! les services.<br /> - Pour communiquer avec vous, y compris l'envoi de mises à jour sur votre compte et d'avis d'information.<br /> - Pour le support client et pour répondre à vos demandes.<br /> - Pour des raisons de sécurité, pour protéger nos services et nos utilisateurs contre les activités frauduleuses.<br /> - Analyser et surveiller l'utilisation de nos applications à des fins d'identification des tendances et de marketing.",
  },
  {
    id: 4,
    title: 'Comment nous partageons vos informations',
    text: "- Fournisseurs de services : Nous pouvons partager vos informations avec des fournisseurs de services tiers qui fournissent des services en notre nom, tels que l'hébergement et la maintenance des données, le service client, le marketing et l'analyse.<br /> - Conformité et prévention des préjudices : Nous pouvons divulguer vos informations lorsque la loi l'exige ou lorsque cela est nécessaire pour protéger nos droits et notre sécurité, ou les droits et la sécurité d'autrui.<br /> - Transferts d'entreprise : Dans le cadre de toute fusion, vente d'actifs de la société, financement ou acquisition de tout ou partie de nos activités par une autre société.",
  },
  {
    id: 5,
    title: 'Vos droits à la vie privée',
    text: "Selon votre emplacement, vous pouvez avoir certains droits en vertu des lois sur la protection des données, tels que le droit d'accéder, de corriger ou de supprimer vos informations personnelles que nous détenons. Vous pouvez également avoir le droit de vous opposer ou de restreindre certaines activités de traitement.",
  },
  {
    id: 6,
    title: 'Sécurité des données',
    text: "Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger la sécurité de vos renseignements personnels. Cependant, aucun système de sécurité n'est impénétrable et nous ne pouvons pas garantir la sécurité de nos bases de données.",
  },
  {
    id: 7,
    title: 'Transferts internationaux de données',
    text: 'Vos informations peuvent être transférées et conservées sur des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.',
  },
  {
    id: 8,
    title: 'Protection de la vie privée des enfants',
    text: "Nos services ne s'adressent pas aux personnes de moins de 13 ans. Nous ne recueillons pas sciemment d'informations personnelles auprès d'enfants de moins de 13 ans.",
  },
  {
    id: 9,
    title: 'Modifications apportées à cette politique de confidentialité',
    text: 'Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page et en mettant à jour la « date de mise à jour » en haut de cette politique.',
  },
  {
    id: 10,
    title: 'Contactez-nous',
    text: "Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : info@thingsfactory.com",
  },
];

export const termsSectionsEn = [
  {
    id: 1,
    title: '',
    text: 'Welcome to BuildUp!, a vital signs monitoring software for buildings provided by Thingsfactory Co. By accessing or using the software, you agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use the software.',
  },
  {
    id: 2,
    title: '',
    text: 'We grant you a limited, non-exclusive, non-transferable license to use the software in accordance with these terms and conditions. You may not sublicense, sell, or distribute the software. You may not reverse engineer, decompile, or disassemble the software.',
  },
  {
    id: 3,
    title: '',
    text: 'The software is owned by Thingsfactory Co (9395-5110 Qc Inc) and is protected by intellectual property laws.',
  },
  {
    id: 4,
    title: '',
    text: 'You may be required to create an account to use certain features of the software. You are responsible for maintaining the confidentiality of your account information. You are responsible for all activities that occur under your account. Notify us immediately of any unauthorized use of your account. We reserve the right to terminate or suspend your account at any time for any reason without notice.',
  },
  {
    id: 5,
    title: '',
    text: 'You may use the software for your personal or business use in accordance with these terms and conditions. You may not use the software for any illegal or unauthorized purpose. You may not interfere with or disrupt the software. You are solely responsible for any content you upload or share using the software. You grant us a non-exclusive, royalty-free license to use your content.',
  },
  {
    id: 6,
    title: '',
    text: 'We strive to maintain the availability of the software, but we do not guarantee uninterrupted access. We provide support for the software through our Help Center https://freshdesk.thingsfactory.com. Response times may vary. We may perform maintenance or updates to the software at any time without notice.',
  },
  {
    id: 7,
    title: '',
    text: 'You agree to use the software in a manner that complies with all applicable laws and regulations. You may not engage in any activities that are illegal, harmful, or violate the rights of others. We reserve the right to suspend or terminate your account if you violate the acceptable use policy.',
  },
  {
    id: 8,
    title: '',
    text: 'All content included in or made available through the software, such as text, graphics, logos, images, audio clips, video, data, music, software, and other material (collectively "Content"), is the property of Thingsfactory or its licensors and is protected by copyright laws. You may not modify, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any Content obtained from or through the software.',
  },
  {
    id: 9,
    title: '',
    text: 'We collect personal information from you when you use our software. This information may include your name, email address, and other information. We use your information to provide the software to you, improve our products and services, and communicate with you. We may disclose your information to third parties as required by law or to protect our rights. You have the right to access, correct, or delete your personal information. Contact us for more information at info@thingsfactory.com',
  },
  {
    id: 10,
    title: '',
    text: 'We own all intellectual property rights in the software, including patents, copyrights, and trademarks. All trademarks and logos used in connection with the software are the property of their respective owners. We respect the intellectual property rights of others. Contact us if you believe your copyright has been infringed.',
  },
  {
    id: 11,
    title: '',
    text: 'We warrant that the software will perform substantially in accordance with the documentation for a period of 1 year from the date of purchase. If the software does not meet this warranty, we will, at our option, either repair or replace the software.',
  },
  {
    id: 12,
    title: '',
    text: 'EXCEPT FOR THE EXPRESS WARRANTY PROVIDED ABOVE, THE SOFTWARE IS PROVIDED "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL OTHER WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON INFRINGEMENT OF THIRD-PARTY RIGHTS.',
  },
  {
    id: 13,
    title: '',
    text: 'OUR LIABILITY FOR BREACH OF THE LIMITED WARRANTY ABOVE IS LIMITED TO THE AMOUNT YOU PAID FOR THE SOFTWARE. IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, ARISING OUT OF OR IN CONNECTION WITH THE SOFTWARE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
  },
  {
    id: 14,
    title: '',
    text: 'These terms of use are governed by the laws of the Province of Quebec, Canada. Any disputes arising out of these terms of use may be resolved through arbitration in the judicial district of Montreal, in the Province of Quebec, Canada.',
  },
  {
    id: 15,
    title: '',
    text: 'These terms and conditions constitute the entire agreement between you and us regarding the software. If any provision of these terms and conditions is found to be invalid, the remaining provisions will continue to be valid and enforceable. We reserve the right to change these terms and conditions at any time. Any changes will be effective immediately upon posting.',
  },
  {
    id: 16,
    title: '',
    text: 'For support or questions about the software, please contact support@thingsfactory.com. For legal inquiries, please contact info@thingsfactory.com',
  },
];

export const termsSectionsFr = [
  {
    id: 1,
    title: '',
    text: "Bienvenue sur BuildUp !, un logiciel de surveillance des signes vitaux pour les bâtiments fourni par Thingsfactory Co. En accédant ou en utilisant le logiciel, vous acceptez d'être lié par ces termes et conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le logiciel.",
  },
  {
    id: 2,
    title: '',
    text: 'Nous vous accordons une licence limitée, non exclusive et non transférable pour utiliser le logiciel conformément aux présentes conditions générales. Vous ne pouvez pas sous-licencier, vendre ou distribuer le logiciel. Vous ne pouvez pas désosser, décompiler ou désassembler le logiciel.',
  },
  {
    id: 3,
    title: '',
    text: 'Le logiciel appartient à Thingsfactory Co (9395-5110 Qc Inc) et est protégé par les lois sur la propriété intellectuelle.',
  },
  {
    id: 4,
    title: '',
    text: 'Vous devrez peut-être créer un compte pour utiliser certaines fonctionnalités du logiciel. Vous êtes responsable du maintien de la confidentialité des informations de votre compte. Vous êtes responsable de toutes les activités qui se produisent sous votre compte. Informez-nous immédiatement de toute utilisation non autorisée de votre compte. Nous nous réservons le droit de résilier ou de suspendre votre compte à tout moment pour quelque raison que ce soit et sans préavis.',
  },
  {
    id: 5,
    title: '',
    text: "Vous pouvez utiliser le logiciel pour votre usage personnel ou professionnel conformément à ces termes et conditions. Vous ne pouvez pas utiliser le logiciel à des fins illégales ou non autorisées. Vous ne pouvez pas interférer avec ou perturber le logiciel. Vous êtes seul responsable de tout contenu que vous téléchargez ou partagez à l'aide du logiciel. Vous nous accordez une licence non exclusive et libre de redevances pour utiliser votre contenu.",
  },
  {
    id: 6,
    title: '',
    text: "Nous nous efforçons de maintenir la disponibilité du logiciel, mais nous ne garantissons pas un accès ininterrompu. Nous fournissons un support pour le logiciel via notre centre d'aide https://freshdesk.thingsfactory.com. Les temps de réponse peuvent varier. Nous pouvons effectuer la maintenance ou les mises à jour du logiciel à tout moment sans préavis.",
  },
  {
    id: 7,
    title: '',
    text: "Vous acceptez d'utiliser le logiciel d'une manière conforme à toutes les lois et réglementations applicables. Vous ne pouvez pas vous engager dans des activités illégales, nuisibles ou violant les droits d'autrui. Nous nous réservons le droit de suspendre ou de résilier votre compte si vous enfreignez la politique d'utilisation acceptable.",
  },
  {
    id: 8,
    title: '',
    text: "Tout le contenu inclus dans ou mis à disposition via le logiciel, tel que le texte, les graphiques, les logos, les images, les clips audio, la vidéo, les données, la musique, le logiciel et tout autre matériel (collectivement, le « Contenu »), est la propriété de Thingsfactory ou de ses concédants de licence et est protégé par les lois sur le droit d'auteur. Vous ne pouvez pas modifier, distribuer, transmettre, afficher, exécuter, reproduire, publier, concéder sous licence, créer des œuvres dérivées, transférer ou vendre tout contenu obtenu à partir ou via le logiciel.",
  },
  {
    id: 9,
    title: '',
    text: "Nous recueillons des renseignements personnels auprès de vous lorsque vous utilisez notre logiciel. Ces informations peuvent inclure votre nom, votre adresse e-mail et d'autres informations. Nous utilisons vos informations pour vous fournir le logiciel, améliorer nos produits et services et communiquer avec vous. Nous pouvons divulguer vos informations à des tiers comme l'exige la loi ou pour protéger nos droits. Vous avez le droit d'accéder à vos informations personnelles, de les corriger ou de les supprimer. Contactez-nous pour plus d'informations à info@thingsfactory.com",
  },
  {
    id: 10,
    title: '',
    text: "Nous détenons tous les droits de propriété intellectuelle sur le logiciel, y compris les brevets, les droits d'auteur et les marques de commerce. Toutes les marques de commerce et tous les logos utilisés dans le cadre du logiciel sont la propriété de leurs propriétaires respectifs. Nous respectons les droits de propriété intellectuelle d'autrui. Contactez-nous si vous pensez que vos droits d'auteur ont été violés.",
  },
  {
    id: 11,
    title: '',
    text: "Nous garantissons que le logiciel fonctionnera substantiellement conformément à la documentation pendant une période de 1 an à compter de la date d'achat. Si le logiciel ne répond pas à cette garantie, nous allons, à notre choix, soit réparer ou remplacer le logiciel.",
  },
  {
    id: 12,
    title: '',
    text: "À L'EXCEPTION DE LA GARANTIE EXPRESSE FOURNIE CI-DESSUS, LE LOGICIEL EST FOURNI « TEL QUEL » SANS AUCUNE GARANTIE D'AUCUNE SORTE, EXPRESSE OU IMPLICITE. NOUS DÉCLINONS TOUTE AUTRE GARANTIE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADÉQUATION À UN USAGE PARTICULIER ET DE NON-VIOLATION DES DROITS DE TIERS.",
  },
  {
    id: 13,
    title: '',
    text: "NOTRE RESPONSABILITÉ POUR VIOLATION DE LA GARANTIE LIMITÉE CI-DESSUS EST LIMITÉE AU MONTANT QUE VOUS AVEZ PAYÉ POUR LE LOGICIEL. EN AUCUN CAS, NOUS NE SERONS RESPONSABLES DES DOMMAGES INDIRECTS, ACCESSOIRES, CONSÉCUTIFS, SPÉCIAUX OU PUNITIFS, Y COMPRIS, MAIS SANS S'Y LIMITER, LA PERTE DE PROFITS, DÉCOULANT DE OU EN RELATION AVEC LE LOGICIEL, MÊME SI NOUS AVONS ÉTÉ INFORMÉS DE LA POSSIBILITÉ DE TELS DOMMAGES.",
  },
  {
    id: 14,
    title: '',
    text: "Les présentes conditions d'utilisation sont régies par les lois de la province de Québec, Au Canada. Tout litige découlant des présentes conditions d'utilisation peut être résolu par arbitrage dans le district judiciaire de Montréal, dans la province de Québec, Canada.",
  },
  {
    id: 15,
    title: '',
    text: "Ces termes et conditions constituent l'intégralité de l'accord entre vous et nous concernant le logiciel. Si une disposition des présentes conditions générales est jugée invalide, les autres dispositions continueront d'être valides et exécutoires. Nous nous réservons le droit de modifier ces termes et conditions à tout moment. Toute modification entrera en vigueur dès sa publication.",
  },
  {
    id: 16,
    title: '',
    text: "Pour obtenir de l'aide ou des questions sur le logiciel, veuillez contacter support@thingsfactory.com. Pour toute question juridique, veuillez communiquer avec info@thingsfactory.com",
  },
];
