import React from 'react';
import { styled } from '@mui/system';
import ColorType from '../components/Theme/ColorType';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledSpan>Copyright © 2023 • Thingsfactory Inc.</StyledSpan>
      <LinkContainer>
        <StyledLink href="/privacy">
          <StyledSpan>{t('register-pp')}</StyledSpan>
        </StyledLink>
        <StyledLink href="/tos">
          <StyledSpan>{t('register-tos')}</StyledSpan>
        </StyledLink>
      </LinkContainer>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-inline: 32px;
  flex: 1;
  margin-top: 20px;
  border-top-width: 1px;
  border-top-color: ${ColorType.softGrey};
  border-top-style: solid;
  padding-top: 5px;
`;

const LinkContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

const StyledLink = styled('a')`
  color: ${ColorType.black};
  text-decoration: none;
`;

const StyledSpan = styled('span')`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`;

export default Footer;
