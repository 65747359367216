enum ColorType {
  primary = '#FF0018',
  primaryDark = '9b000ff5',
  secondary = '#64748B',
  black = '#000000',
  background = '#FFFFFF',
  lightGrey = '#F0F0F0',
  softGrey = '#B2B2B2',
  grey500 = '#2E3646',
  grey50 = '#5F6D7E',
  darkGrey = '#4A4A4A',
  blue = '#0C5399',
  cyan = '#47A3FF',
  infoBackground = '#E9F4FF',
  infoDark = '#0C5399',
  lightRed = '#FEE2E2',
  darkRed = '#991B1B',
  lightGreen = '#EBFFF4',
  softGreen = '#4BB725',
  green = '#4BB725',
  lightOrange = '#FFE5CD',
  softOrange = '#F77F0F',
  softPink = '#FFD6DA',
}

export default ColorType;
