import { useState, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Popup from '../components/Modal/Popup';
import { Theme } from '@mui/system';
import Path from '../components/Link/Path';
import { styled } from '@mui/system';
import { UserTable } from '../components/Tables';
import { getAdminUsers, getUsers } from '../redux/actions/usersAction';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { companyIdSelector } from '../redux/selectors/company';
import {
  isAdminSelector,
  nameSelector,
  roleSelector,
} from '../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';
import { buildingIdSelector } from '../redux/selectors/building';

const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
});

const Users = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(companyIdSelector);
  const buildingId = useAppSelector(buildingIdSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    if (!isAdmin || (isAdmin && !companyId)) dispatch(getUsers(buildingId));
    if (isAdmin && !!companyId) dispatch(getAdminUsers(companyId, buildingId));
  }, [companyId, dispatch, isAdmin]);

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  return (
    <Container>
      <HeaderContainer>
        <StyledDiv>
          <Path />
          <TitleSpan>
            {userName},{' '}
            <DescriptionSpan style={{ fontSize: 24 }}>
              {handleDisplayRole(role)}
            </DescriptionSpan>
          </TitleSpan>
          <DescriptionSpan>{t('users-description')}</DescriptionSpan>
        </StyledDiv>
        <ButtonContainer>
          <ActionButton
            buttonLabel={t('users-popup-label')}
            color={'primary'}
            OnClick={handleOpen}
            style={{
              borderRadius: 6,
            }}
          />
        </ButtonContainer>
      </HeaderContainer>
      <UserTable />
      <Popup open={open} handleClose={handleClose} style={style} />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export default Users;
