import { Navigate } from 'react-router-dom';
import {
  isFullAuthenticated as isFullAuthenticatedSelector,
  roleSelector,
} from '../redux/selectors/user';
import { useAppSelector } from '../utils/redux';

const PrivateRoute = ({ children }) => {
  const isFullAuthenticated = useAppSelector(isFullAuthenticatedSelector);
  const userRole = useAppSelector(roleSelector);
  if (!isFullAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  if (userRole === 'concierge') {
    return <Navigate to="/app" replace />;
  }
  return children;
};

export default PrivateRoute;
