import React, { useEffect } from 'react';
import { H6 } from '../Text';

type TimerProps = {
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  style?: React.CSSProperties;
};
const Timer = ({ seconds, setSeconds }: TimerProps) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
        }
        return prevSeconds !== 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds, setSeconds]);

  const formatTime = (time: number) => {
    return time < 10 ? `0${time}` : time.toString();
  };

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return <H6 text={`${formatTime(minutes)}:${formatTime(remainingSeconds)}`} />;
};

export default Timer;
