import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { ForgotCard, SuccessCard } from '../components/Card';
import ColorType from '../components/Theme/ColorType';
import { setAxiosHeaders } from '../utils/helper';

const Forgot = () => {
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleDisplay = () => {
    switch (step) {
      case 0:
        return <ForgotCard setStep={setStep} />;
      case 1:
        return <SuccessCard setStep={setStep} />;
      default:
        return <ForgotCard setStep={setStep} />;
    }
  };
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: 38,
            lineHeight: '169%',
            color: ColorType.background,
          }}
        >
          BuildUp!
        </span>
      </div>
      {handleDisplay()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <span style={{ fontSize: 16, color: '#c9c9c9' }}>by</span>
        <img
          src="logo_company.svg"
          alt="THINGSFACTORY"
          style={{ height: '16px' }}
        />
      </div>
    </Container>
  );
};

const Container = styled('div')`
  background-image: url(login.png);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: 335px) {
    contain: strict;
  }
  @media (max-height: 545px) {
    overflow-y: scroll;
    height: auto;
  }
`;

export default Forgot;
