import Typography from '@mui/material/Typography/Typography';
import { styled } from '@mui/material/styles';

type TitleProps = {
  text: string;
  color?: string;
};

const Title = ({ text, color }: TitleProps) => {
  return <TextTitle color={color ?? 'typo.main'}>{text}</TextTitle>;
};

const TextTitle = styled(Typography)({
  fontSize: 32,
  fontWeight: 600,
  lineHeight: '22px',
  padding: 16,
});

export default Title;
