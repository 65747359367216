import dayjs from 'dayjs';
import ColorType from '../components/Theme/ColorType';
import { Order, colorsProp } from '../const/types';
import axios from 'axios';
import i18n from '../i18n/config';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: any[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export const displayStatusDevices = (status: string) => {
  const colors: colorsProp = {};
  switch (status) {
    case 'active':
      colors.bgColor = ColorType.lightGreen;
      colors.circleBg = ColorType.softGreen;
      return colors;
    case 'inactive':
      colors.bgColor = ColorType.lightRed;
      colors.circleBg = ColorType.darkRed;
      return colors;
    default:
      colors.bgColor = ColorType.softGrey;
      colors.circleBg = ColorType.grey500;
      return colors;
  }
};

export const timeStringTodateHelper = (time: string) => {
  const [startHours, startMinutes, startSeconds] = time.split(':').map(Number);
  const dateTime = dayjs()
    .set('hour', startHours)
    .set('minute', startMinutes)
    .set('second', startSeconds);
  return dateTime;
};

export const dateTimeHelper = (time: string) => {
  const [startHours, startMinutes, startSeconds] = time.split(':').map(Number);
  const dateTime = dayjs()
    .set('hour', startHours)
    .set('minute', startMinutes)
    .set('second', startSeconds)
    .format('h:mm A');
  return dateTime;
};

export const closestToTime = (time: string) => {
  const [startHours, startMinutes, startSeconds] = time.split(':').map(Number);
  const dateTime = dayjs()
    .set('hour', startHours)
    .set('minute', startMinutes)
    .set('second', startSeconds);
  if (dateTime.isBefore(dayjs())) {
    return dateTime.add(1, 'day');
  } else {
    return dateTime;
  }
};

export const displayLastSeen = (
  date: string,
  isOffline: boolean,
  isExceed24?: boolean
) => {
  if (date) {
    const targetDate = dayjs(date);
    if (isOffline || isExceed24) {
      // More than 24 hours ago, return full date
      return targetDate.format('DD/MM/YYYY HH:mm');
    } else {
      // Less than 24 hours ago, return hour:minute
      return targetDate.format('HH:mm');
    }
  }
};

export const setAxiosHeaders = () => {
  const currentLanguage = i18n.language;
  axios.defaults.headers.common['Accept-Language'] = currentLanguage;
};
