import {
  ADD_COMPANY,
  SET_COMPANIES,
  SET_COMPANY,
  SET_UNAUTHENTICATED,
  SET_USER_SECOND_AUTH,
  TOGGLE_COMPANY_SUPPORT,
  UNSET_COMPANY,
  UPDATE_COMPANY,
} from '../types';

const initialState = {
  companies: [],
  company: {},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_SECOND_AUTH:
      return {
        ...state,
        ...(action.payload?.company && { company: action.payload.company }),
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case SET_COMPANY:
      const company = state.companies.find(
        (company) => company.id === action.payload
      );
      return {
        ...state,
        company,
      };
    case UNSET_COMPANY:
      return {
        ...state,
        company: {},
      };
    case ADD_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        company: {
          ...action.payload,
        },
      };
    case TOGGLE_COMPANY_SUPPORT:
      return {
        ...state,
        company: {
          ...state.company,
          allow_admin_support:
            state?.company && !state.company.allow_admin_support,
        },
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
