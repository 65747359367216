import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Users from '../pages/Users';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Error from '../pages/Error';
import Forgot from '../pages/Forgot';
import Reset from '../pages/Reset';
import CompanyManagement from '../pages/CompanyManagement';
import CompanyDetails from '../pages/CompanyDetails';
import BuildingManagement from '../pages/BuildingManagement';
import BuildingDetails from '../pages/BuildingDetails';
import AddCompany from '../pages/AddCompany';
import AddBuilding from '../pages/AddBuilding';
import LayoutWithMenu from './LayoutWithMenu';
import { useAppSelector } from '../utils/redux';
import { roleSelector } from '../redux/selectors/user';
import UserDetails from '../pages/UserDetails';
import ComponentsManagement from '../pages/ComponentsManagement';
import ComponentDetails from '../pages/ComponentDetails';
import AddComponent from '../pages/AddComponent';
import SchedulerManagement from '../pages/SchedulerManagement';
import Profile from '../pages/Profile';
import Redirection from '../pages/Redirection';
import PrivacyAndTerms from '../pages/PrivacyAndTerms';
import TopSideMenu from './TopSideMenu';
import { useEffect } from 'react';
import { setAxiosHeaders } from '../utils/helper';

function App() {
  const userRole = useAppSelector(roleSelector);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
              {/* <Footer /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
              {/* <Footer /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Forgot />
              {/* <Footer /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/password-reset"
          element={
            <PublicRoute>
              <Reset />
              {/* <Footer /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              {userRole && <TopSideMenu isWithLogo />}
              <PrivacyAndTerms activeTab={1} />
            </>
          }
        />
        <Route
          path="/tos"
          element={
            <>
              {userRole && <TopSideMenu isWithLogo />}
              <PrivacyAndTerms activeTab={0} />
            </>
          }
        />

        {userRole === 'concierge' && (
          <Route path="/app" element={<Redirection />} />
        )}

        <Route
          path="/"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <Home />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <Users />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/users/user-details/:id"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <UserDetails />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        {userRole !== 'building-manager' && (
          <>
            <Route
              path="/company-management"
              element={
                <PrivateRoute>
                  <LayoutWithMenu>
                    <CompanyManagement />
                  </LayoutWithMenu>
                </PrivateRoute>
              }
            />
            <Route
              path="/company-management/add-company"
              element={
                <PrivateRoute>
                  <LayoutWithMenu>
                    <AddCompany />
                  </LayoutWithMenu>
                </PrivateRoute>
              }
            />
            <Route
              path="/company-management/company-details/:id"
              element={
                <PrivateRoute>
                  <LayoutWithMenu>
                    <CompanyDetails />
                  </LayoutWithMenu>
                </PrivateRoute>
              }
            />
          </>
        )}
        <Route
          path="/building-management"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <BuildingManagement />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/building-management/add-building"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <AddBuilding />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/building-management/building-details/:id"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <BuildingDetails />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <ComponentsManagement />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/add-gateway"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <AddComponent />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/gateway/:id"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <ComponentDetails />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/add-controller"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <AddComponent />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/controller/:id"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <ComponentDetails />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/add-sensor"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <AddComponent />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/sensor/:id"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <ComponentDetails />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/schedulers"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <SchedulerManagement />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <LayoutWithMenu>
                <Profile />
              </LayoutWithMenu>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
