import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';

type TextProps = {
  children?: string | number;
  color?: ColorType;
};
const Text = ({ children, color = ColorType.darkGrey }: TextProps) => {
  return <StyledText color={color}>{children}</StyledText>;
};

type StyledTextProps = {
  color: ColorType;
};
const StyledText = styled('span')<StyledTextProps>`
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  color: ${({ color }) => color};
`;

export default Text;
