/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

type variantType = 'text' | 'contained' | 'outlined';

type ButtonType = {
  variant?: variantType;
  buttonLabel: string;
  color?: any;
  style?: React.CSSProperties;
  OnClick: () => void;
  disabled?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
};

type ButtonProps = {
  hasIcon?: boolean;
};

const ActionButton = ({
  buttonLabel,
  color,
  OnClick,
  variant = 'contained',
  style,
  disabled,
  iconLeft,
  iconRight,
}: ButtonType) => {
  return (
    <>
      <BootstrapButton
        color={color}
        variant={variant}
        onClick={OnClick}
        style={style}
        disabled={disabled}
        hasIcon={!!(iconLeft || iconRight)}
      >
        {iconLeft && iconLeft}
        {buttonLabel}
        {iconRight && iconRight}
      </BootstrapButton>
    </>
  );
};

const BootstrapButton = styled(Button)<ButtonProps>((hasIcon) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: hasIcon ? '10px  16px' : '10px 80px',
  fontFamily: 'Inter',
}));

export default ActionButton;
