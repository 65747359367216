import {
  SET_USER,
  SET_USER_FIRST_AUTH,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER_SECOND_AUTH,
  UPDATE_USER,
} from '../types';

const initialState = {
  authenticated: false,
  doubleAuth: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_USER_FIRST_AUTH:
      return {
        ...state,
        authenticated: true,
        doubleAuth: false,
        ...(action.payload?.role && action.payload.role === 'manager'
          ? 'admin'
          : action.payload.role),
        ...action.payload,
      };
    case SET_USER_SECOND_AUTH:
      return {
        ...state,
        authenticated: true,
        doubleAuth: true,
        role: action.payload.role === 'manager' ? 'admin' : action.payload.role,
        email: action.payload.email,
        ...(action.payload?.name && { name: action.payload.name }),
        ...(action.payload?.phone && { phone: action.payload.phone }),
      };
    case SET_USER:
      const { role, ...payload } = action.payload;
      const newRole = role === 'manager' ? 'admin' : role;
      return {
        ...state,
        role: newRole,
        ...payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
