/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Avatar } from '@mui/material';

import Paper from '@mui/material/Paper';

import { Building, PencilSquare } from '../svg';
import { companiesSelector } from '../../redux/selectors/company';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { CompanyData, Order } from '../../const/types';
import { headCells } from '../../const/arrays';
import { getComparator, stableSort } from '../../utils/helper';
import PaginationTable from './PaginationTable';
import { selectCompany } from '../../redux/actions/companyAction';
import ColorType from '../Theme/ColorType';
import { useTranslation } from 'react-i18next';

const rowsPerPage = 5;

const CompanyTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companies = useAppSelector(companiesSelector);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof CompanyData>('name');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(companies.length / rowsPerPage); i += 1) {
      pageNumbers.push(i);
    }
    setTotalPages(pageNumbers.length);
  }, [companies.length]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CompanyData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (row: any) => {
    dispatch(selectCompany({ companyId: parseInt(row.id, 10) }));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(companies, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [companies, order, orderBy, page]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar
          title={t('companies-table-title')}
          description={t('companies-table-description')}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                    sx={{ borderBottom: '1px solid #D1D9E2' }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: '16px 0px 16px 24px',
                      }}
                    >
                      <RowContainer>
                        <Avatar
                          alt="Remy Sharp"
                          sx={{
                            width: 40,
                            height: 40,
                            marginLeft: '-7px',
                            backgroundColor: ColorType.darkGrey,
                          }}
                        >
                          <Building fill={ColorType.background} />
                        </Avatar>
                        <ColumnContainer>
                          <NameText>{row.name}</NameText>
                        </ColumnContainer>
                      </RowContainer>
                    </TableCell>
                    <TableCell align="left">
                      <Address>{row.address}</Address>
                    </TableCell>
                    <TableCell align="right">
                      <PencilSquare
                        height={17}
                        width={17}
                        color="#0C5399"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(row)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationTable
          page={page}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Paper>
    </Box>
  );
};

const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled('div')`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NameText = styled('span')`
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  color: ${ColorType.darkGrey};
`;

const Address = styled('span')`
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: #5f6d7e;
`;

export default CompanyTable;
