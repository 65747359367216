import { useState } from 'react';
import { ScheduleTable } from '../Tables';
import ScheduleCard from './ScheduleCard';
import { ControllerItemDTO } from '../../redux/types/controller.dto';

const ScheduleByControllerCard = () => {
  const [controllers, setControllers] = useState<ControllerItemDTO[]>([]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      <ScheduleTable
        newControllers={controllers}
        setControllers={setControllers}
      />
      {controllers
        .sort((a, b) => a.id - b.id)
        .map((controller: ControllerItemDTO) => {
          return <ScheduleCard key={controller.id} controller={controller} />;
        })}
    </div>
  );
};

export default ScheduleByControllerCard;
