import Typography from '@mui/material/Typography/Typography';
import { styled } from '@mui/material/styles';

type H3Props = {
  text: string;
  style?: React.CSSProperties;
};

const H3 = ({ text, style }: H3Props) => {
  return (
    <TextH3 color="typo.900" style={style}>
      {text}
    </TextH3>
  );
};

const TextH3 = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '21px',
});

export default H3;
