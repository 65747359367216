import { TextField } from '@mui/material';
import s from './style.module.css';
import FormHelperText from '@mui/material/FormHelperText';

type InputType = {
  label?: string;
  type?: string;
  value: string;
  placeholder?: string;
  hasBorder?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  OnChange: (value: string) => void;
};
const Input = ({
  label,
  value,
  type,
  placeholder,
  OnChange,
  hasBorder = false,
  disabled,
  error,
  errorMessage,
}: InputType) => {
  return (
    <div>
      {label && (
        <FormHelperText id="label" className={s.label}>
          {label}
        </FormHelperText>
      )}
      <TextField
        error={error}
        helperText={errorMessage}
        id="input"
        fullWidth
        type={type ? type : 'text'}
        placeholder={placeholder}
        variant="outlined"
        className={`${hasBorder ? s.textFieldWithBorder : s.textField} ${
          disabled && s.textFieldGrey
        }`}
        value={value}
        disabled={disabled}
        onChange={(event) => OnChange(event.target.value)}
      />
    </div>
  );
};

export default Input;
