import {
  SET_DASHBOARD_ALERTS,
  SET_DASHBOARD_ARCHIVED_ALERTS,
  SET_DASHBOARD_BUILDINGS,
  SET_DASHBOARD_EVENTS,
  SET_UNAUTHENTICATED,
} from '../types';

const initialState = {
  buildings: [],
  alerts: [],
  archivedAlerts: [],
  events: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_BUILDINGS:
      return {
        ...state,
        buildings: action.payload,
      };
    case SET_DASHBOARD_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case SET_DASHBOARD_ARCHIVED_ALERTS:
      return {
        ...state,
        archivedAlerts: action.payload,
      };
    case SET_DASHBOARD_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}
