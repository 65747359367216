import { TextField } from '@mui/material';
import s from './style.module.css';

type CodeInputType = {
  value: string;
  indexNumber: number;
  isDark?: boolean;
  OnChange: (i: number, value: string) => void;
  inputRefs: React.MutableRefObject<any>;
};
const CodeInput = ({
  value,
  indexNumber,
  OnChange,
  inputRefs,
  isDark = false,
}: CodeInputType) => {
  return (
    <TextField
      variant="outlined"
      key={indexNumber}
      // size="small"
      placeholder="-"
      value={value}
      onChange={(e) => OnChange(indexNumber, e.target.value)}
      inputProps={{
        maxLength: 1,
        style: {
          textAlign: 'center',
          backgroundColor: isDark ? 'white' : '',
          // padding: 15,
        },
      }}
      inputRef={(ref) => (inputRefs.current[indexNumber] = ref)}
      className={s.codeField}
    />
  );
};

export default CodeInput;
