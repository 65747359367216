import React from 'react';
import { TextField } from '@mui/material';
import s from './style.module.css';
import FormHelperText from '@mui/material/FormHelperText';

type InputType = {
  label?: string;
  type?: string;
  value: string;
  placeholder?: string;
  hasBorder?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  OnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
};
const UnifiedInput = ({
  label,
  value,
  type,
  placeholder,
  OnChange,
  hasBorder = false,
  disabled,
  error,
  errorMessage,
  name,
}: InputType) => {
  return (
    <div>
      {label && (
        <FormHelperText id="label" className={s.label}>
          {label}
        </FormHelperText>
      )}
      <TextField
        error={error}
        helperText={errorMessage}
        id="input"
        fullWidth
        type={type ? type : 'text'}
        placeholder={placeholder}
        variant="outlined"
        className={`${hasBorder ? s.textFieldWithBorder : s.textField} ${
          disabled && s.textFieldGrey
        }`}
        value={value}
        disabled={disabled}
        name={name}
        onChange={OnChange}
      />
    </div>
  );
};

export default UnifiedInput;
