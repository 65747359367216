import React, { ReactElement } from 'react';
import s from './style.module.css';

type IconButtonProps = {
  handleClick: () => void;
  children?: ReactElement;
};
const IconButton = ({ handleClick, children }: IconButtonProps) => {
  return (
    <div className={s.container} onClick={handleClick}>
      {children}
    </div>
  );
};

export default IconButton;
