import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ComponentTabsProps {
  DeviceInformationChildren?: React.ReactNode;
  StatusChildren?: React.ReactNode;
  EventsChildren?: React.ReactNode;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const DeviceTab = ({
  DeviceInformationChildren,
  StatusChildren,
  EventsChildren,
}: ComponentTabsProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
      >
        <Grid item sx={{ borderBottom: 1, borderColor: 'divider', flex: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t('device-update-title')}
              sx={{
                fontFamily: 'Inter',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              {...a11yProps(0)}
            />
            {StatusChildren && (
              <Tab
                label="Status"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                {...a11yProps(1)}
              />
            )}
            {EventsChildren && (
              <Tab
                label="Events"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </Grid>
      </Grid>
      <CustomTabPanel value={value} index={0}>
        {DeviceInformationChildren}
      </CustomTabPanel>
      {StatusChildren && (
        <CustomTabPanel value={value} index={1}>
          {StatusChildren}
        </CustomTabPanel>
      )}
      {EventsChildren && (
        <CustomTabPanel value={value} index={2}>
          {EventsChildren}
        </CustomTabPanel>
      )}
    </Box>
  );
};
