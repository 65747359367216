import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import { Input, SelectInput } from '../Input';
import { useAppSelector } from '../../utils/redux';
import { isAdminSelector, isManagerSelector } from '../../redux/selectors/user';
import {
  BuildingControlSystems,
  BuildingErrorProp,
  BuildingStatuses,
  BuildingTypes,
} from '../../const/types';
import { useTranslation } from 'react-i18next';

type Props = {
  buildingName: string;
  status: string;
  buildingAddress: string;
  buildingTotalArea: string;
  buildingType?: string;
  buildingControlSystem?: string;
  buildingLocation: string;
  buildingOpeningDate: string;
  buildingConstructionDate: string;
  handleChangeName: (text: string) => void;
  handleChangeStatus: (event: SelectChangeEvent) => void;
  handleChangeAddress: (text: string) => void;
  handleChangeTotalArea: (text: string) => void;
  handleChangeType: (event: SelectChangeEvent) => void;
  handleChangeControlSystem: (event: SelectChangeEvent) => void;
  handleChangeLocation: (text: string) => void;
  handleChangeOpeningDate: (text: string) => void;
  handleChangeConstructionDate: (text: string) => void;
  errors: BuildingErrorProp;
};

const GeneralInformationCard = ({
  buildingName,
  status,
  buildingAddress,
  buildingTotalArea,
  buildingType,
  buildingControlSystem,
  buildingLocation,
  buildingOpeningDate,
  buildingConstructionDate,
  handleChangeName,
  handleChangeStatus,
  handleChangeAddress,
  handleChangeTotalArea,
  handleChangeType,
  handleChangeControlSystem,
  handleChangeLocation,
  handleChangeOpeningDate,
  handleChangeConstructionDate,
  errors,
}: Props) => {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  return (
    <GridContentContainer container>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-add-title')}
          placeholder={t('building-detail-general-building')}
          value={buildingName}
          hasBorder
          OnChange={handleChangeName}
          error={!!errors?.buildingName}
          errorMessage={errors?.buildingName}
        />
      </Grid>
      {(isAdmin || isManager) && (
        <Grid xs={12} sm={3.8} item>
          <SelectInput
            label={t('company-status-label')}
            placeholder={t('company-status-placeholder')}
            values={BuildingStatuses}
            value={status}
            OnChange={handleChangeStatus}
            error={!!errors?.status}
            errorMessage={errors?.status}
          />
        </Grid>
      )}
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-add-address-label')}
          placeholder={t('building-add-address-placeholder')}
          value={buildingAddress}
          hasBorder
          OnChange={handleChangeAddress}
          error={!!errors?.buildingAddress}
          errorMessage={errors?.buildingAddress}
        />
      </Grid>

      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-area-label')}
          placeholder={t('building-detail-area-placeholder')}
          value={buildingTotalArea}
          hasBorder
          OnChange={handleChangeTotalArea}
          error={!!errors?.buildingTotalArea}
          errorMessage={errors?.buildingTotalArea}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-type-label')}
          placeholder={t('building-detail-type-placeholder')}
          values={BuildingTypes}
          value={buildingType || ''}
          OnChange={handleChangeType}
          error={!!errors?.buildingType}
          errorMessage={errors?.buildingType}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <SelectInput
          label={t('building-detail-control-label')}
          placeholder={t('building-detail-control-placeholder')}
          values={BuildingControlSystems}
          value={buildingControlSystem || ''}
          OnChange={handleChangeControlSystem}
          error={!!errors?.buildingControlSystem}
          errorMessage={errors?.buildingControlSystem}
        />
      </Grid>

      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-location-label')}
          placeholder={t('building-detail-location-placeholder')}
          value={buildingLocation}
          hasBorder
          OnChange={handleChangeLocation}
          error={!!errors?.buildingLocation}
          errorMessage={errors?.buildingLocation}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-opening-label')}
          placeholder={t('building-detail-opening-placeholder')}
          value={buildingOpeningDate}
          hasBorder
          OnChange={handleChangeOpeningDate}
          error={!!errors?.buildingOpeningDate}
          errorMessage={errors?.buildingOpeningDate}
        />
      </Grid>
      <Grid xs={12} sm={3.8} item>
        <Input
          label={t('building-detail-construction-label')}
          placeholder={t('building-detail-construction-placeholder')}
          value={buildingConstructionDate}
          hasBorder
          OnChange={handleChangeConstructionDate}
          error={!!errors?.buildingConstructionDate}
          errorMessage={errors?.buildingConstructionDate}
        />
      </Grid>
    </GridContentContainer>
  );
};

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default GeneralInformationCard;
