/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import Paper from '@mui/material/Paper';
import { Sensor } from '../svg';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { Order } from '../../const/types';
import { sensorHeadCellsClosed, sensorHeadCellsOpen } from '../../const/arrays';
import { getComparator, stableSort } from '../../utils/helper';
import { useNavigate } from 'react-router';
import { sensorsSelector } from '../../redux/selectors/device';
import EmptyComponent from './EmptyComponent';
import ColorType from '../Theme/ColorType';
import { SensorDTO } from '../../redux/types/sensor.dto';
import { ControllerItemDTO } from '../../redux/types/controller.dto';
import ConfirmPin from '../Modal/ConfirmPin';
import { SensorItem } from '../Card';
import { getDevicesByType } from '../../redux/actions/deviceAction';
import { SENSOR } from '../../const/values';
import NewPaginationTable from './NewPaginationTable';
import { loadingSelector } from '../../redux/selectors/ui';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { buildingSelector } from '../../redux/selectors/building';
import { isAdminSelector, isManagerSelector } from '../../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { companyIdSelector } from '../../redux/selectors/company';
import useEcho from '../../utils/echo';

const SensorTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sensors: SensorDTO = useAppSelector(sensorsSelector);
  const loading = useAppSelector(loadingSelector);
  const echo = useEcho();
  const [controls, setControls] = useState({
    page: 0,
    rowsPerPage: 15,
  });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [controller, setController] = useState<ControllerItemDTO>();
  const [visibleRows, setVisibleRows] = useState<any>([]);
  const [isControllerOpen, setIsControllerOpen] = useState(false);
  const companyId = useAppSelector(companyIdSelector);
  const building = useAppSelector(buildingSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const isSuper = isAdmin || isManager;

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    // Here we are going to listen for real-time events.
    if (echo && companyId) {
      echo
        // @ts-expect-error private
        .private(`buildup.company.${companyId}`)
        .listen('.device.status.changed', (event: any) => {
          if (event.type === 'controller') {
            dispatch({
              type: 'UPDATE_STATUS_SENSOR',
              payload: {
                id: event.id,
                status: event.status,
                status_label: event.status_label,
                channels: event.channels,
              },
            });
          }
        });
      return () => {
        //@ts-expect-error leave echo
        echo.leave(`buildup.company.${companyId}`);
      };
    }
  }, [echo]);

  useEffect(() => {
    dispatch(
      getDevicesByType({
        componentType: SENSOR,
        page: controls.page + 1,
        companyId,
        buildingId: building?.id,
        keyword: '',
      })
    );
    setTotalPages(sensors.meta?.last_page);
  }, [controls]);

  const updateRows = useCallback(() => {
    const newRows = stableSort(sensors.items, getComparator(order, orderBy));
    setVisibleRows(newRows);
  }, [order, orderBy, sensors.items]);

  useEffect(() => {
    updateRows();
  }, [sensors, updateRows]);

  const handleChangeStatus = (controller: ControllerItemDTO) => {
    const data = {
      asset_number: controller.asset_number,
      serial_number: controller.serial_number,
      eui: controller.eui,
      application_key: controller.application_key,
      model: controller.model,
      building_id: controller.building.id,
      floor: controller.device_location.floor,
      floor_area: controller.device_location.floor_area,
      floor_sub_area: controller.device_location.floor_sub_area,
      status: !controller?.status,
      channel: controller.channel,
      channel_status: controller.channel_status,
    };
    setData(data);
    setController(controller);
    handleOpen();
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SensorDTO
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (sensors?.items?.length === 0)
    return (
      <EmptyComponent
        component="Sensor"
        icon={<Sensor width={56} height={56} fill={ColorType.black} />}
        handleClick={() => navigate(`add-sensor`)}
      />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar
          title={t('devices-Sensor')}
          description={t('devices-sensor-description')}
          isWithButton
          handleButtonClick={() => navigate(`add-sensor`)}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress color="primary" />
            </LoadingContainer>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                headCells={
                  isControllerOpen ? sensorHeadCellsOpen : sensorHeadCellsClosed
                }
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows?.map((row: any, index: number) => (
                  <SensorItem
                    sensorRow={row}
                    sensorIndex={index}
                    handleChangeStatus={handleChangeStatus}
                    setIsControllerOpen={setIsControllerOpen}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <NewPaginationTable
          page={controls.page}
          totalPages={totalPages}
          setControls={setControls}
        />
      </Paper>
      <ConfirmPin
        open={openModal}
        handleClose={handleClose}
        data={data}
        controller={controller}
      />
    </Box>
  );
};

const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export default SensorTable;
