import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { TableContainer } from '@mui/material';
import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';

type Props = {
  component: string;
  icon?: JSX.Element;
};
const EmptyItem = ({ component, icon }: Props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <div
          style={{
            backgroundColor: '#F8F9FB',
            borderBottom: '1px solid #D1D9E2',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          }}
        >
          <EnhancedTableToolbar
            title={component}
            description={`Manage ${component}`}
          />
        </div>
        <TableContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 40,
          }}
        >
          {icon && icon}
          <Title>You don't have {component} setup yet</Title>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const Title = styled('span')`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${ColorType.darkGrey};
  margin-top: 24px;
  text-align: center;
`;

export default EmptyItem;
