/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

type variantType = 'text' | 'contained' | 'outlined';

type ButtonType = {
  variant?: variantType;
  buttonLabel: string;
  color?: any;
  disabled?: boolean;
  style?: React.CSSProperties;
  sx?: any;
  OnClick: () => void;
};

const FormButton = ({
  buttonLabel,
  color,
  OnClick,
  variant = 'contained',
  disabled = false,
  style,
  sx,
}: ButtonType) => {
  return (
    <>
      <BootstrapButton
        color={color}
        variant={variant}
        onClick={OnClick}
        style={style}
        disabled={disabled}
        sx={sx}
      >
        {buttonLabel}
      </BootstrapButton>
    </>
  );
};

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 600,
  padding: '10px',
});

export default FormButton;
