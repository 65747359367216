import storage from 'redux-persist/lib/storage';
import {
  configureStore,
  createImmutableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import uiReducer from './reducers/uiReducer';
import buildingReducer from './reducers/buildingReducer';
import usersReducer from './reducers/usersReducer';
import companyReducer from './reducers/companyReducer';
import menuReducer from './reducers/menuReducer';
import deviceReducer from './reducers/deviceReducer';
import scheduleReducer from './reducers/scheduleReducer';
import dashboardReducer from './reducers/dashboardReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['UI'],
};

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [createImmutableStateInvariantMiddleware(), thunk]
    : [thunk];

const reducers = combineReducers({
  User: userReducer,
  Company: companyReducer,
  Building: buildingReducer,
  Users: usersReducer,
  Device: deviceReducer,
  Schedule: scheduleReducer,
  dashboard: dashboardReducer,
  UI: uiReducer,
  Menu: menuReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        warnAfter: 320,
      },
      immutableCheck: {
        warnAfter: 320,
      },
    }).concat(middleware),
  devTools: true,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
