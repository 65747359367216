import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ComponentTabsProps {
  ByControllerChildren?: React.ReactNode;
  ByScheduleChildren?: React.ReactNode;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const SchedulerTab = ({
  ByControllerChildren,
  ByScheduleChildren,
}: ComponentTabsProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
      >
        <Grid item sx={{ borderBottom: 1, borderColor: 'divider', flex: 2 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={t('schedules-tab-controller')}
              sx={{
                fontFamily: 'Inter',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              {...a11yProps(0)}
            />
            {ByScheduleChildren && (
              <Tab
                label={t('schedules-tab-schedule')}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                {...a11yProps(1)}
              />
            )}
          </Tabs>
        </Grid>
      </Grid>
      <CustomTabPanel value={value} index={0}>
        {ByControllerChildren}
      </CustomTabPanel>
      {ByScheduleChildren && (
        <CustomTabPanel value={value} index={1}>
          {ByScheduleChildren}
        </CustomTabPanel>
      )}
    </Box>
  );
};
