import { useState, useEffect, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { CodeInput } from '../Input';
import Grid from '@mui/material/Grid';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import FormHelperText from '@mui/material/FormHelperText';
import {
  disableController,
  enableController,
} from '../../redux/actions/deviceAction';
import { ControllerItemDTO } from '../../redux/types/controller.dto';
import { buildingIdSelector } from '../../redux/selectors/building';
import { companyIdSelector } from '../../redux/selectors/company';

type PopupProps = {
  open: boolean;
  handleClose: () => void;
  data?: any;
  controller?: ControllerItemDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
};

type ErrorProp = {
  code?: string;
  pin?: string;
};

const ConfirmPin = ({ open, handleClose, data, controller }: PopupProps) => {
  const errorsSelector = useAppSelector(errorsArray);
  const inputRefs = useRef<any>([]);
  const [digits, setDigits] = useState(Array(4).fill(''));
  const [errors, setErrors] = useState<ErrorProp>({});
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(companyIdSelector);
  const buildingId = useAppSelector(buildingIdSelector);

  useEffect(() => {
    return () => {
      setDigits(Array(4).fill(''));
    };
  }, [handleClose]);

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
    };
  }, [errorsSelector]);

  const handleChange = (index: any, value: any) => {
    const newDigits = [...digits];
    newDigits[index] = value;

    if (value !== '' && index < newDigits.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    setDigits(newDigits);
  };

  const isCodeFilled = digits.every(Boolean);

  const validateData = () => {
    const errors: ErrorProp = {};
    if (!isCodeFilled) {
      errors.code = 'the code is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (data.channel_status === 'open') {
      dispatch(
        disableController({
          controllerId: controller?.id,
          pin: digits.join(''),
          companyId,
          buildingId: buildingId,
          channel: data.channel,
          handleClose,
        })
      );
    } else {
      dispatch(
        enableController({
          controllerId: controller?.id,
          pin: digits.join(''),
          companyId,
          buildingId: buildingId,
          channel: data.channel,
          handleClose,
        })
      );
    }
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>Confirm Pin</DialogTitleContainer>
      <DialogContentContainer>
        <GridContainer container>
          <Grid xs={12} sm={12} item>
            <span
              style={{
                color: '#4A4A4A',
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '20px',
                wordWrap: 'break-word',
              }}
            >
              Enter your 4 digit number
            </span>
            <CodeContainer>
              {digits.map((digit, index) => (
                <CodeInput
                  key={index}
                  value={digit}
                  indexNumber={index}
                  OnChange={handleChange}
                  inputRefs={inputRefs}
                />
              ))}
            </CodeContainer>
            {errors?.code && (
              <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {errors?.code}
              </FormHelperText>
            )}
            {errors?.pin && (
              <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {errors?.pin}
              </FormHelperText>
            )}
          </Grid>
        </GridContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel="Cancel"
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel="Confirm"
          color={'primary'}
          OnClick={handleSendData}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    // width: '25%',
    // maxWidth: '25%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontWeight: 'bold',
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 32,
  paddingBottom: 32,
}));

const GridContainer = styled(Grid)(() => ({
  gap: 15,
  justifyContent: 'center',
}));

const CodeContainer = styled('div')`
  flex-direction: row;
  gap: 25px;
  display: flex;
  justify-content: center;
`;

export default ConfirmPin;
