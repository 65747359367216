import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Input, SelectInput } from '../Input';
import Grid from '@mui/material/Grid';
import { FormButton } from '../Button';
import { DialogContent, DialogActions, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { isAdminSelector } from '../../redux/selectors/user';
import { Actions, WeekDays } from '../../const/arrays';
import { controllerScheduleListSelector } from '../../redux/selectors/device';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import ColorType from '../Theme/ColorType';
import Switch, { SwitchProps } from '@mui/material/Switch';
import {
  deleteSchedule,
  storeSchedule,
  updateSchedule,
} from '../../redux/actions/scheduleAction';
import { companyIdSelector } from '../../redux/selectors/company';
import { ScheduleDTO } from '../../redux/types/schedule.dto';
import { timeStringTodateHelper } from '../../utils/helper';
import PopupConfirmDelete from './PopupConfirmDelete';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  open: boolean;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  schedule?: ScheduleDTO;
};

type ErrorProp = {
  title?: string;
};

const ScheduleModal = ({ open, handleClose, schedule }: PopupProps) => {
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const isAdmin = useAppSelector(isAdminSelector);
  const companyId = useAppSelector(companyIdSelector);
  const controllersIds = useAppSelector(controllerScheduleListSelector);
  const [title, setTitle] = useState('');
  const [devices, setDevices] = useState<any>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [startTime, setStartTime] = useState<Dayjs | null>(dayjs());
  const [endTime, setEndTime] = useState<Dayjs | null>(dayjs());
  const [checked, setChecked] = useState(false);
  const [weekDays, setWeekDays] = useState(WeekDays);
  const [errors, setErrors] = useState<ErrorProp>({});
  const [openSelect, setOpen] = useState(false);
  const [openSelectStart, setOpenStart] = useState(false);
  const [openSelectEnd, setOpenEnd] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [startAction, setStartAction] = useState('');
  const [endAction, setEndAction] = useState('');
  const [isStartCalled, setIsStartCalled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (schedule) {
      setTitle(schedule?.title ?? '');
      setStartTime(timeStringTodateHelper(schedule?.actions[0]?.action_time));
      setStartDate(dayjs(schedule.start_date));
      setEndTime(timeStringTodateHelper(schedule?.actions[1]?.action_time));
      setStartAction(schedule?.actions[0]?.action_type);
      setEndAction(schedule?.actions[1]?.action_type);
      setEndDate(dayjs(schedule.end_date));
      setChecked(!!schedule.is_forever);
      const updatedDays = weekDays.map((day) => ({
        ...day,
        isSelected: schedule.days.includes(day.id) ? true : day.isSelected,
      }));
      setWeekDays(updatedDays);
      setDevices(
        schedule.controllers.map((controller) => String(controller.id))
      );
    }
  }, [schedule]);

  useEffect(() => {
    if (isStartCalled) {
      if (startAction === 'open_valve') {
        setEndAction('close_valve');
      } else {
        setEndAction('open_valve');
      }
      setIsStartCalled(false);
    }
  }, [isStartCalled, startAction]);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleCloseSelect = () => {
    setOpen(false);
  };

  const handleOpenSelect = () => {
    setOpen(true);
  };

  const handleCloseSelectStart = () => {
    setOpenStart(false);
  };

  const handleOpenSelectStart = () => {
    setOpenStart(true);
  };

  const handleCloseSelectEnd = () => {
    setOpenEnd(false);
  };

  const handleOpenSelectEnd = () => {
    setOpenEnd(true);
  };

  const handleChangeTitle = (text: string) => {
    setTitle(text);
  };

  const handleChangeControllers = (event: any) => {
    const {
      target: { value },
    } = event;
    const isAllSelected = value.find((e: number) => e === 0);
    if (isAllSelected === 0) {
      const newValues = [...controllersIds];
      const allIds = newValues.filter((e) => e.value !== 0).map((e) => e.value);
      setDevices(allIds);
      handleCloseSelect();
    } else {
      setDevices(value);
    }
  };

  const handleChangeStartAction = (event: SelectChangeEvent) => {
    setIsStartCalled(true);
    setStartAction(event.target.value);
  };

  const handleChangeEndAction = (event: SelectChangeEvent) => {
    setEndAction(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleDayClick = (day: any) => {
    const newWeekDayArray = weekDays.map((wd) => {
      if (wd.id === day.id) {
        return { ...wd, isSelected: !wd.isSelected };
      }
      return wd;
    });
    setWeekDays(newWeekDayArray);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (!title) {
      errors.title = 'A valid name is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const arrayDayIds = weekDays
      .filter((wd) => wd.isSelected === true)
      .map((wd) => wd.id);
    const data = {
      title,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
      status: true,
      is_forever: checked,
      days: arrayDayIds,
      controller_ids: devices,
      actions: [
        { action_time: startTime?.format('HH:mm'), action_type: startAction },
        { action_time: endTime?.format('HH:mm'), action_type: endAction },
      ],
    };
    if (schedule) {
      const scheduleId = schedule.id;
      return dispatch(updateSchedule(data, scheduleId, companyId, handleClose));
    }
    dispatch(storeSchedule(data, companyId, handleClose));
  };

  const handleDisable = () => {
    handleOpenDeleteModal();
  };

  const handleDeleteShcedule = () => {
    if (schedule) {
      const scheduleId = schedule.id;
      return dispatch(deleteSchedule(scheduleId, companyId, handleClose));
    }
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>{t('schedule-modal-title')}</DialogTitleContainer>
      <DialogContentContainer>
        <GridContainer container>
          <Grid item xs={12} sx={{ paddingInline: '30px' }}>
            <Input
              placeholder={t('profile-name-title')}
              value={title}
              type="text"
              OnChange={handleChangeTitle}
              error={!!errors?.title}
              errorMessage={errors?.title}
            />
          </Grid>
          <Grid xs={12} sm={5} item>
            <StyledSpan>{t('schedule-modal-time')}</StyledSpan>
            <Grid
              xs={12}
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <TimeField
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
              />
              <SelectInput
                values={Actions}
                value={startAction}
                OnChange={handleChangeStartAction}
                open={openSelectStart}
                onOpen={handleOpenSelectStart}
                onClose={handleCloseSelectStart}
              />
            </Grid>
            <StyledSpan>{t('schedule-modal-to')}</StyledSpan>
            <Grid
              xs={12}
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <TimeField
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
              />
              <SelectInput
                values={Actions}
                value={endAction}
                OnChange={handleChangeEndAction}
                open={openSelectEnd}
                onOpen={handleOpenSelectEnd}
                onClose={handleCloseSelectEnd}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={5} item>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledSpan>{t('schedule-modal-weekdays')}</StyledSpan>
              <div style={{ display: 'flex', gap: 8 }}>
                {weekDays.map((wd) => {
                  return (
                    <WeekDayContainer
                      key={wd.id}
                      onClick={() => handleDayClick(wd)}
                      isSelected={wd.isSelected}
                    >
                      <WeekDayText isSelected={wd.isSelected}>
                        {activeLocale === 'en' ? wd.name : wd.nameFr}
                      </WeekDayText>
                    </WeekDayContainer>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={5} item>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledSpan>{t('schedule-modal-repeat')}</StyledSpan>
              <div style={{ display: 'flex', gap: 8 }}>
                <span
                  style={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: ColorType.black,
                  }}
                >
                  {t('schedule-modal-repeat')}
                </span>
                <IOSSwitch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={5} item>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledSpan>{t('schedule-modal-start')}</StyledSpan>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 24,
                    paddingRight: '30%',
                  }}
                >
                  <DatePicker
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    disabled={checked}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledSpan>{t('schedule-modal-end')}</StyledSpan>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 24,
                    paddingRight: '30%',
                  }}
                >
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    disabled={checked}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={12} item sx={{ paddingInline: '30px' }}>
            <SelectInput
              label={t('devices-Controller')}
              values={controllersIds}
              value={devices}
              OnChange={handleChangeControllers}
              open={openSelect}
              onOpen={handleOpenSelect}
              onClose={handleCloseSelect}
              multiple
            />
          </Grid>
        </GridContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
            fontFamily: 'Inter',
          }}
        />
        {schedule && (
          <FormButton
            buttonLabel={t('button-delete')}
            color={'grey'}
            OnClick={handleDisable}
            style={{
              marginRight: 10,
              borderRadius: 6,
              border: '1px solid #F0F0F0',
              fontFamily: 'Inter',
            }}
          />
        )}
        <FormButton
          buttonLabel={t('button-set-schedule')}
          color={'primary'}
          OnClick={handleSendData}
          style={{
            borderRadius: 6,
            fontFamily: 'Inter',
          }}
        />
      </DialogActionsContainer>
      <PopupConfirmDelete
        open={openDeleteModal}
        handleClick={handleDeleteShcedule}
        handleClose={handleCloseDeleteModal}
      />
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '80%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontSize: 28,
  fontWeight: 600,
  fontFamily: 'Inter',
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

const GridContainer = styled(Grid)(() => ({
  gap: 15,
  justifyContent: 'center',
}));

type WeekDayContainerProps = {
  isSelected: boolean;
};
const WeekDayContainer = styled('div')<WeekDayContainerProps>`
  width: 42px;
  height: 38px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.5px ${ColorType.softGrey} solid;
  justify-content: center;
  display: flex;
  background-color: ${({ isSelected }) =>
    isSelected ? ColorType.blue : ColorType.background};
`;

const WeekDayText = styled('span')<WeekDayContainerProps>`
  color: ${({ isSelected }) =>
    isSelected ? ColorType.background : ColorType.black};
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  font-family: Inter;
`;

const StyledSpan = styled('span')`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Inter;
  color: #5f6d7e;
`;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default ScheduleModal;
