import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import ColorType from '../Theme/ColorType';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { schedulesSelector } from '../../redux/selectors/schedule';
import dayjs from 'dayjs';
import { WeekDays } from '../../const/arrays';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dateTimeHelper, closestToTime } from '../../utils/helper';
import { Theme } from '@mui/system';
import ScheduleModal from '../Modal/ScheduleModal';
import { ScheduleDTO } from '../../redux/types/schedule.dto';
import { Switch } from '../Input';
import { toggleSchedule } from '../../redux/actions/scheduleAction';
import { companyIdSelector } from '../../redux/selectors/company';
import { useTranslation } from 'react-i18next';
import EmptySchedule from '../Tables/EmptySchedule';
import { Clog } from '../svg';
dayjs.extend(relativeTime);

const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
});

const ScheduleByAll = () => {
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const dispatch = useAppDispatch();
  const schedules = useAppSelector(schedulesSelector);
  const companyId = useAppSelector(companyIdSelector);

  const [open, setOpen] = useState(false);
  const [singleSchedule, setSingleSchedule] = useState<ScheduleDTO>();

  const handleOpen = (schedule: ScheduleDTO) => {
    setOpen(true);
    setSingleSchedule(schedule);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (id: number) => {
    const updatedItems = schedules?.map((item, i) =>
      item.id === id ? { ...item, status: !item.status } : item
    );
    const updatedItem = updatedItems?.find((schedule) => schedule.id === id);
    const data = {
      start_date: updatedItem?.start_date,
      end_date: updatedItem?.end_date,
      actions: updatedItem?.actions,
      status: updatedItem?.status,
      is_forever: updatedItem?.is_forever,
      days: updatedItem?.days,
      controller_ids: updatedItem?.controllers?.map((controller) =>
        String(controller.id)
      ),
    };
    dispatch(toggleSchedule(data, id, companyId));
  };

  const PaperHeader = () => (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Container>
        <BreadCrumbContainer
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <StyledLink underline="hover" color="inherit">
            {t('schedule-card-title')}

            <NavigateNextIcon />
          </StyledLink>
        </BreadCrumbContainer>
      </Container>
    </Toolbar>
  );

  if (schedules?.length === 0)
    return (
      <EmptySchedule
        component={t('schedules-planner')}
        description={t('schedules-all-schedules')}
        icon={<Clog width={56} height={56} fill={ColorType.black} />}
      />
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <PaperHeader />

        {schedules.map((schedule) => {
          const startTime = dateTimeHelper(schedule?.actions[0]?.action_time);
          const endTime = dateTimeHelper(schedule?.actions[1]?.action_time);
          const selectedDays = schedule.days.map((id) =>
            activeLocale === 'en'
              ? WeekDays.find((day) => day.id === id)?.name
              : WeekDays.find((day) => day.id === id)?.nameFr
          );
          return (
            <ScheduleContainer key={schedule.id}>
              <div
                onClick={() => handleOpen(schedule)}
                style={{ cursor: 'pointer' }}
              >
                <ScheduleWrapper>
                  <StyledTime isDisabled={!schedule.status}>
                    {startTime}
                  </StyledTime>
                  <StyledTime isDisabled={!schedule.status}>-</StyledTime>
                  <StyledTime isDisabled={!schedule.status}>
                    {endTime}
                  </StyledTime>
                  <StyledDate isDisabled={!schedule.status}>|</StyledDate>
                  <StyledDate isDisabled={!schedule.status}>
                    {schedule.days.length === 7
                      ? t('schedule-card-daily')
                      : selectedDays.join(', ')}
                  </StyledDate>
                </ScheduleWrapper>
                <ScheduleWrapper>
                  {schedule.is_forever === 1 && (
                    <>
                      <StyledDate isDisabled={!schedule.status}>
                        {t('schedule-card-forever')}
                      </StyledDate>
                      <StyledDate isDisabled={!schedule.status}>|</StyledDate>
                    </>
                  )}
                  {schedule.title && (
                    <StyledGreyedText>{schedule.title} | </StyledGreyedText>
                  )}
                  <StyledGreyedText>
                    Start in{' '}
                    {closestToTime(schedule?.actions[0]?.action_time).toNow(
                      true
                    )}
                  </StyledGreyedText>
                </ScheduleWrapper>
              </div>
              <div>
                <Switch
                  key={schedule.id}
                  initialChecked={!!schedule.status}
                  handleCheckedChange={() => handleChange(schedule.id)}
                />
              </div>
            </ScheduleContainer>
          );
        })}
      </Paper>
      <ScheduleModal
        open={open}
        handleClose={handleClose}
        style={style}
        schedule={singleSchedule}
      />
    </Box>
  );
};

type TextProps = {
  isDisabled: boolean;
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
`;

const BreadCrumbContainer = styled(Breadcrumbs)``;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
`;

const ScheduleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${ColorType.background};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d1d9e2;
`;

const ScheduleWrapper = styled('div')`
  display: flex;
  gap: 5px;
`;

const StyledTime = styled('span')<TextProps>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ isDisabled }) =>
    isDisabled ? ColorType.softGrey : ColorType.black};
`;

const StyledDate = styled('span')<TextProps>`
  font-size: 13px;
  font-weight: 300;
  color: ${({ isDisabled }) =>
    isDisabled ? ColorType.softGrey : ColorType.black};
`;

const StyledGreyedText = styled('span')`
  font-size: 13px;
  font-weight: 300;
  color: ${ColorType.softGrey};
`;

export default ScheduleByAll;
